import React, { useState, useEffect, useRef } from 'react';

import axios from 'axios';
import swal from 'sweetalert';
import { Link, Redirect } from 'react-router-dom'
import moment from 'moment';
import { MuiThemeProvider, createTheme } from '@material-ui/core';
import MUIDataTable from "mui-datatables";

import SetColor from './SetColor.js';
import { Modal } from 'react-bootstrap';
import { setCookie, getCookie } from '../common_component/cookie.js';
import Organization_modal from '../common_component/organization_modal.js';
import Timer_modal from '../common_component/timer_modal.js';
import Report_modal from '../common_component/report_modal.js';
import Select from 'react-select';
import product_unit_arr from './common_arr/product-unit-arr.js';


//import "antd/dist/antd.css";
import { Drawer, Button, Space } from 'antd';

export const ItemList = () => {
    const timer_interval_ref = useRef();
    const [clockTimerModalShow, setClockTimerModalShow] = useState(false);
    const [reportModalShow, setReportModalShow] = useState(false);
    const [reportModalFlag, setReportModalFlag] = useState(false);
    const [state_org, setstate_org] = useState(2);
    const [organization_name, setOrganization_name] = useState("");
    const [OrganizationModalShow, setOrganizationModalShow] = useState(false);
    const [redirect_to_login, setRedirect_to_login] = useState(2);

    const [Err_msg, setErr_msg] = useState(null);
    const [Err_details, setErr_details] = useState([]);

    const [Branch_name, setBranch_name] = useState("All");
    const [Warehouse_name, setWarehouse_name] = useState("All");
    const [Branchlist, setBranchlist] = useState([]);
    const [WarehouseSelectionList, setWarehouseSelectionList] = useState([]);
    const [open, setOpen] = useState(false);
    const [childrenDrawer, setChildrenDrawer] = useState(false);
    const [navtype, setnavtype] = useState("Overview");
    const [UnitSelectionList, setUnitSelectionList] = useState([]);
    const [Shipping_info, setShipping_info] = useState([{ "Weight": "", "Dimension": { "Width": "", "Height": "", "Length": "" } }]);
    const [Specification_info, setSpecification_info] = useState({ "MPN": "", "ICBN": "", "UPC": "", "EAN": "" });

    const [accessToken, setaccessToken] = useState('');
    const [user, setUser] = useState({});
    const [username, setUsername] = useState("");
    const [user_id, setUser_id] = useState("");
    const [unique_id, setUnique_id] = useState("");
    const [loading, setloading] = useState(false);
    const [state, setstate] = useState(2);
    const [userTheme, setUserTheme] = useState((localStorage.getItem("kt_metronic_theme_mode_menu") == "system") ? window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light" : localStorage.getItem("kt_metronic_theme_mode_menu"))
    const timer = useRef(0)
    const [clock_timer, setClockTimer] = useState(0);

    const [responsive, setResponsive] = useState("vertical");
    const [tableBodyHeight, setTableBodyHeight] = useState("400px");
    const [tableBodyMaxHeight, setTableBodyMaxHeight] = useState("");
    const [data, setData] = useState([]);
    const [editmodal, setEditModal] = useState([]);
    const [listCount, setListCount] = useState(0)
    const [currentPage, setCurrentPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [sortColumn, setSortColumn] = useState(null)
    const [sortValue, setSortValue] = useState(null)
    const [searchValue, setSearchValue] = useState(null)
    const [itemsListApiState, setItemsListApiState] = useState(false);
    const [Tracking_inventory, setTracking_inventory] = useState("");


    const [tag_name, settag_name] = useState("");
    const [productid, setproductid] = useState("");
    const [categoryname, setCategoryname] = useState("");
    const [status, setstatus] = useState("");
    const [description, setDescription] = useState("");

    const [visibility, setvisibility] = useState(0);
    const [redirect, setredirect] = useState(0);
    const [organization_id, setOrganization_id] = useState("");
    const [organization_list, setorganization_list] = useState([]);
    const [read, setread] = useState(2);
    const [write, setwrite] = useState(2);
    const [mainSubmitButtonState, setMainSubmitButtonState] = useState(false);
    const [Warehouselist, setWarehouselist] = useState([]);

    const [CategoryModalShow, setCategoryModalShow] = useState(false);
    const [StatusModalShow, setStatusModalShow] = useState(false);
    const [Item_name, setItem_name] = useState("");
    const [Item_flag, setItem_flag] = useState(2);
    const [Item_details, setItem_details] = useState([]);
    const [Tax_details, setTax_details] = useState([]);
    const [Stock_history_details, setStock_history_details] = useState([]);
    const [Selected_Stock_history_details, setSelected_Stock_history_details] = useState([]);
    const [Item_history_details, setItem_history_details] = useState([]);
    const [Serial_batch_details, setSerial_batch_details] = useState([]);
    const [Selected_serial_batch_details, setSelected_serial_batch_details] = useState([]);

    var page_name = "item-list";

    const loaderstyle = {
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: "auto",
        marginBottom: "auto",
    };
    const options = {
        fixedHeader: false,
        selectableRows: "none",
        tableBodyHeight,
        tableBodyMaxHeight,
        responsive: "standard",
    };

    let dropdownStyleDark = {
        option: (provided, state) => ({
            ...provided,
            color: '#dddddf',
            backgroundColor: state.isSelected ? provided.backgroundColor : state.isFocused ? '#5f5f8d' : provided.backgroundColor,
        }),
        control: (provided, state) => ({
            ...provided,
            backgroundColor: "#2b2b40",
            borderColor: "#4c4c71"
        }),
        placeholder: (provided, state) => ({
            ...provided,
            color: "#dddddf",
        }),
        singleValue: (provided, state) => ({
            ...provided,
            color: "#dddddf",
        }),
        menu: (provided, state) => ({
            ...provided,
            backgroundColor: "#2b2b40",
        })
    }

    const columns = [
        {
            name: "product_id",
            label: "product_id",
            options: {
                display: false,
                filter: true,
                sort: true
            }
        },
        {
            name: "product_name",
            label: "Item Name",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "product_type",
            label: "Tracking Inventory Type",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "brand_name",
            label: "Brand",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "category_name",
            label: "Category ",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "subcategory_name",
            label: "SubCategory ",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "add_status",
            label: "Status",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <SetColor
                            value={value}
                            index={tableMeta.columnIndex}
                            change={event => updateValue(event)}
                        />
                    )
                }
            }
        }
    ];

    useEffect(() => {
        if (getCookie("access_token") != "") {
            if (getCookie("organization_id") != "") {
                setOrganization_id(getCookie("organization_id"));
            }
            else {
                var object = {};
                object.page_name = page_name;
                var headers = {
                    headers: {
                        "Content-Type": "application/json",
                        "token": getCookie("access_token"),
                        "Accept": "*/*"
                    }
                }

                axios.post(`${process.env.REACT_APP_SERVER_URL}/show-organization`, object, headers).then((res) => {
                    if (res.data.length == 1) {
                        setCookie('organization_id', res.data[0].organization_id, 1, true);
                        setOrganization_id(res.data[0].organization_id)
                    }
                    else {
                        setstate_org(1);
                        setorganization_list(res.data);
                        setOrganizationModalShow(true);
                    }
                })
            }
        }
        else {
            setRedirect_to_login(1);
        }
    }, [])

    useEffect(() => {
        setCookie("lastpage", window.location.href, 1, false)
        const validateJWT = () => {

            if (getCookie("access_token") != "") {
                var object = {};
                object.token = getCookie("access_token");

                var headers = {
                    headers: {
                        "Content-Type": "application/json",
                        "Accept": "*/*"
                    }
                }
                axios.post(`${process.env.REACT_APP_SERVER_URL}/get-user`, object, headers).then((res) => {

                    if (res.data.operation == "success") {
                        var user_object = {};
                        user_object.email = res.data.email;
                        user_object.name = res.data.name;
                        user_object.img = res.data.img;
                        setUser_id(user_object.email)
                        setUser(user_object)
                        setUsername(res.data.name)
                    }
                    else {
                        swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
                            window.location.href = "/login"
                        });
                    }
                })
                    .catch(function (error) {
                        swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
                            window.location.href = "/login"
                        });
                    });
                object.organization_id = organization_id;
                axios.post(`${process.env.REACT_APP_SERVER_URL}/audience-info`, object, headers).then((res) => {

                    if (res.data.operation == "success") {
                        var token = res.data.audience_license;
                        if (res.data.organization_flag == 0) {
                            setstate_org(3);
                            setOrganization_name(res.data.organization_name);
                            setOrganizationModalShow(true);
                        }
                        else {
                            var path = window.location.pathname;
                            path = path.replace(/\/$/, "");
                            path = decodeURIComponent(path);
                            var original_url = path.split("/")[1]
                            let index = 0;
                            let temp = -1;
                            for (let i = 0; i < res.data.user_access.access.length; i++) {
                                if (temp == 1) break;
                                let component = res.data.user_access.access[i].inside_components;
                                for (let j = 0; j < component.length; j++) {
                                    if (temp == 1) break;
                                    if (component[j].hasOwnProperty(original_url)) {
                                        index = i;
                                        if (component[j][original_url] == 1 && res.data.user_access.access[index].visibility == 1) {
                                            temp = 1;
                                            setvisibility(1);
                                            setread(component[j]["read"])
                                            setwrite(component[j]["write"])
                                            // setwrite(0)
                                            // break;
                                        }
                                        else {
                                            setredirect(1);
                                        }
                                    }
                                    else if (component[j].hasOwnProperty("key")) {
                                        let sub_inside_components = component[j].inside_components
                                        for (let k = 0; k < sub_inside_components.length; k++) {
                                            if (sub_inside_components[k].hasOwnProperty(original_url)) {
                                                index = i
                                                if (sub_inside_components[k][original_url] == 1 && res.data.user_access.access[index].visibility == 1 && component[j].visibility == 1) {
                                                    temp = 1;
                                                    setvisibility(1);
                                                    setread(sub_inside_components[k]["read"])
                                                    setwrite(sub_inside_components[k]["write"])
                                                    // setwrite(0)
                                                    break;

                                                }
                                                else {
                                                    setredirect(1);
                                                }
                                            }
                                        }
                                    }
                                    else {
                                        temp = 2;
                                    }
                                }
                            }

                            if (temp == 2) {
                                setredirect(1);
                            }

                        }
                        if (!token.includes("ufhdsupw29fher") || !token.includes("pdbwu27dh6schs") || !token.includes("0dh27agiqbcrwi")) {
                            setCookie('access_token', res.data.audience_license, 1, true)
                            timer.current = process.env.REACT_APP_JWT_EXPIRY;
                            //setClockTimer(process.env.REACT_APP_JWT_EXPIRY);
                        }
                        else {
                            timer.current = res.data.audience_count;
                            //setClockTimer(res.data.audience_count)
                        }
                    }
                    else {
                        setRedirect_to_login(1);
                    }
                })
                    .catch(function (error) {
                        setRedirect_to_login(1);
                    });
            }
            else {
                setRedirect_to_login(1);
            }
        }
        organization_id != "" && validateJWT();
    }, [organization_id])

    useEffect(() => {
        var obj_array = [];
        var Productunitarr = product_unit_arr.product_unit_arr;
        //console.log(Productunitarr)
        setUnitSelectionList(Productunitarr);
    }, []);

    useEffect(() => {
        let id = setInterval(() => {
            setUserTheme((localStorage.getItem("kt_metronic_theme_mode_menu") == "system") ? window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light" : localStorage.getItem("kt_metronic_theme_mode_menu"))
        }, 10)

        return () => {
            clearInterval(id)
        }
    }, [])

    useEffect(() => {
        timer_interval_ref.current = setInterval(() => {
            // console.log(timer)

            timer.current = timer.current - 1
            if (timer.current > 0 && timer.current < process.env.REACT_APP_JWT_EXPIRY_NOTICE) {
                if (!clockTimerModalShow) {
                    setClockTimer(process.env.REACT_APP_JWT_EXPIRY_NOTICE)
                    setClockTimerModalShow(true)
                }
            }
        }, 1000);

        return () => { clearInterval(timer_interval_ref) };
    }, []);


    async function getitemdetails(cp, rpp, sort_c, sort_v, search_v) {
        var object = {};
        object.email = user.email;
        setItemsListApiState(true);
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/product-datatable`, { method: 'POST', body: JSON.stringify({ "email": user.email, "organization_id": organization_id, "current_page": cp, "rows_per_page": rpp, "sort_column": sort_c, "sort_value": sort_v, "search_value": search_v, "page_name": page_name }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();

        if (body.data.list.length > 0) {
            setEditModal(body.data.list);
            setListCount(body.data.count);
            setItemsListApiState(false);
        }
        else {
            setEditModal([]);
            setListCount(0);
            setItemsListApiState(false);
            setstate(1);
            setData([]);
        }
    }


    async function gettrackinginventorytype() {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/getadvanceinventorytracking`, {
            method: 'POST', body: JSON.stringify({ "organization_id": organization_id, "email": user.email, "page_name": page_name }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") }
        }
        );
        const body = await response.json();
        if (body.operation === "success") {
            setTracking_inventory(body.data[0].tracking_inventory_type);
        }
    }

    useEffect(() => {
        if ((read || write) && (user.email && visibility)) {
            let p1 = getitemdetails(currentPage, rowsPerPage, sortColumn, sortValue, searchValue);
            Promise.all([p1])
                .then(() => {
                    setstate(1)
                    console.log("all apis done")
                })
                .catch((err) => {
                    const new_err = new Error(err);
                    setErr_msg(new_err.message);
                    setErr_details(new_err.stack.split("\n"));
                    setstate(3)
                    console.log(err)
                })
        }

    }, [user, visibility, read, write])

    useEffect(() => {
        console.log(Err_msg)
        if (Err_msg !== null && Err_details.length > 0) {
            let object = {}
            object.token = getCookie("access_token")
            object.organization_id = organization_id
            object.message = Err_msg
            object.Err_details = Err_details
            object.page_url = window.location.pathname
            object.email = user.email
            object.page_name = page_name
            let headers = {
                headers: {
                    "Content-Type": "application/json",
                    "token": getCookie("access_token"),
                    "Accept": "*/*"
                }
            }
            axios.post(`${process.env.REACT_APP_SERVER_URL}/check-issue-details`, object, headers).then((res) => {
                if (res.data.operation == "danger") {
                    setReportModalFlag(false);
                }
                else {
                    setReportModalFlag(true);
                }
                setReportModalShow(true);
            })
        }
    }, [Err_msg, Err_details])

    useEffect(() => {
        if (state == 1) {
            getitemdetails(currentPage, rowsPerPage, sortColumn, sortValue, searchValue)
        }
    }, [currentPage, rowsPerPage, sortColumn, sortValue, searchValue])

    useEffect(() => {
        async function geteditdetails() {
            if (editmodal.length > 0) {
                var temp_data = [];
                editmodal.map((obj) => {
                    var temp_obj = {};
                    temp_obj.product_id = obj.pid;
                    temp_obj.product_name = obj.product_name;
                    temp_obj.product_type = <span className="badge badge-secondary me-auto rounded">{obj.tracking_inventory_type}</span>;
                    temp_obj.brand_name = obj.brand_name;
                    temp_obj.category_name = obj.category_name;
                    temp_obj.subcategory_name = obj.subcategory_name;
                    temp_obj.add_status = obj.add_status;
                    temp_obj.category_code = obj.category_code;
                    temp_obj.id = obj.pid;
                    var id = obj.pid;
                    temp_data.push(temp_obj);
                })
                setData(temp_data);
                setstate(1);
            }
            else {
                setData([]);
                setstate(1);
            }
        }
        (editmodal.length > 0) && geteditdetails();

    }, [editmodal])

    const Serial_batch_filter = (branch_id, warehouse_id) => {
        
        let arr = [];
        if (warehouse_id === "All" && branch_id === "All") {
            arr = Serial_batch_details;
        }
        else if (warehouse_id === "" && branch_id !== "") {
            arr = Serial_batch_details.filter(option => (option.Branch == branch_id));
        }
        else if (warehouse_id !== "" && branch_id !== "") {
            arr = Serial_batch_details.filter(option => (option.warehouse == warehouse_id && option.Branch == branch_id));
        }
        console.log(arr);
        setSelected_serial_batch_details(arr);
    }


    // console.log(editmodal)
    return (
        <>
            {
                (redirect == 1 || (read == 0 && write == 0)) ?
                    <>
                        <Redirect to={{
                            pathname: "/unauthorized",
                        }}
                        />
                    </> :
                    null
            }
            <div className="d-flex flex-column flex-column-fluid">
                <div className="app-toolbar py-3 py-lg-6" id="kt_app_toolbar">
                    <div id="kt_app_toolbar_container" className="app-container container-xxl d-flex flex-stack">
                        <h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">Item List
                            <small className="text-muted fs-7 fw-bold my-1 ms-1"></small>
                        </h1>
                    </div>
                </div>
                <div id="kt_app_content" className="app-content flex-column-fluid">
                    <div id="kt_app_content_container" className="app-container container-xxl">
                        {
                            state == 1 ?
                                <>
                                    <MuiThemeProvider theme={createTheme({ palette: { type: userTheme } })}>
                                        <MUIDataTable
                                            title={
                                                <>
                                                    <span className='me-20'>Item List</span>
                                                    {itemsListApiState && <><span>Loading...</span>
                                                        <svg width="50" height="15" viewBox="0 45 100 10">
                                                            <g transform="translate(20 50)"><circle cx="0" cy="0" r="6" fill="#e15b64"><animateTransform attributeName="transform" type="scale" begin="-0.375s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite"></animateTransform></circle></g>
                                                            <g transform="translate(40 50)"><circle cx="0" cy="0" r="6" fill="#f8b26a"><animateTransform attributeName="transform" type="scale" begin="-0.25s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite"></animateTransform></circle></g>
                                                            <g transform="translate(60 50)"><circle cx="0" cy="0" r="6" fill="#abbd81"><animateTransform attributeName="transform" type="scale" begin="-0.125s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite"></animateTransform></circle></g>
                                                            <g transform="translate(80 50)"><circle cx="0" cy="0" r="6" fill="#81a3bd"><animateTransform attributeName="transform" type="scale" begin="0s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite"></animateTransform></circle></g>
                                                        </svg>
                                                    </>}
                                                </>
                                            }
                                            data={data}
                                            columns={columns}
                                            options={{
                                                ...options,
                                                download: false,
                                                filter: false,
                                                searchPlaceholder: 'Search Item',
                                                serverSide: true,
                                                count: listCount,
                                                //jumpToPage: true,
                                                onChangePage: (val) => { setCurrentPage(val) },
                                                onChangeRowsPerPage: (val) => { setCurrentPage(0); setRowsPerPage(val) },
                                                onColumnSortChange: (col, dir) => { setSortColumn(col); setSortValue(dir) },
                                                onSearchChange: (val) => { setCurrentPage(0); setSearchValue(val) },
                                                // onTableChange: (action, tableState)=>{console.log(action, tableState);}
                                            }}
                                        />
                                    </MuiThemeProvider>
                                </>
                                :
                                <>
                                    {
                                        state == 2 ?
                                            <img src="/images/loader-06.svg" alt="" style={loaderstyle} />
                                            :
                                            (state == 3 && Err_msg !== null) &&
                                            <>
                                                <div className="card-body pt-0">
                                                    <div className="text-gray-500 fw-bold fs-5 mb-5">Item List</div>
                                                    <div className="notice d-flex bg-light-primary rounded border-primary border border-dashed rounded-3 p-6">
                                                        <div class="d-flex flex-stack flex-grow-1 flex-wrap flex-md-nowrap">
                                                            <div class="mb-3 mb-md-0 fw-semibold">
                                                                <h4 class="text-gray-900 fw-bold">Report an issue</h4>
                                                                <div class="fs-6 text-gray-700 pe-7">There is some error in the page. Do you want reoprt it to the developer?</div>
                                                            </div>
                                                            <a class="btn btn-primary px-6 align-self-center text-nowrap" onClick={(e) => { setReportModalShow(true) }}>Report</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                    }
                                </>
                        }
                    </div>
                </div>
            </div>

            <Organization_modal OrganizationModalShow={OrganizationModalShow} setOrganizationModalShow1={setOrganizationModalShow} organization_name={organization_name} organization_list={organization_list} organization_id={organization_id} state_org={state_org} mainSubmitButtonState={mainSubmitButtonState} setMainSubmitButtonState={setMainSubmitButtonState} />
            <Timer_modal clockTimerModalShow={clockTimerModalShow} setClockTimerModalShow1={setClockTimerModalShow} clock_timer={clock_timer} organization_id={organization_id} timer_ref={timer} mainSubmitButtonState={mainSubmitButtonState} setMainSubmitButtonState={setMainSubmitButtonState} />
            <Report_modal reportModalShow={reportModalShow} reportModalFlag={reportModalFlag} setReportModalShow={setReportModalShow} Err_msg={Err_msg} Err_details={Err_details} email={user.email} page_name={page_name} organization_id={organization_id} mainSubmitButtonState={mainSubmitButtonState} setMainSubmitButtonState={setMainSubmitButtonState} />
        </>);

};

export default ItemList;
