
var tcs_section_arr = new Array(
   {
      "id":"1",
      "section_no":"193",
      "section_name":"Interest on securities",
      "status":"Active"
   },
   {
      "id":"2",
      "section_no":"192",
      "section_name":"Payment of salary",
      "status":"Active"
   },
   {
      "id":"3",
      "section_no":"192A",
      "section_name":"Premature withdrawal from EPF",
      "status":"Active"
   },
   {
      "id":"4",
      "section_no":"194",
      "section_name":"Payment of any dividend",
      "status":"Active"
   },
   {
      "id":"5",
      "section_no":"194B",
      "section_name":"Income from lottery winnings",
      "status":"Active"
   }
);

exports.tcs_section_arr = tcs_section_arr;