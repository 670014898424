export  function setCookie(cname, cvalue, exdays,flag) {
	var d = new Date();
	d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
	let cookie_name=cname;
	if(cname=="access_token")
	{
		cookie_name="isms_access_token";
	}
	else if(cname=="organization_id")
	{
		cookie_name="isms_organization_id";
	}
	else if(cname=="lastpage")
	{
		cookie_name="isms_lastpage";
	}
	var expires = "expires=" + d.toUTCString();
	if(!flag)
	{
		document.cookie = cookie_name + "=" + cvalue + ";" + expires + ";path=/";
	}
	else
	{
		document.cookie = cookie_name + "=" + cvalue + ";" + expires + ";path=/";
	}
}

export  function getCookie(cname) {
	let cookie_name=cname;
	if(cname=="access_token")
	{
		cookie_name="isms_access_token";
	}
	else if(cname=="organization_id")
	{
		cookie_name="isms_organization_id";
	}
	else if(cname=="lastpage")
	{
		cookie_name="isms_lastpage";
	}
	var name = cookie_name + "=";
	var ca = document.cookie.split(';');
	for (var i = 0; i < ca.length; i++) {
		var c = ca[i];
		while (c.charAt(0) === ' ') {
			c = c.substring(1);
		}
		if (c.indexOf(name) === 0) {
			return c.substring(name.length, c.length);
		}
	}
	return "";
}


export function delete_cookie(cname,exdays,flag) {
	var d = new Date();
	d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
	var expires = "expires=" + d.toUTCString();
	if(!flag)
	{
		document.cookie = cname +'=; Path=/;'+ expires + ";";
	}
	else
	{
		document.cookie = cname +'=; Path=/;'+ expires + ";";
		/*let url = window.location.href;
		let domain = (new URL(url));
		domain = domain.hostname;
		domain = domain.replace('isms','');
		document.cookie = cname +'=;'+ expires + `;domain=${domain}`;*/
	}
	
  }
