
var product_unit_arr = new Array(
   {
      "id":"1",
      "Short_term":"box",
      "Unit":"Box",
      "status":"Active"
   },
   {
      "id":"2",
      "Short_term":"cm",
      "Unit":"Centimeter",
      "status":"Active"
   },
   {
      "id":"3",
      "Short_term":"dz",
      "Unit":"Dozen",
      "status":"Active"
   },
   {
      "id":"4",
      "Short_term":"ft",
      "Unit":"Feet",
      "status":"Active"
   },
   {
      "id":"5",
      "Short_term":"g",
      "Unit":"Grams",
      "status":"Active"
   },
   {
      "id":"6",
      "Short_term":"in",
      "Unit":"Inches",
      "status":"Active"
   },
   {
      "id":"7",
      "Short_term":"lb",
      "Unit":"Pound",
      "status":"Active"
   },
   {
      "id":"8",
      "Short_term":"pcs",
      "Unit":"pieces",
      "status":"Active"
   }
);

exports.product_unit_arr = product_unit_arr;