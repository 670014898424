import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import swal from 'sweetalert';
import moment from 'moment';
import { Redirect } from 'react-router-dom';
// import { ToWords } from 'to-words'
import { setCookie, getCookie } from '../common_component/cookie.js';
import Organization_modal from '../common_component/organization_modal.js';
import Timer_modal from '../common_component/timer_modal.js';
import { Modal, Button } from 'react-bootstrap';
import { useDropzone } from 'react-dropzone';

export const BalanceDetails = (props) => {

    const timer_interval_ref = useRef();
    const [clockTimerModalShow, setClockTimerModalShow] = useState(false);
    const [state_org, setstate_org] = useState(2);
    const [organization_name, setOrganization_name] = useState("");
    const [OrganizationModalShow, setOrganizationModalShow] = useState(false);
    const [redirect_to_login, setRedirect_to_login] = useState(2);

    const [balanceDataDetails, setBalanceDataDetails] = useState(null);
    const [currencyCode, setCurrencyCode] = useState(null)

    const [timezoneChange, setTimezoneChange] = useState("+00:00")
    const [uploadedFiles, setUploadedFiles] = useState([])

    const [declineModalShow, setDeclineModalShow] = useState(false)
    const [declineReason, setDeclineReason] = useState("")
    const [openDropzone, setOpenDropzone] = useState(false)
    const [attachment, setAttachment] = useState([])
    const { getRootProps, getInputProps } = useDropzone({
        onDrop: acceptedFiles => { setAttachment(prevFiles => [...prevFiles, ...acceptedFiles]); },
        multiple: true
    });
    const [payableAmount, setPayableAmount] = useState(0)
    const [remainingBalance, setRemainingBalance] = useState(0)
    const [wizardPanel, setWizardPanel] = useState(1)

    const [tableDataArray, setTableDataArray] = useState([{ from_date: moment.utc().subtract(7, "days").format('YYYY-MM-DD'), to_date: moment.utc().format('YYYY-MM-DD'), scheme_type: "", scheme_ref: "", scheme_details: "", claim_amt: 0, remark: "" }])

    const [submitButtonState, setSubmitButtonState] = useState(false)

    const [respondModalShow, setRespondModalShow] = useState(false)
    const [uploadResponseDescription, setUploadResponseDescription] = useState("")
    const [submitResponseButtonState, setSubmitResponseButtonState] = useState(false)


    const [state, setstate] = useState(2);
    const [user, setUser] = useState({});
    const [organization_id, setOrganization_id] = useState("");

    const [visibility, setvisibility] = useState(0);
    const [redirect, setredirect] = useState(0);
    const [read, setread] = useState(2);
    const [write, setwrite] = useState(2);

    const [userTheme, setUserTheme] = useState((localStorage.getItem("kt_metronic_theme_mode_menu") === "system") ? window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light" : localStorage.getItem("kt_metronic_theme_mode_menu"))

    const timer = useRef(0)
    const [clock_timer, setClockTimer] = useState(0);

    var page_name = "balance-details";

    const loaderstyle = {
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: "auto",
        marginBottom: "auto",
    };

    useEffect(() => {
        let id = setInterval(() => {
            setUserTheme((localStorage.getItem("kt_metronic_theme_mode_menu") === "system") ? window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light" : localStorage.getItem("kt_metronic_theme_mode_menu"))
        }, 10)

        return () => {
            clearInterval(id)
        }
    }, [])

    useEffect(() => {
        if (getCookie("access_token") !== "") {
            if (getCookie("organization_id") !== "") {
                setOrganization_id(getCookie("organization_id"));
            }
            else {
                var object = {};
                object.page_name = page_name;
                var headers = {
                    headers: {
                        "Content-Type": "application/json",
                        "token": getCookie("access_token"),
                        "Accept": "*/*"
                    }
                }

                axios.post(`${process.env.REACT_APP_SERVER_URL}/show-default-organization`, object, headers).then((res) => {
                    if (res.data.length > 0) {
                        setCookie('organization_id', res.data[0].organization_id, 1, true);
                        setOrganization_id(res.data[0].organization_id)
                    }
                })
            }
        }
        else {
            setRedirect_to_login(1);
        }
    }, [])

    useEffect(() => {
        setCookie("lastpage", window.location.href, 1, false)
        const validateJWT = () => {

            if (getCookie("access_token") !== "") {
                var object = {};
                object.token = getCookie("access_token");

                var headers = {
                    headers: {
                        "Content-Type": "application/json",
                        "Accept": "*/*"
                    }
                }
                axios.post(`${process.env.REACT_APP_SERVER_URL}/get-user`, object, headers).then((res) => {

                    if (res.data.operation === "success") {
                        var user_object = {};
                        user_object.email = res.data.email;
                        user_object.name = res.data.name;
                        user_object.img = res.data.img;

                        setUser(user_object)
                    }
                    else {
                        swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
                            window.location.href = "/login"
                        });
                    }
                })
                    .catch(function (error) {
                        swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
                            window.location.href = "/login"
                        });
                    });
                object.organization_id = organization_id;
                axios.post(`${process.env.REACT_APP_SERVER_URL}/audience-info`, object, headers).then((res) => {

                    if (res.data.operation === "success") {
                        var token = res.data.audience_license;
                        if (res.data.organization_flag === 0) {
                            setstate_org(3);
                            setOrganization_name(res.data.organization_name);
                            setOrganizationModalShow(true);
                        }
                        else {
                            setOrganization_name(res.data.organization_name);
                            var path = window.location.pathname;
                            path = path.replace(/\/$/, "");
                            path = decodeURIComponent(path);
                            var original_url = path.split("/")[1]
                            let index = 0;
                            let temp = -1;
                            for (let i = 0; i < res.data.user_access.access.length; i++) {
                                if (temp === 1) break;
                                let component = res.data.user_access.access[i].inside_components;
                                for (let j = 0; j < component.length; j++) {
                                    if (component[j].hasOwnProperty(original_url)) {
                                        index = i;
                                        if (res.data.user_access.access[index].inside_components[j][original_url] === 1 && res.data.user_access.access[index].visibility === 1) {
                                            temp = 1;
                                            setvisibility(1);
                                            setread(res.data.user_access.access[index].inside_components[j]["read"])
                                            setwrite(res.data.user_access.access[index].inside_components[j]["write"])
                                            // setwrite(0)
                                            break;
                                        }
                                        else {
                                            setredirect(1);
                                        }

                                    }
                                    else if (component[j].hasOwnProperty("key")) {
                                        let sub_inside_components = component[j].inside_components
                                        for (let k = 0; k < sub_inside_components.length; k++) {
                                            if (sub_inside_components[k].hasOwnProperty(original_url)) {
                                                index = i
                                                if (sub_inside_components[k][original_url] === 1 && res.data.user_access.access[index].visibility === 1 && component[j].visibility === 1) {
                                                    temp = 1;
                                                    setvisibility(1);
                                                    setread(sub_inside_components[k]["read"])
                                                    setwrite(sub_inside_components[k]["write"])
                                                    // setwrite(0)
                                                    break;

                                                }
                                                else {
                                                    setredirect(1);
                                                }
                                            }
                                        }
                                    }
                                    else {
                                        temp = 2;
                                    }
                                }
                            }

                            if (temp === 2) {
                                setredirect(1);
                            }
                        }

                        if (!token.includes("ufhdsupw29fher") || !token.includes("pdbwu27dh6schs") || !token.includes("0dh27agiqbcrwi")) {
                            setCookie('access_token', res.data.audience_license, 1, true)
                            timer.current = process.env.REACT_APP_JWT_EXPIRY
                        }
                        else {
                            timer.current = res.data.audience_count
                        }
                    }
                    else {
                        setRedirect_to_login(1);
                    }
                })
                    .catch(function (error) {
                        console.log(error);
                        setRedirect_to_login(1);
                    });
            }
            else {
                setRedirect_to_login(1);
            }
        }
        organization_id !== "" && validateJWT();

    }, [organization_id])

    useEffect(() => {
        timer_interval_ref.current = setInterval(() => {
            // console.log(timer)

            timer.current = timer.current - 1
            if (timer.current > 0 && timer.current < process.env.REACT_APP_JWT_EXPIRY_NOTICE) {
                if (!clockTimerModalShow) {
                    setClockTimer(process.env.REACT_APP_JWT_EXPIRY_NOTICE)
                    setClockTimerModalShow(true)
                }
            }
        }, 1000);

        return () => { clearInterval(timer_interval_ref) };
    }, []);

    async function getBalanceDetails() {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/get-user-balance-data-details`, { method: 'POST', body: JSON.stringify({ "email": user.email, "file_id": props.match.params.id, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        // console.log(body);

        if (body.operation === "success") {
            setBalanceDataDetails(body.data);
            // setUploadedFiles(body.data.uploaded_files)
        }
    }

    async function getTimezoneInformation() {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/get-organization-timezone-information`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        if (body.operation === "success") {
            if (body.data.length > 0) {
                if (body.data[0].timezone_value != "" && body.data[0].timezone_value != null) {
                    const timezone = body.data[0].timezone_value;
                    setTimezoneChange(timezone);
                }
            }
        }
        else {
            throw "timezone api error"
        }
    }

    async function getOrganizationDetails() {

        var object = {};
        object.email = user.email;

        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/get-organization-information`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "organization_id": organization_id, "id": organization_id, "flag": "details" }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        // let obj = {}
        if (body.length > 0) {
            setCurrencyCode(body.currency_code)
        }
        else {
            console.log("Invalid organization image api call")
            return null
        }
    }

    const handleModalClose = () => {
        setDeclineModalShow(false)
        setDeclineReason("");
        setOpenDropzone(false)
        setAttachment([])
        setSubmitButtonState(false);
        setPayableAmount(0);
        setRemainingBalance(0);
        setWizardPanel(1)
        setTableDataArray([{ from_date: moment.utc().subtract(7, "days").format('YYYY-MM-DD'), to_date: moment.utc().format('YYYY-MM-DD'), scheme_type: "", scheme_ref: "", scheme_details: "", claim_amt: 0, remark: "" }])
    };

    const handleRespondModalClose = () => {
        setRespondModalShow(false);
        setUploadResponseDescription("");
        setOpenDropzone(false);
        setAttachment([]);
    };

    useEffect(() => {
        if ((read === 1 || write === 1) && (user.email && visibility)) {

            let p1 = getBalanceDetails()
            let p2 = getTimezoneInformation()
            let p3 = getOrganizationDetails()

            Promise.all([p1, p2, p3])
                .then(() => {
                    setstate(1);
                    console.log("all apis done")
                })
                .catch(({ message, cause }) => {
                    setstate(0);
                    console.log(message, cause)
                })
        }
    }, [user, visibility, read, write])

    const validateBalance = () => {

        if (declineReason === "") {
            swal("Oops!", "Enter a proper reason for decline", "warning");
            return false
        }

        if ((tableDataArray.reduce((total, item) => total + parseFloat(item.claim_amt), 0) < payableAmount)) {
            swal("Oops", "Please check the claim amount and the required payable amount", "warning")
            return false
        }

        if (tableDataArray.length === 0) {
            swal("Oops!", "Enter atleast one row in table", "warning");
            return false
        }
        for (let i = 0; i < tableDataArray.length; i++) {
            let x = tableDataArray[i]
            console.log(x);
            if (moment(x.from_date).isAfter(moment(x.to_date))) {
                swal("Oops!", "Enter a proper date range", "warning");
                return false;
            }
            if (x.scheme_type === "" || x.scheme_ref === "" || x.scheme_details === "" || x.claim_amt === 0) {
                swal("Oops!", "Enter a proper value in the table", "warning");
                return false;
            }
        }

        return true
    }

    const submitConfirmation = () => {
        var object = {}

        object.operation_type = "CONFIRMED"
        object.distributor_org_id = balanceDataDetails.organization_id
        object.document_id = props.match.params.id
        object.organization_id = organization_id
        object.page_name = page_name

        setSubmitButtonState(true)

        var headers = {
            headers: {
                "Content-Type": "application/json",
                "Accept": "*/*",
                "token": getCookie("access_token")
            }
        }
        axios.post(`${process.env.REACT_APP_SERVER_URL}/confirm-user-balance-transaction`, object, headers).then((res) => {
            setSubmitButtonState(false)
            if (res.data.operation === "success") {
                swal("Great!", "User confirmation successful", "success").then((value) => { window.location.reload() });
            }
            else {
                swal("Oops!", "Something went wrong!", "error");
            }
        })
            .catch(function (error) {
                console.log(error)
            });
    }

    const submitDetails = () => {

        const object = new FormData()

        object.append("operation_type", "INIT_DECLINED")

        object.append("payable_amount", payableAmount)
        object.append("balance", remainingBalance)
        object.append("user_message", declineReason)

        attachment.forEach(obj => {
            object.append("data_files", obj)
        })

        let temp = []
        tableDataArray.forEach((obj) => {
            let tobj = {}
            tobj.from_date = obj.from_date
            tobj.to_date = obj.to_date
            tobj.scheme_type = obj.scheme_type
            tobj.scheme_details = obj.scheme_details
            tobj.scheme_ref = obj.scheme_ref
            tobj.claim_amt = obj.claim_amt
            tobj.remark = obj.remark

            temp.push(tobj)
        })
        object.append("scheme_details", JSON.stringify(temp))

        object.append("distributor_org_id", balanceDataDetails.organization_id)
        object.append("file_id", props.match.params.id)
        object.append("organization_id", organization_id)
        object.append("page_name", page_name)

        // console.log(object);

        setSubmitButtonState(true)

        var headers = {
            headers: {
                "Content-Type": "application/json",
                "Accept": "*/*",
                "token": getCookie("access_token")
            }
        }
        axios.post(`${process.env.REACT_APP_SERVER_URL}/submit-user-balance-details`, object, headers).then((res) => {
            setSubmitButtonState(false)
            if (res.data.operation === "success") {
                swal("Great!", "data saved successfully!", "success").then(() => { window.location.reload() })
            }
            else {
                swal("Oops!", "Something went wrong!", "error");
            }
        })
            .catch(function (error) {
                console.log(error)
            });
    }

    const submitResponse = () => {

        if (uploadResponseDescription === "") {
            swal("Oops!", "Enter a proper response for decline", "warning");
            return
        }

        if (uploadedFiles.length > 3) {
            swal("Oops!", "Only 3 files can be uploaded at a time", "warning")
            return
        }

        const object = new FormData()

        object.append("operation_type", "RESPONSE")

        object.append("user_message", uploadResponseDescription)

        attachment.forEach(obj => {
            object.append("data_files", obj)
        })

        object.append("distributor_org_id", balanceDataDetails.organization_id)
        object.append("file_id", props.match.params.id)
        object.append("organization_id", organization_id)
        object.append("page_name", page_name)

        // console.log(object);

        setSubmitResponseButtonState(true)

        var headers = {
            headers: {
                "Content-Type": "application/json",
                "Accept": "*/*",
                "token": getCookie("access_token")
            }
        }
        axios.post(`${process.env.REACT_APP_SERVER_URL}/submit-user-balance-details`, object, headers).then((res) => {
            setSubmitResponseButtonState(false)
            if (res.data.operation === "success") {
                swal("Great!", "data saved successfully!", "success").then(() => { window.location.reload() })
            }
            else {
                swal("Oops!", "Something went wrong!", "error");
            }
        })
            .catch(function (error) {
                console.log(error)
            });
    }

    return (
        <>
            {
                (redirect === 1 || (read === 0 && write === 0)) && <Redirect to={{ pathname: "/unauthorized" }} />
            }
            {
                redirect_to_login === 1 && <Redirect to={{ pathname: "/login" }} />
            }

            <div className="d-flex flex-column flex-column-fluid">
                <div className="app-toolbar py-3 py-lg-6" id="kt_app_toolbar">
                    <div id="kt_app_toolbar_container" className="app-container container-xxl d-flex flex-stack">
                        <h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">Dealer Balance Details</h1>
                    </div>
                </div>
                <div id="kt_app_content" className="app-content flex-column-fluid">
                    <div id="kt_app_content_container" className="app-container container-xxl">
                        {state === 1 ?
                            <>
                                <div className="d-flex flex-column gap-7 gap-lg-10">
                                    <div className="d-flex flex-wrap flex-stack gap-5 gap-lg-10">
                                        <ul className="nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-semibold mb-lg-n2 me-auto" role="tablist">
                                            <li className="nav-item" role="presentation">
                                                <a className="nav-link text-active-primary pb-4 active" data-bs-toggle="tab" href="#balance_summary" aria-selected="true" role="tab">Balance Summary</a>
                                            </li>
                                            <li className="nav-item" role="presentation">
                                                <a className="nav-link text-active-primary pb-4" data-bs-toggle="tab" href="#conversation_history" aria-selected="false" role="tab" tabIndex="-1">Conversation Details</a>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="tab-content">
                                        <div className="tab-pane fade active show" id="balance_summary" role="tab-panel">
                                            <div className="d-flex flex-column gap-7 gap-lg-10">
                                                {
                                                    balanceDataDetails.status === "PENDING_CONFIRMATION" &&
                                                    <div className="row mb-5">
                                                        <div className='col-md-8'>
                                                            <label className="fs-5 fw-bold mb-2">
                                                                Please confirm or decline the details accordingly :
                                                            </label>
                                                        </div>
                                                        <div className='col-md-4 d-flex gap-4'>
                                                            <label className="fs-5 mb-3">
                                                                {
                                                                    write === 1 &&
                                                                    <button className="btn btn-sm btn-secondary btn-active-info" data-kt-indicator={submitButtonState ? "on" : "off"} disabled={submitButtonState} onClick={() => { submitConfirmation() }}                                                                     >
                                                                        <span className="indicator-label">Confirm</span>
                                                                        <span className="indicator-progress">Please wait...
                                                                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                                        </span>
                                                                    </button>
                                                                }
                                                            </label>
                                                            {
                                                                balanceDataDetails.operation_type !== "INIT_DECLINED" ?
                                                                    <label className="fs-5 mb-3">
                                                                        {
                                                                            write === 1 &&
                                                                            <button className="btn btn-sm btn-secondary btn-active-danger" data-kt-indicator={balanceDataDetails === null ? "on" : "off"} disabled={balanceDataDetails === null} onClick={() => { setDeclineModalShow(true) }}>
                                                                                <span className="indicator-label">Decline</span>
                                                                                <span className="indicator-progress">Please wait...
                                                                                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                                                </span>
                                                                            </button>
                                                                        }
                                                                    </label>
                                                                    :
                                                                    <label className="fs-5 mb-3">
                                                                        {
                                                                            write === 1 &&
                                                                            <button className="btn btn-sm btn-secondary btn-active-danger" onClick={() => { setRespondModalShow(true) }}>
                                                                                <span className="indicator-label">Respond</span>
                                                                            </button>
                                                                        }
                                                                    </label>
                                                            }
                                                        </div>
                                                    </div>
                                                }


                                                <div className="card card-flush py-4 flex-row-fluid overflow-hidden">
                                                    <div className="card-body pt-0">

                                                        <div className="d-flex justify-content-between flex-column">
                                                            <div className="table table-striped border-bottom mb-9">
                                                                <table className="table align-middle table-row-dashed fs-6 gy-5 mb-0">
                                                                    <thead>
                                                                        <tr className="border-bottom fs-6 fw-bold text-center text-muted">
                                                                            <th className="min-w-70px">Brand Name</th>
                                                                            <th className="min-w-70px">Dealer Name</th>
                                                                            <th className="min-w-150px">Dealer Email</th>
                                                                            <th className="min-w-150px">Status</th>
                                                                            <th className="min-w-70px">Recievable Amount</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody className="fw-semibold text-center text-gray-600">
                                                                        <tr>
                                                                            <td><div className="fw-bold">{balanceDataDetails.brand_name}</div></td>
                                                                            <td><div className="fw-bold">{balanceDataDetails.dealer_name}</div></td>
                                                                            <td>{balanceDataDetails.dealer_email}</td>
                                                                            <td className={balanceDataDetails.status === 'PENDING_CONFIRMATION' ? 'text-warning' : balanceDataDetails.status === 'CONFIRMED' ? 'text-success' : ''}>
                                                                                {
                                                                                    balanceDataDetails.status.replace("_CONFIRMATION", "").toLowerCase()
                                                                                        .replace(/_/g, ' ').replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())
                                                                                }
                                                                            </td>
                                                                            <td>{currencyCode} {balanceDataDetails.recievable_amount}</td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                        <div className="tab-pane fade" id="conversation_history" role="tab-panel">
                                            <div className="d-flex flex-column gap-7 gap-lg-10">
                                                <div className="card card-flush py-4 flex-row-fluid">

                                                    <div className="card-body">
                                                        {
                                                            balanceDataDetails &&
                                                            <div className="card" >
                                                                <div className="card-header" >
                                                                    <div className="card-title">
                                                                        <div className="d-flex justify-content-center flex-column me-3">
                                                                            <span className="fs-2 fw-bold text-hover-primary">{balanceDataDetails.organization_name}</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="card-body" >
                                                                    <div className="scroll-y me-n5 pe-5 h-300px h-lg-auto" style={{ maxHeight: "432px" }}>
                                                                        {
                                                                            balanceDataDetails.conversation_details.map((x, i) => {
                                                                                return (
                                                                                    x.user_type === "DEALER" ?
                                                                                        <div key={i} className="d-flex justify-content-end mb-10">
                                                                                            <div className="d-flex flex-column align-items-end">
                                                                                                <div className="d-flex align-items-center mb-2">
                                                                                                    <div className="me-3">
                                                                                                        <span className="text-muted fs-7 mb-1">{moment(x.timestamp).utcOffset(timezoneChange).format("lll")}</span>
                                                                                                        <a href="#" className="fs-5 fw-bold text-gray-900 text-hover-primary ms-1">You</a>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="p-5 rounded bg-light-primary text-dark fw-semibold mw-lg-600px text-end">
                                                                                                    {/* <span className='text-muted'> Payable Amt: </span> <span className='fs-4 text-hover-success' style={{ fontWeight: "bold" }}>{x.dealer_estimate}</span><br /> */}
                                                                                                    <span className='text-muted'> Payable Amt: </span> <span className='fs-4 text-hover-success' style={{ fontWeight: "bold" }}>{balanceDataDetails.dealer_estimate}</span><br />
                                                                                                    {x.message}<br />
                                                                                                    {
                                                                                                        x.scheme_details && x.scheme_details.length > 0 &&
                                                                                                        <div className="bg-white mt-4" style={{ overflowX: "auto", border: "1px solid #dee2e6" }}>
                                                                                                            <table className="table align-middle table-row-dashed fs-6 gy-5 mb-0">
                                                                                                                <thead>
                                                                                                                    <tr className="border-bottom fs-6 fw-bold text-center text-muted">
                                                                                                                        <th className="min-w-125px">From Date</th>
                                                                                                                        <th className="min-w-125px">To Date</th>
                                                                                                                        <th className="min-w-125px">Scheme type</th>
                                                                                                                        <th className="min-w-125px">Scheme ref</th>
                                                                                                                        <th className="min-w-175px">Scheme details</th>
                                                                                                                        <th className="min-w-125px">Claim amt</th>
                                                                                                                        <th className="min-w-125px">Remarks</th>
                                                                                                                    </tr>
                                                                                                                </thead>
                                                                                                                <tbody className="table-hover text-center">
                                                                                                                    {
                                                                                                                        x.scheme_details.map((item, index) => (
                                                                                                                            <tr key={index}>
                                                                                                                                <td>{moment(item.from_date).format("ll")}</td>
                                                                                                                                <td>{moment(item.to_date).format("ll")}</td>
                                                                                                                                <td>{item.scheme_type}</td>
                                                                                                                                <td>{item.scheme_ref}</td>
                                                                                                                                <td>{item.scheme_details}</td>
                                                                                                                                <td>{item.claim_amt}</td>
                                                                                                                                <td>{item.remark}</td>
                                                                                                                            </tr>
                                                                                                                        ))}
                                                                                                                </tbody>
                                                                                                            </table>
                                                                                                        </div>
                                                                                                    }<br />
                                                                                                    {
                                                                                                        x.uploaded_files.length > 0 &&
                                                                                                        <div>
                                                                                                            {
                                                                                                                x.uploaded_files.map((file, index) => {
                                                                                                                    let t = file.original_filename.split(".")
                                                                                                                    let f = file.original_filename.length > 10 ? file.original_filename.substring(0, 10) + "... ." + t[t.length - 1] : file.original_filename
                                                                                                                    return (
                                                                                                                        <div key={index} className='bg-white my-2 p-3 rounded shadow text-center' >
                                                                                                                            <a target="_blank" className="fw-semibold fs-6 text-primary" href={`${process.env.REACT_APP_GENERAL_IMAGE_BUCKET}/balance_sheet/documents/${balanceDataDetails.organization_id}/${file.filename}`} title={file.original_filename}>
                                                                                                                                {f}
                                                                                                                            </a>
                                                                                                                        </div> // use distributor org_id in the path
                                                                                                                    )
                                                                                                                })
                                                                                                            }
                                                                                                        </div>
                                                                                                    }
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        :
                                                                                        <div key={i} className="d-flex justify-content-start mb-10" style={{ width: "100%" }}>
                                                                                            <div className="d-flex flex-column align-items-start" style={{ width: "100%" }}>
                                                                                                <div className="d-flex align-items-center mb-2">
                                                                                                    <div className="ms-3">
                                                                                                        <a href="#" className="fs-5 fw-bold text-gray-900 text-hover-primary me-1">{balanceDataDetails.organization_name}</a>
                                                                                                        <span className="text-muted fs-7 mb-1">{moment(x.timestamp).utcOffset(timezoneChange).format("lll")}</span>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="p-5 rounded bg-light-dark text-dark fw-semibold w-50 text-start"                                                                             >
                                                                                                    <span className='text-muted'> Recievable Amt: </span> <span className='fs-4 text-hover-success' style={{ fontWeight: "bold" }}>{x.distributor_estimate}</span><br />
                                                                                                    {x.message}<br />
                                                                                                    {x.update_message ? <>{x.update_message}<br /></> : ""}
                                                                                                    {
                                                                                                        x.uploaded_files.length > 0 &&
                                                                                                        <div>
                                                                                                            {
                                                                                                                x.uploaded_files.map((file, index) => {
                                                                                                                    let t = file.original_filename.split(".")
                                                                                                                    let f = file.original_filename.length > 10 ? file.original_filename.substring(0, 10) + "... ." + t[t.length - 1] : file.original_filename
                                                                                                                    return (
                                                                                                                        <div key={index} className='bg-white my-2 p-3 rounded shadow text-center' >
                                                                                                                            <a target="_blank" className="fw-semibold fs-6 text-primary" href={`${process.env.REACT_APP_GENERAL_IMAGE_BUCKET}/balance_sheet/documents/${balanceDataDetails.organization_id}/${file.filename}`} title={file.original_filename}>
                                                                                                                                {f}
                                                                                                                            </a>
                                                                                                                        </div>
                                                                                                                    )
                                                                                                                })
                                                                                                            }
                                                                                                        </div>
                                                                                                    }
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                );
                                                                            })
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </>
                            : state === 2 ?
                                <div className="card modal-body pt-0 pb-15 px-5 px-xl-20">
                                    <img src="/images/loader-06.svg" alt="" style={loaderstyle} />
                                </div>
                                :
                                <div className="card modal-body px-5 px-xl-20">
                                    <div className="flex-lg-row-fluid ">
                                        <div className="card card-flush my-5">

                                            <div className="card-body">
                                                <div className="notice bg-light-danger rounded border-primary border border-dashed rounded-3 p-6">
                                                    <h4 className="text-gray-800 fw-bolder">Something went wrong</h4>
                                                    <div className='mt-10 text-center'>
                                                        <button className='btn btn-success rounded' onClick={() => { window.location.reload() }}>Reload the page</button>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                        }
                    </div>
                </div>
            </div>

            <Modal show={declineModalShow} onHide={() => { handleModalClose() }} centered size="lg">
                <Modal.Header className="bg-light">
                    <Modal.Title>Decline Confirmation</Modal.Title>
                    <div>
                        {
                            wizardPanel !== 1 &&
                            <button className="btn btn-link text-primary"
                                onClick={() => {
                                    setWizardPanel(1);
                                    setTableDataArray([{ from_date: moment.utc().subtract(7, "days").format('YYYY-MM-DD'), to_date: moment.utc().format('YYYY-MM-DD'), scheme_type: "", scheme_ref: "", scheme_details: "", claim_amt: 0, remark: "" }])
                                }}>
                                <span className="d-flex align-items-center">
                                    <i className="bi bi-caret-left-square-fill fs-1 text-hover-danger mr-2"></i>
                                    <span className="indicator-label">Previous</span>
                                </span>
                            </button>
                        }
                        {
                            write === 1 &&
                            <button className="btn btn-link mx-5 text-primary" data-kt-indicator={submitButtonState ? "on" : "off"} disabled={submitButtonState}
                                onClick={() => {
                                    if (remainingBalance === 0) {
                                        swal("Oops", "Please provide a valid payable amount to proceed", "warning")
                                        return
                                    }
                                    wizardPanel === 2 ? validateBalance() && submitDetails() : setWizardPanel(wizardPanel + 1)
                                }}>
                                <span className="indicator-label">
                                    {wizardPanel !== 2 ? (
                                        <>
                                            <i className="bi bi-caret-right-square-fill fs-1 text-hover-primary mr-2"></i>
                                            Next
                                        </>
                                    ) : (
                                        <>
                                            <i className="bi bi-arrow-up-circle-fill text-success" style={{ fontSize: "2rem" }}></i>
                                            submit
                                        </>
                                    )}
                                </span>
                                <span className="indicator-progress">Please wait...
                                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                </span>
                            </button>
                        }
                        <Button variant="link" className="text-hover-danger" onClick={() => { handleModalClose() }}>✖</Button>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    {
                        wizardPanel === 1 ?
                            <>
                                <div className="row p-3">
                                    <div className='col-md-8'>
                                        <label className="fs-5 mb-2 text-gray-700">
                                            Recievable Amount
                                        </label>
                                    </div>
                                    <div className='col-md-4'>
                                        {balanceDataDetails !== null && balanceDataDetails.recievable_amount}
                                    </div>
                                </div>
                                <div className="row p-3">
                                    <div className='col-md-8'>
                                        <label className="fs-5 mb-2 text-gray-700">
                                            Payable Amount
                                        </label>
                                    </div>
                                    <div className='col-md-4'>
                                        <input type="number" className="form-control mb-2" placeholder="Enter the amount" value={payableAmount.toString()}
                                            onChange={(e) => {
                                                let val = e.target.value === "" ? 0 : parseFloat(e.target.value)
                                                setPayableAmount(val);
                                                setRemainingBalance(val > balanceDataDetails.recievable_amount ? val - balanceDataDetails.recievable_amount : balanceDataDetails.recievable_amount - val);
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="row p-3">
                                    <div className='col-md-8'>
                                        <label className="fs-5 mb-2 text-gray-700">
                                            Balance
                                        </label>
                                    </div>
                                    <div className='col-md-4'>
                                        <input type="number" className="form-control form-control-solid mb-2" value={remainingBalance.toString()} readOnly placeholder="enter payable amount." />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <textarea
                                        className="form-control"
                                        placeholder='Provide a valid reason for decline'
                                        rows="2"
                                        value={declineReason}
                                        onChange={(e) => setDeclineReason(e.target.value)}
                                    ></textarea>
                                </div>
                                <div className="row p-3">
                                    <div className='col-md-8'>
                                        <label className="fs-5 mb-2 text-gray-700">
                                            Upload additional files(optional):
                                        </label>
                                    </div>
                                    <div className='col-md-4 d-flex gap-4'>
                                        <i className="bi bi-file-arrow-up cursor-pointer fs-2qx text-hover-primary" onClick={() => setOpenDropzone(true)}></i>
                                        <i className="bi bi-x-circle-fill cursor-pointer fs-2qx text-hover-danger" onClick={() => { setOpenDropzone(false); setAttachment([]); }}></i>
                                    </div>
                                </div>

                                {openDropzone && (
                                    <div {...getRootProps()} style={{ border: '1px dashed #ccc', padding: '20px', textAlign: 'center', marginTop: '20px' }}>
                                        <input {...getInputProps()} />
                                        <span>Drag 'n' drop some files here, or click to select files</span>
                                    </div>
                                )}
                                <ul className="list-group mt-2">
                                    {attachment.map(file => (
                                        <li key={file.path} className="list-group-item d-flex">
                                            <span className='flex-grow-1'>{file.name}</span>
                                            <span className="badge badge-primary">{(file.size / (1024).toFixed(3))} KB</span>
                                            <span className="text-hover-danger ps-3 cursor:pointer" onClick={() => { setAttachment([]) }}>✖</span>
                                        </li>
                                    ))}
                                </ul>
                            </> :
                            <>
                                <div className="card-body pt-0" >
                                    <div className='d-flex align-items-center'>
                                        <label className="fs-5 me-2 text-gray-700">Payable:</label>
                                        <span className=" fs-2 me-10 fw-bold text-primary">{payableAmount.toString()}</span>
                                        <label className="fs-5 me-2 text-gray-700">Total claim amt:</label>
                                        <span className=" fs-2 fw-bold text-primary">{tableDataArray.reduce((total, item) => total + parseFloat(item.claim_amt), 0) ? tableDataArray.reduce((total, item) => total + parseFloat(item.claim_amt), 0) : 0}</span>
                                    </div>

                                    <div className="d-flex justify-content-between flex-column w-100" style={{ overflowX: "auto", backgroundColor: "#F0F8FF" }}>
                                        <div className="table table-striped border-bottom mb-9">
                                            <table className="table align-middle table-row-dashed fs-6 gy-5 mb-0">
                                                <thead>
                                                    <tr className="border-bottom fs-6 fw-bold text-center text-muted">
                                                        <th className="required min-w-125px">Form Date</th>
                                                        <th className="required min-w-125px">To Date</th>
                                                        <th className="required min-w-125px">Scheme type</th>
                                                        <th className="required min-w-125px">Scheme ref</th>
                                                        <th className="required min-w-175px">Scheme details</th>
                                                        <th className="required min-w-125px">Claim amt</th>
                                                        <th className="min-w-125px">Remarks</th>
                                                    </tr>
                                                </thead>
                                                <tbody className="table-hover text-center">
                                                    {tableDataArray.map((item, index) => (
                                                        <tr key={index}>
                                                            <td>
                                                                <input
                                                                    type="date"
                                                                    className="form-control"
                                                                    value={item.from_date}
                                                                    onChange={(e) => {
                                                                        const newArray = tableDataArray.map(x => { return { ...x } });
                                                                        newArray[index].from_date = e.target.value;
                                                                        setTableDataArray(newArray);
                                                                    }}
                                                                />
                                                            </td>
                                                            <td>
                                                                <input
                                                                    type="date"
                                                                    className="form-control"
                                                                    value={item.to_date}
                                                                    onChange={(e) => {
                                                                        const newArray = tableDataArray.map(x => { return { ...x } });
                                                                        newArray[index].to_date = e.target.value;
                                                                        setTableDataArray(newArray);
                                                                    }}
                                                                />
                                                            </td>
                                                            <td>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    value={item.scheme_type}
                                                                    onChange={(e) => {
                                                                        const newArray = tableDataArray.map(x => { return { ...x } });
                                                                        newArray[index].scheme_type = e.target.value;
                                                                        setTableDataArray(newArray);
                                                                    }}
                                                                />
                                                            </td>
                                                            <td>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    value={item.scheme_ref}
                                                                    onChange={(e) => {
                                                                        const newArray = tableDataArray.map(x => { return { ...x } });
                                                                        newArray[index].scheme_ref = e.target.value;
                                                                        setTableDataArray(newArray);
                                                                    }}
                                                                />
                                                            </td>
                                                            <td>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    value={item.scheme_details}
                                                                    onChange={(e) => {
                                                                        const newArray = tableDataArray.map(x => { return { ...x } });
                                                                        newArray[index].scheme_details = e.target.value;
                                                                        setTableDataArray(newArray);
                                                                    }}
                                                                />
                                                            </td>
                                                            <td>
                                                                <input
                                                                    type="number"
                                                                    className="form-control"
                                                                    value={item.claim_amt.toString()}
                                                                    onChange={(e) => {
                                                                        const newArray = tableDataArray.map(x => { return { ...x } });
                                                                        newArray[index].claim_amt = e.target.value === "" ? 0 : parseFloat(e.target.value);
                                                                        setTableDataArray(newArray);
                                                                    }}
                                                                />
                                                            </td>
                                                            <td>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    value={item.remark}
                                                                    onChange={(e) => {
                                                                        const newArray = tableDataArray.map(x => { return { ...x } });
                                                                        newArray[index].remark = e.target.value;
                                                                        setTableDataArray(newArray);
                                                                    }}
                                                                />
                                                            </td>
                                                            <td>
                                                                {
                                                                    tableDataArray.length > 1 &&
                                                                    <button
                                                                        className="btn btn-danger btn-sm rounded-pill"
                                                                        onClick={() => {
                                                                            const newArray = tableDataArray.map(x => { return { ...x } });
                                                                            newArray.splice(index, 1);
                                                                            setTableDataArray(newArray);
                                                                        }}
                                                                    >
                                                                        <i className="bi bi-x fs-1 p-0"></i>
                                                                    </button>
                                                                }
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                            {
                                                (tableDataArray.reduce((total, item) => total + parseFloat(item.claim_amt), 0) < payableAmount) &&
                                                <button
                                                    className="btn btn-primary"
                                                    onClick={() => { setTableDataArray([...tableDataArray, { from_date: moment.utc().subtract(7, "days").format('YYYY-MM-DD'), to_date: moment.utc().format('YYYY-MM-DD'), scheme_type: "", scheme_ref: "", scheme_details: "", claim_amt: 0, remark: "" }]); }}
                                                >
                                                    Add Row
                                                </button>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </>
                    }
                </Modal.Body>
            </Modal >


            <Modal show={respondModalShow} onHide={handleRespondModalClose} centered>
                <Modal.Header className="bg-light">
                    <Modal.Title>Compose your response</Modal.Title>
                    <div>
                        {
                            write == 1 && uploadResponseDescription !== "" &&
                            <button className="btn btn-link mx-5 text-primary p-0" data-kt-indicator={submitResponseButtonState ? "on" : "off"} disabled={submitResponseButtonState}
                                onClick={() => { submitResponse() }}
                            >
                                <span className="indicator-label"><i className="bi bi-arrow-up-circle-fill px-0 text-hover-success" style={{ fontSize: "xx-large" }}></i></span>
                                <span className="indicator-progress">Please wait...
                                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                </span>
                            </button>
                        }
                        <Button className="btn btn-danger btn-sm" variant="danger" onClick={handleRespondModalClose}>✖</Button>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className="form-group">
                        <textarea
                            className="form-control mb-4"
                            placeholder='Provide a detailed message'
                            rows="2"
                            value={uploadResponseDescription}
                            onChange={(e) => setUploadResponseDescription(e.target.value)}
                        ></textarea>
                    </div>

                    <div className="row p-3">
                        <div className='col-md-8'>
                            <label className="fs-5 mb-2 text-gray-700">
                                Upload additional files(optional):
                            </label>
                        </div>
                        <div className='col-md-4 d-flex gap-4'>
                            <i className="bi bi-file-arrow-up cursor-pointer fs-2qx text-hover-primary" onClick={() => setOpenDropzone(true)}></i>
                            <i className="bi bi-x-circle-fill cursor-pointer fs-2qx text-hover-danger" onClick={() => { setOpenDropzone(false); setAttachment([]); }}></i>
                        </div>
                    </div>

                    {openDropzone && (
                        <>
                            <div {...getRootProps()} style={{ border: '1px dashed #ccc', padding: '20px', textAlign: 'center', marginTop: '20px' }}>
                                <input {...getInputProps()} />
                                <span>Drag 'n' drop some files here, or click to select files</span>
                            </div>
                            <span className='text-muted'> Please select a maximum of 3 documents at a time</span>
                        </>
                    )}
                    <ul className="list-group mt-2">
                        {attachment.map((file, indx) => (
                            <li key={file.path} className="list-group-item d-flex">
                                <span className='flex-grow-1'>{file.name}</span>
                                <span className="badge badge-primary">{file.size} bytes</span>
                                <span className="text-hover-danger ps-3 cursor:pointer"
                                    onClick={() => {
                                        let t = [...attachment]
                                        t.splice(indx, 1)
                                        setAttachment(t)
                                    }}>✖
                                </span>
                            </li>
                        ))}
                    </ul>
                </Modal.Body>
            </Modal>

            <Organization_modal OrganizationModalShow={OrganizationModalShow} setOrganizationModalShow1={setOrganizationModalShow} organization_name={organization_name} organization_list={[]} organization_id={organization_id} state_org={state_org} />
            <Timer_modal clockTimerModalShow={clockTimerModalShow} setClockTimerModalShow1={setClockTimerModalShow} clock_timer={clock_timer} organization_id={organization_id} timer_ref={timer} />
        </>);

};

export default BalanceDetails;
