
import React, { lazy, Suspense, useState, useEffect,useRef } from 'react';
import ReactDOM from 'react-dom';
//import process.env from '../config.json';
import AsideNavbar from './aside-navbar.js';
import Header from './header.js';
import Footer from './footer.js';
import axios from 'axios';
import swal from 'sweetalert';
import $ from 'jquery';
import moment from 'moment';
import { Modal } from 'react-bootstrap';
import { Link,Redirect } from 'react-router-dom';

import Select from 'react-select';

import Picker from "react-month-picker";
import "react-month-picker/css/month-picker.css";

import {setCookie,getCookie} from '../common_component/cookie.js';
import Organization_modal from '../common_component/organization_modal.js';
import Timer_modal from '../common_component/timer_modal.js';

export const Approvals = () => {

	const timer_interval_ref = useRef();
	const [clockTimerModalShow, setClockTimerModalShow] = useState(false);
    const [state_org, setstate_org] = useState(2);
	const [organization_name, setOrganization_name] = useState("");
	const [OrganizationModalShow, setOrganizationModalShow] = useState(false);
	const [redirect_to_login, setRedirect_to_login] = useState(2);

	const [Approval_info, setApproval_info] = useState([{key:"sale_approval",type:"not_configure",value:[]},{key:"purchase_approval",type:"not_configure",value:[]},{key:"inventory_approval",type:"not_configure",value:[]},{key:"dealer_order_approval",type:"not_configure",value:[]},{key:"dealer_invoice_approval",type:"not_configure",value:[]},{key:"Expense_approval",type:"not_configure",value:[]},{key:"Bill_approval",type:"not_configure",value:[]}]);
	const [notification_info, setnotification_info] = useState([{key:"sale_approval",type:"Send email and in-app notifications when transactions are submitted for approval"},{key:"purchase_approval",type:""},{key:"inventory_approval",type:"Send email and in-app notifications when transactions are submitted for approval"},{key:"dealer_order_approval",type:"Send email and in-app notifications when transactions are submitted for approval"},{key:"dealer_invoice_approval",type:"Send email and in-app notifications when transactions are submitted for approval"},{key:"Expense_approval",type:"Send email and in-app notifications when transactions are submitted for approval"},{key:"Bill_approval",type:"Send email and in-app notifications when transactions are submitted for approval"}]);
	const [UserValuelist, setUserValuelist] = useState([]);
	const [UserValuelist1, setUserValuelist1] = useState([]);
	const [UserTypelist, setUserTypelist] = useState([{ label: "Individual User", value: "Individual User" },{ label: "Group User", value: "Group User" }]);

	const [approval_id, setapproval_id] = useState("");
	const [accessToken, setaccessToken] = useState('');
	const [state, setstate] = useState(2);
	const [loading, setloading] = useState(true);
	const [user, setUser] = useState({});
	const [username, setUsername] = useState("");
	const [user_id, setUser_id] = useState("");
	const [Organization_id, setOrganization_id] = useState("");
	const [navtype, setnavtype] = useState("sale_approval");
    const [organization_list, setorganization_list] = useState([]);
	const [Configured, setConfigured] = useState([]);
	const [Userlist, setUserlist] = useState([]);
	const [RoleList, setRoleList] = useState([]);
	

	const [visibility, setvisibility] = useState(0);
    const [redirect, setredirect] = useState(0);
    const [Selected_organization_id, setSelected_organization_id] = useState("");
    const [read, setread] = useState(2);
    const [write, setwrite] = useState(2);
	const [userTheme,setUserTheme]=useState((localStorage.getItem("kt_metronic_theme_mode_menu")=="system")?window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light":localStorage.getItem("kt_metronic_theme_mode_menu"))

	const [mainSubmitButtonState, setMainSubmitButtonState] = useState(false);

	const [TaxGrouplist, setTaxGrouplist] = useState([]);
    const [Taxlist, setTaxlist] = useState([]);

	let [timer, setTimer] = useState(0);
	let [clock_timer, setClockTimer] = useState(0);
	var page_name = "show-organizations";
	let count = 3;
	
	const loaderstyle = {
		display: "block",
		marginLeft: "auto",
		marginRight: "auto",
		marginTop: "auto",
		marginBottom: "auto",
	};

	let dropdownStyleDark = {
        option: (provided, state) => ({
            ...provided,
            color: '#dddddf',
            backgroundColor: state.isSelected?provided.backgroundColor: state.isFocused ? '#5f5f8d':provided.backgroundColor ,
        }),
        control: (provided, state) => ({
            ...provided,
            backgroundColor: "#2b2b40",
            borderColor:"#4c4c71"
        }),
        placeholder: (provided, state) => ({
            ...provided,
            color: "#dddddf",
        }),
        singleValue: (provided, state) => ({
            ...provided,
            color: "#dddddf",
        }),
        menu: (provided, state) => ({
            ...provided,
            backgroundColor: "#2b2b40",
        })
    }

	useEffect(() => {
        let id = setInterval(()=>{
          setUserTheme((localStorage.getItem("kt_metronic_theme_mode_menu")=="system")?window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light":localStorage.getItem("kt_metronic_theme_mode_menu"))
        },10)
      
        return () => {
          clearInterval(id)
        }
    }, [])

	useEffect(() => {
        if (getCookie("access_token") != "") {
            if(getCookie("organization_id")!="")
            {
                setOrganization_id(getCookie("organization_id"));  
            }
            else
            {
                var object = {};
                object.page_name=page_name;
                var headers = {
                    headers: {
                        "Content-Type": "application/json",
                        "token": getCookie("access_token"),
                        "Accept": "*/*"
                    }
                }

                axios.post(`${process.env.REACT_APP_SERVER_URL}/show-default-organization`, object, headers).then((res) => {

                    if (res.data.length>0) {
                        setCookie('organization_id', res.data[0].organization_id, 1,true);
                        setOrganization_id(res.data[0].organization_id)
                    }
                
                })    
            }
        }
        else {
            setRedirect_to_login(1);
        }
    }, [])

	useEffect(() => {
		setCookie("lastpage", window.location.href, 1,false)
		const validateJWT = () => {

			if (getCookie("access_token") != "") {
				var object = {};
				object.token = getCookie("access_token");

				var headers = {
					headers: {
						"Content-Type": "application/json",
						"Accept": "*/*"
					}
				}
				axios.post(`${process.env.REACT_APP_SERVER_URL}/get-user`, object, headers).then((res) => {

					if (res.data.operation == "success") {
						var user_object = {};
						user_object.email = res.data.email;
						user_object.name = res.data.name;
						user_object.img = res.data.img;
						setUser_id(user_object.email)
						setUser(user_object)
						setUsername(res.data.name)
					}
					else {
						swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
							window.location.href = "/login"
						});
					}
				})
					.catch(function (error) {
						swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
							window.location.href = "/login"
						});
					});
				object.organization_id=Organization_id;
				axios.post(`${process.env.REACT_APP_SERVER_URL}/audience-info`, object, headers).then((res) => {

					if (res.data.operation == "success") {
						var token = res.data.audience_license;
						if (res.data.organization_flag == 0) {
                            setstate_org(3);
							setOrganization_name(res.data.organization_name);
							setOrganizationModalShow(true);
                        }
                        else {

                            var path = window.location.pathname;
                            path = path.replace(/\/$/, "");
                            path = decodeURIComponent(path);
                            var original_url = path.split("/")[1]
                            let index = 0;
							let temp = -1;
                            for (let i = 0; i < res.data.user_access.access.length; i++) 
							{
								if(temp == 1) break;
                                let component = res.data.user_access.access[i].inside_components;
                                for (let j = 0; j < component.length; j++) {
                                    if (component[j].hasOwnProperty(original_url)) {
                                        index = i;
                                        if (res.data.user_access.access[index].inside_components[j][original_url] == 1 && res.data.user_access.access[index].visibility == 1) {
											temp = 1;
                                            setvisibility(1);
                                            setread(res.data.user_access.access[index].inside_components[j]["read"])
                                            setwrite(res.data.user_access.access[index].inside_components[j]["write"])
                                            // setwrite(0)
                                            break;
                                        }
                                        else {
                                            setredirect(1);
                                        }

                                    }
									else if(component[j].hasOwnProperty("key")){
                                        let sub_inside_components= component[j].inside_components
                                        for (let k = 0; k < sub_inside_components.length; k++) {
                                            if(sub_inside_components[k].hasOwnProperty(original_url)){
                                                index=i
                                                if (sub_inside_components[k][original_url] == 1 && res.data.user_access.access[index].visibility == 1 && component[j].visibility == 1) {
                                                    temp = 1;
                                                    setvisibility(1);
                                                    setread(sub_inside_components[k]["read"])
                                                    setwrite(sub_inside_components[k]["write"])
                                                    // setwrite(0)
                                                    break;
                                                    
                                                }
                                                else {
                                                    setredirect(1);
                                                }
                                            }
                                        }
                                    }
									else
									{
										temp = 2;
									}
                                }
                            }
							
							if(temp == 2)
                            {
                                setredirect(1);
                            }
                        }

						if (!token.includes("ufhdsupw29fher") || !token.includes("pdbwu27dh6schs") || !token.includes("0dh27agiqbcrwi")) {
							setCookie('access_token', res.data.audience_license, 1,true)
							setTimer(process.env.REACT_APP_JWT_EXPIRY);
							//setClockTimer(process.env.REACT_APP_JWT_EXPIRY);
						}
						else {
							setTimer(res.data.audience_count)
							//setClockTimer(res.data.audience_count)
						}
					}
					else {
						setRedirect_to_login(1);
					}
				})
					.catch(function (error) {
						console.log(error);
						setRedirect_to_login(1);
					});
			}
			else {
				setRedirect_to_login(1);
			}
		}
		Organization_id!="" && validateJWT();
		
	}, [Organization_id])

	useEffect(() => {
        timer_interval_ref.current = setInterval(() => {
            setTimer((timer)=>{
                if (timer > 0 && timer < process.env.REACT_APP_JWT_EXPIRY_NOTICE) {
                    if (!clockTimerModalShow) {
                        setClockTimer(process.env.REACT_APP_JWT_EXPIRY_NOTICE)
                        setClockTimerModalShow(true)
                    }
                }
                return timer - 1
            })
        }, 1000);
        
        return () => {clearInterval(timer_interval_ref)};
    }, []);

	useEffect(() => {
        if ((read || write) && (user.email && visibility)) {
            let p1 = getapprovaldetails()
            let p2 = getuserdetails()
            let p3 = getroledetails()
            Promise.all([p1, p2, p3])
                .then(() => {
					setstate(1);
                    console.log("all api done")
                })
                .catch((err) => {
					setstate(3)
                    console.log(err)
                })
        }
    }, [user, visibility, read, write])

        async function getuserdetails() {
            var object = {};
            object.email = user.email;

            const response = await fetch(
                `${process.env.REACT_APP_SERVER_URL}/user-datatable`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name,"organization_id":Organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
            );
            const body = await response.json();
      
            if (body.length > 0) {
                var temp_dropdown = [];
                body.map((obj) => {
                    if (obj.log_status === "Active") {
                        var temp_obj = {};
                        temp_obj.label = obj.name;
                        temp_obj.value = obj.user_id;
                        temp_dropdown.push(temp_obj);
                    }
                })
				//console.log(temp_dropdown);
				setUserlist(temp_dropdown);
            }
        }

		async function getroledetails() {
            var object = {};
            object.email = user.email;

            const response = await fetch(
                `${process.env.REACT_APP_SERVER_URL}/get-roles`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name,"organization_id":Organization_id,"service":"expense"}), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
            );
            const body = await response.json();
            if (body.length > 0) {
                var temp_dropdown = [];
                body.map((obj) => {
                    if (obj.log_status === "Active") {
                        var temp_obj = {};
                        temp_obj.label = obj.user_role;
                        //temp_obj.value = obj.user_role_id;
						temp_obj.value = obj.user_role;
                        temp_dropdown.push(temp_obj);
                    }
                })
				//console.log(temp_dropdown);
				setRoleList(temp_dropdown);
            }
        }

		async function getapprovaldetails() {
            var object = {};
            object.email = user.email;

            const response = await fetch(
                `${process.env.REACT_APP_SERVER_URL}/approval-list`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name,"organization_id":Organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
            );
            const body = await response.json();
			//console.log(body);
            if (body.operation ==="success" && body.data.length>0) {
				
				let approval=JSON.parse(body.data[0].approval_info);
				let arr=[];
				if(approval.filter(obj => obj.key =="sale_approval").length==0)
				{
					approval.push({key:"sale_approval",type:"not_configure",value:[]})
					arr.push("Sale approval");
				}
				if(approval.filter(obj => obj.key =="purchase_approval").length==0)
				{
					approval.push({key:"purchase_approval",type:"not_configure",value:[]});
					arr.push("Purchase approval");
				}
				if(approval.filter(obj => obj.key =="inventory_approval").length==0)
				{
					approval.push({key:"inventory_approval",type:"not_configure",value:[]});
					arr.push("Inventory approval");
				}
				if(approval.filter(obj => obj.key =="dealer_order_approval").length==0)
				{
					approval.push({key:"dealer_order_approval",type:"not_configure",value:[]});
					arr.push("Dealer Order approval");
				}
				if(approval.filter(obj => obj.key =="dealer_invoice_approval").length==0)
				{
					approval.push({key:"dealer_invoice_approval",type:"not_configure",value:[]});
					arr.push("Dealer Invoice approval");
				}
				if(approval.filter(obj => obj.key =="Expense_approval").length==0)
				{
					approval.push({key:"Expense_approval",type:"not_configure",value:[]});
					arr.push("Expense approval");
				}
				if(approval.filter(obj => obj.key =="Bill_approval").length==0)
				{
					approval.push({key:"Bill_approval",type:"not_configure",value:[]});
					arr.push("Bill approval");
				}
				setConfigured(arr);
				//console.log(approval);
				setApproval_info(approval);
				//setstate(1);
				//console.log(JSON.parse(body.data[0].approval_info));
				setapproval_id(body.data[0].unique_id); 
            }
			else
			{
				//setstate(1);
			}
        }


	const submitApprovalDetails = (e) => {
		e.preventDefault();
		let approval= [...Approval_info];
		console.log(Approval_info);
		let flag=false;
		for(let i=0;i<approval.length;i++)
		{
			if(approval[i].type!=="not_configure")
			{
				//console.log()
				let arr=approval[i].value;
				for(let j=0;j<arr.length;j++)
				{
					if(arr[j].user_value.length==0)
					{
						swal("Oops!", `Some field are blank(${approval[i].key.replace(/_/g, ' ')})!`, "warning");
						flag=true;
						break;
					}
					
				}
				// for(let j=0;j<approval[].length;j++)
				// {
				// 	let user_value=arr[i].user_value;
				// }
				if(flag==true)
				{
					break;
				}
			}
		}
		if(flag==true)
		{
			return false;
		}
		var values = {"page_name":page_name,"id":approval_id,"approval_info":JSON.stringify(Approval_info),"notification_info":JSON.stringify(notification_info),"organization_id":Organization_id};
		setMainSubmitButtonState(true)
		var headers = {
			headers: {
				"Content-Type": "application/json",
				"Accept": "*/*",
				"token": getCookie("access_token")
			}
		}
		if(approval_id=="")
		{
			axios.post(`${process.env.REACT_APP_SERVER_URL}/add-approval`, values, headers).then((res) => {
				setMainSubmitButtonState(false)
				if (res.data && res.data.operation == "success") {
					swal("Great!", "Approval details saved successfully!", "success").then((value) => {
						window.location.reload();
					});
				}
				else {
					swal("Oops!", "Something went wrong!", "error");
				}
			});
		}
		else
		{
			axios.post(`${process.env.REACT_APP_SERVER_URL}/update-approval`, values, headers).then((res) => {
				setMainSubmitButtonState(false)
				if (res.data && res.data.operation == "success") {
					swal("Great!", "Approval details updated successfully!", "success").then((value) => {
						window.location.reload();
					});
				}
				else {
					swal("Oops!", "Something went wrong!", "error");
				}
			});
		}
		
	}

	const submitStatus = (unique_id,add_status) => {
		var id = id;
		setstate(2);
		var values = { "id": unique_id,"add_status":add_status,"organization_id":Organization_id,"page_name": page_name };
		setMainSubmitButtonState(true)
		var headers = {
			headers: {
				"Content-Type": "application/json",
				"Accept": "*/*",
				"token": getCookie("access_token")
			}
		}

		axios.post(`${process.env.REACT_APP_SERVER_URL}/update-gst-status`, values, headers).then((res) => {
			setMainSubmitButtonState(false)
			if (res.data && res.data.operation == "success") {
				window.location.reload();
				swal("Great!", "Status changed successfully!", "success").then((value) => {
					window.location.reload();
				});
			}
			else {
				swal("Oops!", "Something went wrong!", "error");
			}
		});
	}

	const remove_Approval = (value,actual_index) => {
		const values1 = [...Approval_info ];
		let arr=values1.filter(obj => obj.key ==value)[0].value;
		arr.splice(actual_index,1);
		setApproval_info(values1);
	}

	const add_Approval = (value) => {
		const values1 = [...Approval_info ];
		let arr=values1.filter(obj => obj.key ==value)[0].value;
		arr.push({level:arr.length+1,user_value:[]});
		setApproval_info(values1);
	}

	const UserValueLoad = (value) => {
		if(value==="Individual User")
		{
			setUserValuelist(Userlist);
		}
		else
		{
			setUserValuelist(RoleList);
		}
	}

	const ValueLoad = (value1,value2) => {
		const values1 = [...Approval_info ];
		console.log(value2)
		if(value2==="multilevel_approval")
		{
			setUserValuelist(Userlist);
			values1.filter(obj => obj.key ==value1)[0].value=[{level:1,user_value:[]}];
		}
		else if(value2==="custom_approval")
		{
			setUserValuelist(Userlist);
			values1.filter(obj => obj.key ==value1)[0].value=[{user_type:"Individual User",user_value:[]}];
		}
		else if(value2==="not_configure")
		{
			values1.filter(obj => obj.key ==value1)[0].value=[];
		}
		setApproval_info(values1);
	}

	const Validate_check = (type,index,temp) => {
		let approval= [...Approval_info];
		let flag=true;
		let arr=approval.filter(obj => obj.key ==type)[0].value;
		for(let i=0;i<arr.length;i++)
		{
			if(arr[i].user_value.filter(obj => obj ==temp[temp.length-1].value).length>0)
			{
				flag=false;
			}
		}
		return flag;
	}

	function capitalizeWords(str) {
	return str
		.toLowerCase()
		.split('_')
		.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
		.join(' ');
	}

	return (
		<>

			{
                (redirect == 1 || (read == 0 && write == 0)) ?
                    <>
                        <Redirect to={{
                            pathname: "/unauthorized",
                        }}
                        />
                    </> :
                    null
            }
			
				<div className="d-flex flex-column flex-column-fluid">
					<div className="app-toolbar py-3 py-lg-6" id="kt_app_toolbar">
						<div id="kt_app_toolbar_container" className="app-container container-xxl d-flex flex-stack">
							<h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">Approval
								
								<small className="text-muted fs-7 fw-bold my-1 ms-1"></small>
							</h1>
							{/*<div class="d-flex align-items-center gap-2 gap-lg-3">
								<a onClick={(e) => { CreateGstDetails();setGSTModalShow(true); }} class="btn btn-sm fw-bold btn-primary">New GSTIN</a>
							</div>*/}
						</div>
					</div>
					<div id="kt_app_content" className="app-content flex-column-fluid">
						<div id="kt_app_content_container" className="app-container container-xxl">
						{Configured.length>0 &&
						<>
							<div class="notice d-flex bg-light-warning rounded border-warning border border-dashed  p-6">
								<span class="svg-icon svg-icon-2tx svg-icon-warning me-4"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
									<rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="currentColor"></rect>
									<rect x="11" y="14" width="7" height="2" rx="1" transform="rotate(-90 11 14)" fill="currentColor"></rect>
									<rect x="11" y="17" width="2" height="2" rx="1" transform="rotate(-90 11 17)" fill="currentColor"></rect>
								</svg>
								</span>
								<div class="d-flex flex-stack flex-grow-1 ">
									<div class=" fw-semibold">
										{/* <h4 class="text-gray-900 fw-bold">No Data Available!</h4> */}

										<div class="fs-6 text-gray-700 ">{Configured.toString()} Part Not Configured yet. Configure this, Click <span class="fw-bold text-primary fs-4">Save Changes button</span>.</div>
									</div>
								</div>
							</div><br/></>}
						{state == 1 ?
						<>
						<div className="card  pt-0 pb-15 px-3 px-xl-10">
                            <br />
                                <div className="card mb-5 mb-xl-10">
                                    <div className="card mt-5 mt-xxl-8">
                                        <div className="card-rounded bg-light d-flex flex-stack flex-wrap p-5 col-12">
											<ul className="nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-semibold mb-n2">
											{Approval_info.map((obj, indx, arr) => {
												return (
													<>
													<li className="nav-item">
														<a className={`nav-link text-active-primary pb-4 ${navtype === obj.key && "active"}`}  onClick={(e) => { setnavtype(obj.key); }}>{capitalizeWords(obj.key)}</a>
													</li>
													</>
                                                )
                                            })}
											</ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="card card-xxl-stretch mb-5 mb-xl-10">
                                    <div className="card-body py-3">
                                        <div className="tab-content">
										{Approval_info.map((obj1, indx, arr) => {
											return (
												<>
												{navtype=== obj1.key &&
												<div className="tab-pane fade show active">
													<div className="card mb-5 mb-xl-10" id="kt_profile_details_view">
														<div className="card-header">
															<div className="card-title m-0">
																<h3 className="fw-bolder m-0">{capitalizeWords(obj1.key)}</h3>
															</div>
															<span className="badge badge-light fw-bolder my-2">
															
															</span>
														</div>
														<div className="card-body p-9">
															<div class="fv-row">
															<label class="d-flex align-items-center fs-5 fw-semibold mb-4">
																<span class="required">{capitalizeWords(obj1.key)} Preferences</span>
																<i class="fas fa-exclamation-circle ms-2 fs-7" data-bs-toggle="tooltip" aria-label="Select your app category" data-bs-original-title="Select your app category" data-kt-initialized="1"></i>
															</label>
															<div class="fv-row fv-plugins-icon-container fv-plugins-bootstrap5-row-valid">
																<label class="d-flex flex-stack mb-5 cursor-pointer">
																	<span class="d-flex align-items-center me-2">
																		<span class="symbol symbol-50px me-6">
																			<span class="symbol-label bg-light-primary">
																				<span class="svg-icon svg-icon-1 svg-icon-primary">
																					<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
																						<path opacity="0.3" d="M18.4 5.59998C21.9 9.09998 21.9 14.8 18.4 18.3C14.9 21.8 9.2 21.8 5.7 18.3L18.4 5.59998Z" fill="currentColor"></path>
																						<path d="M12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2ZM19.9 11H13V8.8999C14.9 8.6999 16.7 8.00005 18.1 6.80005C19.1 8.00005 19.7 9.4 19.9 11ZM11 19.8999C9.7 19.6999 8.39999 19.2 7.39999 18.5C8.49999 17.7 9.7 17.2001 11 17.1001V19.8999ZM5.89999 6.90002C7.39999 8.10002 9.2 8.8 11 9V11.1001H4.10001C4.30001 9.4001 4.89999 8.00002 5.89999 6.90002ZM7.39999 5.5C8.49999 4.7 9.7 4.19998 11 4.09998V7C9.7 6.8 8.39999 6.3 7.39999 5.5ZM13 17.1001C14.3 17.3001 15.6 17.8 16.6 18.5C15.5 19.3 14.3 19.7999 13 19.8999V17.1001ZM13 4.09998C14.3 4.29998 15.6 4.8 16.6 5.5C15.5 6.3 14.3 6.80002 13 6.90002V4.09998ZM4.10001 13H11V15.1001C9.1 15.3001 7.29999 16 5.89999 17.2C4.89999 16 4.30001 14.6 4.10001 13ZM18.1 17.1001C16.6 15.9001 14.8 15.2 13 15V12.8999H19.9C19.7 14.5999 19.1 16.0001 18.1 17.1001Z" fill="currentColor"></path>
																					</svg>
																				</span>
																			</span>
																		</span>
																		<span class="d-flex flex-column">
																			<span class="fw-bold fs-6">Don't configure {capitalizeWords(obj1.key)}</span>
																			<span class="fs-7 text-muted">Creating a clear text structure is just one SEO</span>
																		</span>
																	</span>
																	<span class="form-check form-check-custom form-check-solid">
																		<input class="form-check-input" type="radio"
																		onChange={(event) => { 
																			const values1 = [...Approval_info ];
																			values1.filter(obj => obj.key ==obj1.key)[0].type="not_configure";
																			setApproval_info(values1);
																			ValueLoad(obj1.key,"not_configure");
																		}}
																		checked={Approval_info.filter(obj => obj.key ==obj1.key)[0].type==="not_configure"}/>
																	</span>
																</label>
																<div class="fv-row">
																	<label class="d-flex flex-stack mb-5 cursor-pointer">
																		<span class="d-flex align-items-center me-2">
																			<span class="symbol symbol-50px me-6">
																				<span class="symbol-label bg-light-danger">
																					<span class="svg-icon svg-icon-1 svg-icon-danger">
																						<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
																							<rect x="2" y="2" width="9" height="9" rx="2" fill="currentColor"></rect>
																							<rect opacity="0.3" x="13" y="2" width="9" height="9" rx="2" fill="currentColor"></rect>
																							<rect opacity="0.3" x="13" y="13" width="9" height="9" rx="2" fill="currentColor"></rect>
																							<rect opacity="0.3" x="2" y="13" width="9" height="9" rx="2" fill="currentColor"></rect>
																						</svg>
																					</span>
																				</span>
																			</span>
																			<span class="d-flex flex-column">
																				<span class="fw-bold fs-6">Custom Role or User can approve</span>
																				<span class="fs-7 text-muted">Creating a clear text structure is just one aspect</span>
																			</span>
																		</span>
																		<span class="form-check form-check-custom form-check-solid">
																			<input class="form-check-input" type="radio"
																			onChange={(event) => { 
																				const values1 = [...Approval_info];
																				values1.filter(obj => obj.key ==obj1.key)[0].type="custom_approval";
																				setApproval_info(values1);
																				ValueLoad(obj1.key,"custom_approval");
																			}}
																			checked={Approval_info.filter(obj => obj.key ==obj1.key)[0].type==="custom_approval"}/>
																		</span>
																	</label>
																	</div>
																	<label class="d-flex flex-stack cursor-pointer">
																		<span class="d-flex align-items-center me-2">
																			<span class="symbol symbol-50px me-6">
																				<span class="symbol-label bg-light-success">
																					<span class="svg-icon svg-icon-1 svg-icon-success">
																						<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
																							<path opacity="0.3" d="M20.9 12.9C20.3 12.9 19.9 12.5 19.9 11.9C19.9 11.3 20.3 10.9 20.9 10.9H21.8C21.3 6.2 17.6 2.4 12.9 2V2.9C12.9 3.5 12.5 3.9 11.9 3.9C11.3 3.9 10.9 3.5 10.9 2.9V2C6.19999 2.5 2.4 6.2 2 10.9H2.89999C3.49999 10.9 3.89999 11.3 3.89999 11.9C3.89999 12.5 3.49999 12.9 2.89999 12.9H2C2.5 17.6 6.19999 21.4 10.9 21.8V20.9C10.9 20.3 11.3 19.9 11.9 19.9C12.5 19.9 12.9 20.3 12.9 20.9V21.8C17.6 21.3 21.4 17.6 21.8 12.9H20.9Z" fill="currentColor"></path>
																							<path d="M16.9 10.9H13.6C13.4 10.6 13.2 10.4 12.9 10.2V5.90002C12.9 5.30002 12.5 4.90002 11.9 4.90002C11.3 4.90002 10.9 5.30002 10.9 5.90002V10.2C10.6 10.4 10.4 10.6 10.2 10.9H9.89999C9.29999 10.9 8.89999 11.3 8.89999 11.9C8.89999 12.5 9.29999 12.9 9.89999 12.9H10.2C10.4 13.2 10.6 13.4 10.9 13.6V13.9C10.9 14.5 11.3 14.9 11.9 14.9C12.5 14.9 12.9 14.5 12.9 13.9V13.6C13.2 13.4 13.4 13.2 13.6 12.9H16.9C17.5 12.9 17.9 12.5 17.9 11.9C17.9 11.3 17.5 10.9 16.9 10.9Z" fill="currentColor"></path>
																						</svg>
																					</span>
																					
																				</span>
																			</span>
																			<span class="d-flex flex-column">
																				<span class="fw-bold fs-6">Configure multi-level approval with specific Role or User.</span>
																				<span class="fs-7 text-muted">Creating a clear text structure copywriting</span>
																			</span>
																			
																		</span>
																		<span class="form-check form-check-custom form-check-solid">
																			<input class="form-check-input" type="radio"
																			onChange={(event) => { 
																				const values1 = [...Approval_info];
																				values1.filter(obj => obj.key ==obj1.key)[0].type="multilevel_approval";
																				setApproval_info(values1);
																				ValueLoad(obj1.key,"multilevel_approval");
																			}}
																			checked={Approval_info.filter(obj => obj.key ==obj1.key)[0].type==="multilevel_approval"}/>
																		</span>
																	</label>
																<div class="fv-plugins-message-container invalid-feedback"></div></div>
															</div><br/>
															{Approval_info.filter(obj => obj.key ==obj1.key)[0].type!=="not_configure" &&
															<>
															<label class="d-flex align-items-center fs-5 fw-semibold mb-4">
																<span class="required">Approver</span>
																<i class="fas fa-exclamation-circle ms-2 fs-7" data-bs-toggle="tooltip" aria-label="Select your app category" data-bs-original-title="Select your app category" data-kt-initialized="1"></i>
															</label>
															<div>
																{Approval_info.filter(obj => obj.key ==obj1.key)[0].type=="custom_approval" ?
																<div className="form-group">
																	<div className='d-flex justify-content-center mx-3'>
																		<div className="col-5 me-2">
																			<label className="required form-label fs-6 fw-bolder text-gray-700 mb-3">User Type</label>
																				<div className="mb-5">
																					<Select
																						styles={userTheme=='dark'?dropdownStyleDark:{}}
																						options={UserTypelist}
																						onChange={(event) => { 
																							////UserValueLoad(event.value);
																							const values1 = [...Approval_info];
																							values1.filter(obj => obj.key ==obj1.key)[0].value[0].user_type=event.value;
																							values1.filter(obj => obj.key ==obj1.key)[0].value[0].user_value=[];
																							setApproval_info(values1);
																						}}
																						value={UserTypelist.filter(option => option.value == Approval_info.filter(obj => obj.key ==obj1.key)[0].value[0].user_type).length != 0 && UserTypelist.filter(option => option.value ==Approval_info.filter(obj => obj.key ==obj1.key)[0].value[0].user_type)[0]}
																						placeholder="--Select User Type--"
																					/>
																				</div>
																		</div>
																		<div className="col-5">
																			<label className="required form-label fs-6 fw-bolder text-gray-700 mb-3">Value</label>
																				<div className="mb-5">
																					<Select
																						styles={userTheme=='dark'?dropdownStyleDark:{}}
																						isMulti
																						options={Approval_info.filter(obj => obj.key ==obj1.key)[0].value[0].user_type==="Individual User"?Userlist:RoleList}
																						onChange={(event) => { 
																							const values1 = [...Approval_info];
																							console.log(event.map(a => a.value))
																							values1.filter(obj => obj.key ==obj1.key)[0].value[0].user_value=event.map(a => a.value);
																							setApproval_info(values1);
																						}}
																						value={Approval_info.filter(obj => obj.key ==obj1.key)[0].value[0].user_value.map((e) => { return { label: e, value: e } })}
																						placeholder={`--Select--`}
																					/>
																				</div>
																		</div>
																	</div>	 
																</div>
																	:
																	Approval_info.filter(obj => obj.key ==obj1.key)[0].type=="multilevel_approval" &&
																	<>
																	{Approval_info.filter(obj => obj.key ==obj1.key)[0].value.map((obj2,index1) =>
																		{
																			return(
																				index1<count &&
																				<>
																				<div className="form-group" >
																				<h5 className="m-3">Level {index1+1}</h5>
																				<div className="d-flex justify-content-center mx-3">
																					<div className="col-6 me-2" >
																						<label className="required form-label fs-6 fw-bolder text-gray-700 mb-3">Value</label>
																						<div className="mb-5">
																						<Select
																							styles={userTheme=='dark'?dropdownStyleDark:{}}
																							
																							
																							isMulti
																							options={Userlist}
																							onChange={(event) => { 
																								const values1 = [...Approval_info];
																								if(Validate_check(obj1.key,index1,event))
																								{
																									values1.filter(obj => obj.key ==obj1.key)[0].value[index1].user_value=event.map(a => a.value);
																								}
																								else
																								{
																									swal("Oops!", "User already selected!", "warning")
																								}
																								setApproval_info(values1);
																							}}
																							value={Approval_info.filter(obj => obj.key ==obj1.key)[0].value[index1].user_value.map((e) => { return { label: e, value: e } })}
																							placeholder={`--Select--`}
																						/>
																						</div>
																					</div>
																					<div class="col-md-3 mt-9">
																						<a onClick={(event) => {remove_Approval(obj1.key,index1);}} data-repeater-delete="" class="btn btn-sm font-weight-bolder btn-light-danger">
																						<i class="la la-trash-o"></i>Delete</a>
																					</div>
																					{/*<div className="col-1 mt-9"><button className="btn btn-sm font-weight-bolder btn-light-danger" type="button" onClick={(event) => {remove_Approval(obj1.key,index1);}}><i className="la la-remove"></i></button></div>*/}
																				</div>
																				</div>
																				</>
																		);
																	})}
																	
																	</>
																}
															</div>
															{(Approval_info.filter(obj => obj.key ==obj1.key)[0].type=="multilevel_approval" && Approval_info.filter(obj => obj.key ==obj1.key)[0].value.length<count) &&
																<>
																<div class="col-lg-3"></div>
																<div class="col">
																	<button type="button" className="btn btn-sm font-weight-bolder btn-light-primary" onClick={(event) => { add_Approval(obj1.key);}}><i className="la la-plus"></i>Add</button>
																</div>
																</>
															}
														</>
														}
														</div>
														<div className="card-footer d-flex justify-content-end py-6 px-9">
															<button type="submit" className="btn btn-primary" id="kt_account_profile_details_submit" onClick={(e) => {submitApprovalDetails(e)}} data-kt-indicator={mainSubmitButtonState?"on":"off"} disabled={mainSubmitButtonState}><span className="indicator-label">Save Changes</span>
																<span className="indicator-progress">Please wait...
																	<span className="spinner-border spinner-border-sm align-middle ms-2"></span></span></button>
														</div> 
													</div>
												</div>}
												</>
                                            )
                                         })}
                                        </div>
                                    </div>
                                </div>
                            </div>
						</>
						:
						<div className="card modal-body pt-0 pb-15 px-5 px-xl-20">
								<br />
									{state == 2 ?
										<img src="/images/loader-06.svg" alt="" style={loaderstyle} />
										:
										<>
											<div className="flex-lg-row-fluid me-lg-15">
												<div className="card card-flush pt-3 mb-5 mb-lg-10">

													<div className="card-header">
														<div className="card-title">
															<h2 className="fw-bolder">Approval Details</h2>

														</div>
													</div>
													<div className="card-body pt-0">
														<div className="text-gray-500 fw-bold fs-5 mb-5"></div>
														<div className="d-flex align-items-center p-3 mb-2">
														</div>
														<div className="notice d-flex bg-light-primary rounded border-primary border border-dashed rounded-3 p-6">
															<div className="d-flex flex-stack flex-grow-1">
																<div className="fw-bold">
																	<h4 className="text-gray-800 fw-bolder">No Data Available</h4>
																	<div className="fs-6 text-gray-600">
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</>
									}
								<br />
							</div>
							}
					</div>
				</div>
			</div>

			<Organization_modal OrganizationModalShow={OrganizationModalShow} setOrganizationModalShow1={setOrganizationModalShow} organization_name={organization_name} organization_list={organization_list} organization_id={Organization_id} state_org={state_org} mainSubmitButtonState={mainSubmitButtonState} setMainSubmitButtonState={setMainSubmitButtonState}/>
			<Timer_modal clockTimerModalShow={clockTimerModalShow} setClockTimerModalShow1={setClockTimerModalShow} clock_timer={clock_timer} organization_id={Organization_id} setTimer={setTimer} mainSubmitButtonState={mainSubmitButtonState} setMainSubmitButtonState={setMainSubmitButtonState}/>
		</>);

};

export default Approvals;
