import React, { lazy, Suspense, useState, useEffect, useRef  } from 'react';
import ReactDOM from 'react-dom';

import AsideNavbar from './aside-navbar.js';
import Header from './header.js';
import Footer from './footer.js';

import axios from 'axios';
import uuid from 'react-uuid';

import { Helmet } from "react-helmet";
import { Modal } from 'react-bootstrap';

import { ResponsiveBar } from '@nivo/bar';
import { ResponsivePie } from '@nivo/pie'

import { Link, Redirect } from "react-router-dom";
import moment from 'moment';
import swal from 'sweetalert';
import Select from 'react-select';
import {setCookie,getCookie} from '../common_component/cookie.js';
import Organization_modal from '../common_component/organization_modal.js';
import Timer_modal from '../common_component/timer_modal.js';

function Dashboard() {
	const timer_interval_ref = useRef();
    const [clockTimerModalShow, setClockTimerModalShow] = useState(false);
	const [userTheme,setUserTheme]=useState((localStorage.getItem("kt_metronic_theme_mode_menu")=="system")?window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light":localStorage.getItem("kt_metronic_theme_mode_menu"))
	const [accessToken, setaccessToken] = useState('');
	let [user, setUser] = useState({});
	let [state, setstate] = useState(2);
	let [timer, setTimer] = useState(0);
	let [clock_timer, setClockTimer] = useState(0);
	let [purchase_flag, setpurchase_flag] = useState("");
	let [starting_date, setstarting_date] = useState(moment().format("YYYY-MM-DD HH:mm"));
	let [ending_date, setending_date] = useState("");
	let [verification_date, setverification_date] = useState("");
	let [duration, setduration] = useState("");
	let [Quotalimit_count, setQuotalimit_count] = useState([]);
	let [redirect_to_login, setRedirect_to_login] = useState(2);
	const [visibility, setvisibility] = useState(0);
	let [redirect, setredirect] = useState(0);
	const [verification_flag, setverification_flag] = useState("");
	const [message_flag, setmessage_flag] = useState("");
	const [verification_status, setverification_status] = useState("");
	const [verification_id, setverification_id] = useState("");
	const [organization_id, setOrganization_id] = useState("");
	const [organization_name, setOrganization_name] = useState("");
    let [organization_list, setorganization_list] = useState([]);
	let [state_org, setstate_org] = useState(2);
	const [OrganizationModalShow, setOrganizationModalShow] = useState(false);
	let [read, setread] = useState(2);
	let [write, setwrite] = useState(2);
	const [mainSubmitButtonState, setMainSubmitButtonState] = useState(false);
	let [Organization_admin, setOrganization_admin] = useState("");

	const [CurrencySymbol, setCurrencySymbol] = useState("");
	const [Collection_details, setCollection_details] = useState(null);
	const [TotalCollection_details, setTotalCollection_details] = useState([]);

	const [sendInvitationModalShow, setSendVerificationModalShow] = useState(false);
    const [resendVerificationModalShow, setResendVerificationModalShow] = useState(false);

	let [user_name, setuser_name] = useState("");
    let [user_type, setuser_type] = useState("");
    let [user_id, setuser_id] = useState("");
	
	const [dsoList, setdsoList] = useState([]);
	const [Graph_data1, setGraph_data1] = useState([]);
    const [Graph_data2, setGraph_data2] = useState([]);
	const [Graph_data3, setGraph_data3] = useState([]);
    const [Graph_data4, setGraph_data4] = useState([]);
	const [Colour_details, setColour_details] = useState(["hsl(272, 70%, 50%)","hsl(185, 70%, 50%)","hsl(121, 70%, 50%)"]);

	let data=[
		{
		  "id": "ruby",
		  "label": "ruby",
		  "value": 139,
		  "color": "hsl(306, 70%, 50%)"
		},
		{
		  "id": "hack",
		  "label": "hack",
		  "value": 450,
		  "color": "hsl(67, 70%, 50%)"
		},
		{
		  "id": "haskell",
		  "label": "haskell",
		  "value": 462,
		  "color": "hsl(153, 70%, 50%)"
		},
		{
		  "id": "erlang",
		  "label": "erlang",
		  "value": 185,
		  "color": "hsl(215, 70%, 50%)"
		},
		{
		  "id": "scala",
		  "label": "scala",
		  "value": 1,
		  "color": "hsl(238, 70%, 50%)"
		}
	  ]

	var page_name = "dashboard"
	const loaderstyle = {
		display: "block",
		marginLeft: "auto",
		marginRight: "auto",
		marginTop: "auto",
		marginBottom: "auto",
	};

	let dropdownStyleDark = {
        option: (provided, state) => ({
            ...provided,
            color: '#dddddf',
            backgroundColor: state.isSelected?provided.backgroundColor: state.isFocused ? '#5f5f8d':provided.backgroundColor ,
        }),
        control: (provided, state) => ({
            ...provided,
            backgroundColor: "#2b2b40",
            borderColor:"#4c4c71"
        }),
        placeholder: (provided, state) => ({
            ...provided,
            color: "#dddddf",
        }),
        singleValue: (provided, state) => ({
            ...provided,
            color: "#dddddf",
        }),
        menu: (provided, state) => ({
            ...provided,
            backgroundColor: "#2b2b40",
        })
    }

	useEffect(() => {
        if (getCookie("access_token") != "") {
            if(getCookie("organization_id")!="")
            {
                setOrganization_id(getCookie("organization_id"));  
            }
            else
            {
                var object = {};
                object.page_name=page_name;
                var headers = {
                    headers: {
                        "Content-Type": "application/json",
                        "token": getCookie("access_token"),
                        "Accept": "*/*"
                    }
                }
                axios.post(`${process.env.REACT_APP_SERVER_URL}/show-organization`, object, headers).then((res) => {
					if (res.data.length==1) {
						setCookie('organization_id', res.data[0].organization_id, 1,true);
						setOrganization_id(res.data[0].organization_id)
					}
					else
					{
						setstate_org(1);
						setorganization_list(res.data);
						Organization_modal(true,res.data,getCookie("organization_id"),organization_name,state_org);
						//let x=Organization_modal(true,res.data,getCookie("organization_id"),organization_name,state_org);
						//console.log(x);
						setOrganizationModalShow(true); 
						//window.$("#ModalOrganization").modal("show")
						//setstate1(1)
					}
				})   
            }
        }
        else {
			console.log("abc");
            window.location.href = "/login"
        }
    }, [])

	useEffect(() => {
		setCookie("lastpage", window.location.href, 1,false)
		const validateJWT = () => {

			if (getCookie("access_token") != "") {
				var object = {};
				object.token = getCookie("access_token");

				var headers = {
					headers: {
						"Content-Type": "application/json",
						"Accept": "*/*"
					}
				}
				axios.post(`${process.env.REACT_APP_SERVER_URL}/get-user`, object, headers).then((res) => {

					if (res.data.operation == "success") {
						var user_object = {};
						user_object.email = res.data.email;
						user_object.name = res.data.name;
						user_object.img = res.data.img;
						user_object.phone = res.data.phone;
						setUser(user_object)

						// const script = document.createElement('script');
						// script.type = "text/javascript";
						// script.src = "https://s3.tradingview.com/external-embedding/embed-widget-screener.js";
						// script.async = true;
						// script.text = '{\
						// 				  "width": "100%",\
						// 				  "height": "100%",\
						// 				  "defaultColumn": "overview",\
						// 				  "screener_type": "crypto_mkt",\
						// 				  "displayCurrency": "USDT",\
						// 				  "colorTheme": "light",\
						// 				  "locale": "en",\
						// 				  "isTransparent": false\
						// 				}';
						// document.getElementById("dashboard_script").appendChild(script);

					}
					else {
						/*
						swal("Oops!", "Please login", "error").then((value) => {
							window.location.href = "/login"
						});
						*/
						setRedirect_to_login(1)
					}
				})
				.catch(function (error) {
					/*
					swal("Oops!", "Please login", "error").then((value) => {
						window.location.href = "/login"
					});
					*/
					console.log(error)
					setRedirect_to_login(1)
				});
				object.organization_id=organization_id;
				axios.post(`${process.env.REACT_APP_SERVER_URL}/audience-info`, object, headers).then((res) => {

					if (res.data.operation == "success") {
						var token = res.data.audience_license;
						if (res.data.organization_flag == 0) {
							setstate_org(3);
							setOrganization_name(res.data.organization_name);
							/*setOrganizationDetails(res.data.organization_list);
							let arr=[];
							for (var i = 0 ; i<res.data.organization_list.length ; i++ ) {
								arr.push({label:res.data.organization_list[i].organization_name,value:res.data.organization_list[i].organization_id});
							}
							setorganization_list(arr);*/
							setOrganizationModalShow(true); 
							//window.$("#ModalOrganization").modal("show")
							/*swal("Please login!", "Your account doesn't belong to any organization.", "warning").then((value) => {
								window.location.href = "/login"
							});*/
						}
						else {
							setuser_name(res.data.user_type.name);
                            setuser_type(res.data.user_type.type);
                            setuser_id(res.data.user_type.id);
							/*var path = window.location.pathname;
							path = path.replace(/\/$/, "");
							path = decodeURIComponent(path);
							//var original_url = path.split("/")[1]*/
							var original_url ="dashboard"
							let index = 0;
							let temp = -1;
							for (let i = 0; i < res.data.user_access.access.length; i++)
							{
								if (temp == 1) break;
								let component = res.data.user_access.access[i].inside_components;
								for (let j = 0; j < component.length; j++) {
									if (component[j].hasOwnProperty(original_url)) {
										index = i;
										if (res.data.user_access.access[index].inside_components[j][original_url] == 1 && res.data.user_access.access[index].visibility == 1) {
											temp = 1;
											setvisibility(1);
											setread(res.data.user_access.access[index].inside_components[j]["read"])
											setwrite(res.data.user_access.access[index].inside_components[j]["write"])
											// setwrite(0)
											break;
										}
										else {
											setredirect(1);
										}
									}
									else if(component[j].hasOwnProperty("key")){
                                        let sub_inside_components= component[j].inside_components
                                        for (let k = 0; k < sub_inside_components.length; k++) {
                                            if(sub_inside_components[k].hasOwnProperty(original_url)){
                                                index=i
                                                if (sub_inside_components[k][original_url] == 1 && res.data.user_access.access[index].visibility == 1 && component[j].visibility == 1) {
                                                    temp = 1;
                                                    setvisibility(1);
                                                    setread(sub_inside_components[k]["read"])
                                                    setwrite(sub_inside_components[k]["write"])
                                                    // setwrite(0)
                                                    break;
                                                    
                                                }
                                                else {
                                                    setredirect(1);
                                                }
                                            }
                                        }
                                    }
									else
									{
										temp = 2;
									}
								}
							}
							if(temp == 2)
                            {
								//alert("403");
                                setredirect(1);
                            }
						}
						if (!token.includes("ufhdsupw29fher") || !token.includes("pdbwu27dh6schs") || !token.includes("0dh27agiqbcrwi")) {
							setCookie('access_token', res.data.audience_license, 1,true)
							setTimer(process.env.REACT_APP_JWT_EXPIRY);
							//setClockTimer(process.env.REACT_APP_JWT_EXPIRY);
						}
						else {
							setTimer(res.data.audience_count)
							//setClockTimer(res.data.audience_count)
						}
					}
					else {
						/*swal("Oops!",`${res.data.message}`, "error").then((value) => {
							window.location.href = "/login"
						});*/
						setRedirect_to_login(1);
					}
				})
					.catch(function (error) {
						/*
						swal("Oops!", "Please login", "error").then((value) => {
							window.location.href = "/login"
						});
						*/
						setRedirect_to_login(1)
					});
			}
			else {
				/*
				swal("Oops!", "Please login", "error").then((value) => {
					window.location.href = "/login"
				});
				*/
				setRedirect_to_login(1)
			}
		}

		organization_id!="" && validateJWT();

	}, [organization_id]);

	useEffect(() => {
        timer_interval_ref.current = setInterval(() => {
            setTimer((timer)=>{
                if (timer > 0 && timer < process.env.REACT_APP_JWT_EXPIRY_NOTICE) {
                    if (!clockTimerModalShow) {
                        setClockTimer(process.env.REACT_APP_JWT_EXPIRY_NOTICE)
                        setClockTimerModalShow(true)
                    }
                }
                return timer - 1
            })
        }, 1000);
        
        return () => {clearInterval(timer_interval_ref)};
    }, []);

	useEffect(() => {
		async function getbasecurrency() {
			var object = {};
			object.email = user.email;
	
			const response = await fetch(
				`${process.env.REACT_APP_SERVER_URL}/get-base-currency`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
			);
			const body = await response.json();
				//console.log(body)
			if (body.length > 0) {
				setCurrencySymbol(body[0].currency_symbol);
				//Reset_Denomination(body[0].currency_code, "Set");
			}
		}
		if (read || write) {
			(user.email && visibility) && getbasecurrency()
		}
		async function getpurchaseflagdetails() {
			var object = {};
			object.email = user.email;

			const response = await fetch(
				`${process.env.REACT_APP_SERVER_URL}/get-trial-plan-details`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name,"organization_id":organization_id}), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
			);
			const body = await response.json();
			if(body.length>0)
			{
				//console.log(moment(body[0].ending_date, "YYYY-MM-DD HH:mm"));
				setending_date(moment(body[0].ending_date, "YYYY-MM-DD HH:mm"))
				//let end=moment(body[0].ending_date, "YYYY-MM-DD HH:mm");
				//console.log(Math.floor(moment.duration(end.diff(starting_date)).asDays()));
				setpurchase_flag(body);
			}
		}
		if (read || write) {
			(user.email && visibility) && getpurchaseflagdetails()
		}

		async function getOrganizationAdmin() {
			var object = {};
			object.email = user.email;

			const response = await fetch(
				`${process.env.REACT_APP_SERVER_URL}/get-Organization-Admin`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name,"organization_id":organization_id}), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
			);
			const body = await response.json();
			if(body.data.length>0)
			{
				//console.log(body.data[0].user_id);
				setOrganization_admin(body.data[0].user_id);
			}
		}
		if (read || write) {
			(user.email && visibility) && getOrganizationAdmin()
		}
		async function getverificationstatus() {
			var object = {};
			object.email = user.email;

			const response = await fetch(
				`${process.env.REACT_APP_SERVER_URL}/get-verification-status`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name,"organization_id":organization_id}), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
			);
			const body = await response.json();
			if(body.length>0)
			{
				//console.log(body);
				setverification_status(body[0].verification_status);
				setverification_flag(body[0].verification_flag);
				setmessage_flag(body[0].message_flag);
				setverification_id(body[0].unique_id);
				//console.log(body[0].log_date_time);
				if(body[0].mail_date_time!=undefined)
				{
					setverification_date(moment(body[0].mail_date_time).format("YYYY-MM-DD HH:mm"));
					console.log(-moment.duration(moment(body[0].mail_date_time).diff(starting_date)).asHours());
					setduration(-moment.duration(moment(body[0].mail_date_time).diff(starting_date)).asHours());
				}
				// console.log(moment(body[0].mail_date_time).format("YYYY-MM-DD HH:mm"));
				// console.log(starting_date);
				// console.log(moment.duration(moment(body[0].mail_date_time).diff(starting_date)).asHours());
				// console.log(moment.duration(moment(body[0].mail_date_time).format("YYYY-MM-DD HH:mm").diff(starting_date)).asHours())
				//console.log(moment.duration(moment(body[0].mail_date_time).format("YYYY-MM-DD HH:mm").diff(starting_date)).asHours())
			}
		}
		if (read || write) {
			(user.email && visibility) && getverificationstatus()
		}

		async function getCollectionEntry() {
			//let start=moment.utc().subtract(1, "days").format("YYYY-MM-DD");
			//let end=moment.utc().subtract(1, "days").format("YYYY-MM-DD");

			let start=moment.utc().format("YYYY-MM-DD");
			let end=moment.utc().format("YYYY-MM-DD");

			const response = await fetch(
				`${process.env.REACT_APP_SERVER_URL}/get-dealer-collection-summary`, { method: 'POST', body: JSON.stringify({ "email": user.email,"user_id":user_id,"page_name": page_name,"flag":"collection_summary","start_date":start,"end_date":end,"organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
			);
			const body = await response.json();
			if (body.operation == "success") {
				let currency=[];
				// let brand=[...BrandList];
				// let dealer=[...dealerList];
				let dso=[...dsoList];
				// //let date=[];
				// let arr=[];
				if(body.data.length>0)
				{
					for(let i=0;i<body.data.length;i++)
					{ 
					// 	if(dealer.find(x=>x.value==body.data[i].dealer_id))
					// 	{
					// 		let p=dealer.find(x=>x.value==body.data[i].dealer_id);
					// 		if(!p.dso.find(x=>x.value==body.data[i].dso_id))
					// 		{
					// 			p.dso.push({label:body.data[i].dso_name,value:body.data[i].dso_id});
					// 		}
					// 		body.data[i].brand.map((obj,index) => {
					// 			if(!p.brand.find(x=>x.value==obj.value))
					// 			{
					// 			   p.brand.push(obj);
					// 			}
					// 		})
					// 	}
					// 	else
					// 	{
					// 		dealer.push({value:body.data[i].dealer_id,label:body.data[i].dealer_display_name,brand:body.data[i].brand,dso:[{label:body.data[i].dso_name,value:body.data[i].dso_id}]});
					// 	}
						if(dso.find(x=>x.value==body.data[i].dso_id))
						{
							let p=dso.find(x=>x.value==body.data[i].dso_id);
							if(!p.dealer.find(x=>x.value==body.data[i].dealer_id))
							{
								p.dealer.push({label:body.data[i].dealer_display_name,value:body.data[i].dealer_id});
							}
						}
						else
						{
							dso.push({value:body.data[i].dso_id,label:body.data[i].dso_name,dealer:[{label:body.data[i].dealer_display_name,value:body.data[i].dealer_id}]});
						}
		
						if(!currency.find(x=>x.value==body.data[i].currency.Currency_code))
						{
							currency.push({value:body.data[i].currency.Currency_code,label:body.data[i].currency.Currency_code,Currency_symbol:body.data[i].currency.Currency_symbol});
						}
		
					// 	body.data[i].brand.map((obj,index) => {
					// 		if(!brand.find(x=>x.value==obj.value))
					// 		{
					// 		   brand.push({label:obj.label,value:obj.value});
					// 		}
					// 	})
					}
					//var check_first = First_graph_set_up(body.data);
					var check_second = Second_graph_set_up(body.data);
					var check_third =Third_graph_set_up(body.data);
					var check_fourth =Fourth_graph_set_up(body.data);
					setdsoList(dso);
					setCurrencySymbol(currency[0].Currency_symbol);
					setTotalCollection_details(body.data);   
				}
			}
			else {
				throw "transaction series api list error"
			}
		}
		if (read || write) 
		{
			(user.email && visibility) && getCollectionEntry()
		}

	}, [user, visibility, read, write]);

	useEffect(() => {
		if(TotalCollection_details.length>0)
		{ 
			let arr=[...TotalCollection_details];
			//arr = arr.filter(option => option.dealer_id===selectedDealer.value && option.dso_id===SelectedDso.value && option.currency.Currency_symbol===selectedCurrency.value);
			let obj={};
			obj.cheque_amount=0;
			obj.payment_cheque=[];
			obj.cash_amount=0;
			obj.payment_cash=[];
			obj.bank_amount=0;
			obj.payment_bank=[];
			obj.pdc_amount=0;
			obj.payment_pdc=[];
			///All deler All record
			for(let i=0;i<arr.length;i++)
			{ 
				let temp=arr[i].payment_details.map(a=>{return {...a}});
				let temp1=[];
				if(arr[i].collection_entry_flag==="dealer-collection-entry")
				{
					if(temp.filter(option => option.payment_method==="Cheque")[0].payment_info.length>0)
					{
						let payment_info=temp.filter(option => option.payment_method==="Cheque")[0].payment_info;
						obj.cheque_amount+=payment_info.reduce((p, o) => { return p + (Math.round((o.amount) * 100) / 100) }, 0)
						obj.payment_cheque.push(arr[i]);
						temp1.push("Cheque");
					}
					if(temp.filter(option => option.payment_method==="Cash")[0].payment_info.length>0)
					{
						let payment_info=temp.filter(option => option.payment_method==="Cash")[0].payment_info;
						obj.cash_amount+=payment_info.reduce((p, o) => { return p + (Math.round(Number(o.amount) * 100) / 100) }, 0)
						obj.payment_cash.push(arr[i]);
						temp1.push("Cash");
					}
					if(temp.filter(option => option.payment_method==="Bank Transaction")[0].payment_info.length>0)
					{
						let payment_info=temp.filter(option => option.payment_method==="Bank Transaction")[0].payment_info;
						obj.bank_amount+=payment_info.reduce((p, o) => { return p + (Math.round((o.amount) * 100) / 100) }, 0)
						obj.payment_bank.push(arr[i]);
						temp1.push("Bank Transaction");
					}
				}
				else
				{
					if(temp.filter(option => option.payment_method==="Cheque")[0].payment_info.length>0)
					{
						let payment_info=temp.filter(option => option.payment_method==="Cheque")[0].payment_info;
						obj.pdc_amount+=payment_info.reduce((p, o) => { return p + (Math.round((o.amount) * 100) / 100) }, 0)
						obj.payment_pdc.push(arr[i]);
						temp1.push("Cheque");
					}
				}
				arr[i].payment_mode=temp1.toString();
			}
			obj.total_amount= obj.cheque_amount+ obj.cash_amount+ obj.bank_amount+ obj.pdc_amount;
			//console.log("744",obj);
			var check_first = First_graph_set_up(obj);
			setCollection_details(obj);
		}
		else
		{
			setCollection_details(null);
		}
	}, [TotalCollection_details]);

	const First_graph_set_up = (data) => {
		console.log(data);
		let arr=[];
		if(data.cash_amount!==0)
		{ 
			let obj={};
			obj.id= "Cash";
			obj.value=data.cash_amount;
			obj.cashcolor= "hsl(306, 70%, 50%)";
			arr.push(obj);
		}
		if(data.cheque_amount!==0)
		{
			let obj={};
			obj.id= "Cheque";
			obj.value=data.cheque_amount;
			obj.cashcolor= "hsl(67, 70%, 50%)";
			arr.push(obj);
		}
		if(data.bank_amount!==0)
		{
			let obj={};
			obj.id= "Bank Transaction";
			obj.value=data.bank_amount;
			obj.cashcolor= "hsl(153, 70%, 50%)";
			arr.push(obj);
		}
		if(data.pdc_amount!==0)
		{
			let obj={};
			obj.id= "PDC";
			obj.value=data.pdc_amount;
			obj.cashcolor= "hsl(344, 70%, 50%)";
			arr.push(obj);
		}
		console.log("arr",arr);
		setGraph_data1(arr);
        // let arr=[];
        // for(let i=0;i<data.length;i++)
        // {                
        //         for(let j=0;j<data[i].payment_details.length;j++)
        //         {   
        //             let payment_info=data[i].payment_details[j].payment_info;
		// 			let payment_mode=data[i].payment_details[j].payment_method;
		// 			if(data[i].collection_entry_flag=="dealer-pdc-collection-entry")
		// 			{
        //             	payment_mode="PDC";
		// 			}
        //             let amount=payment_info.reduce((p, o) => { return p + (Math.round(Number(o.amount) * 100) / 100) }, 0);
		// 			console.log(arr);
		// 			if(!arr.find(x=>x.id==payment_mode))
        //     		{
		// 				if(data[i].collection_entry_flag=="dealer-collection-entry")
		// 				{
		// 					if(payment_mode=="Cash")
		// 					{ 
		// 						let obj={};
		// 						obj.id= "Cash";
		// 						//obj.id= "Cash";
		// 						obj.value= amount;
		// 						obj.cashcolor= "hsl(306, 70%, 50%)";
		// 						arr.push(obj);
		// 					}
		// 					if(payment_mode=="Cheque")
		// 					{
		// 						let obj={};
		// 						obj.id= "Cheque";
		// 						//obj.id= "Cheque";
		// 						obj.value= amount;
		// 						obj.cashcolor= "hsl(67, 70%, 50%)";
		// 						arr.push(obj);
		// 					}
		// 					if(payment_mode=="Bank Transaction")
		// 					{
		// 						let obj={};
		// 						obj.id= "Bank Transaction";
		// 						//obj.id= "Bank Transaction";
		// 						obj.value= amount;
		// 						obj.cashcolor= "hsl(153, 70%, 50%)";
		// 						arr.push(obj);
		// 					}
		// 				}
		// 				else
		// 				{
		// 					console.log(arr);
		// 					if(payment_mode=="Cheque")
		// 					{
		// 						let obj={};
		// 						obj.id= "PDC";
		// 						//obj.id= "PDC";
		// 						obj.value= amount;
		// 						obj.cashcolor= "hsl(344, 70%, 50%)";
		// 						arr.push(obj);
		// 					}
		// 				}
		// 			}
		// 			else
		// 			{
		// 				console.log(data[i].collection_entry_flag);
		// 				let p=arr.find(x=>x.id==payment_mode);
		// 				p.value= p.value+amount;
		// 			}
        //         } 
		// 	}
        // setGraph_data1(arr);	
    }

	const Second_graph_set_up = (data) => {
        let arr=[];
        for(let i=0;i<data.length;i++)
        {
            let date="";
            if(arr.find(x=>x.dso_id==data[i].dso_id))
            {
                let p=arr.find(x=>x.dso_id==data[i].dso_id)
                for(let j=0;j<data[i].payment_details.length;j++)
                {   
                    let payment_info=data[i].payment_details[j].payment_info;
                    let payment_mode=data[i].payment_details[j].payment_method;
                    let amount=payment_info.reduce((p, o) => { return p + (Math.round(Number(o.amount) * 100) / 100) }, 0);
                    if(data[i].collection_entry_flag=="dealer-collection-entry")
                    {
                        if(payment_mode=="Cash")
                        { 
                            p.Cash= p.Cash+amount;
                        }
                        if(payment_mode=="Cheque")
                        {
                            p.Cheque= p.Cheque+amount;
                        }
                        if(payment_mode=="Bank Transaction")
                        {
                            p.Bank= p.Bank+amount;
                        }
                    }
                    else
                    {
                        if(payment_mode=="Cheque")
                        {
                            console.log(p.PDC);
                            console.log(amount);
                            p.PDC= p.PDC+amount;
                        } 
                    }
                }
            }
            else
            {
                let obj={};
                obj.dso_name=data[i].dso_name.length > 12 ? 
				data[i].dso_name.substring(0,12) + "..." : 
				data[i].dso_name;
				obj.dso_id=data[i].dso_id;
                obj.Cash=0;
                obj.Cheque=0;
                obj.Bank=0;
                obj.PDC=0;

                for(let j=0;j<data[i].payment_details.length;j++)
                {   
                    let payment_info=data[i].payment_details[j].payment_info;
                    let payment_mode=data[i].payment_details[j].payment_method;
                    let amount=payment_info.reduce((p, o) => { return p + (Math.round(Number(o.amount) * 100) / 100) }, 0);
                    if(data[i].collection_entry_flag=="dealer-collection-entry")
                    {
                        if(payment_mode=="Cash")
                        { 
                            obj.Cash= amount;
                            obj.cashcolor= "hsl(154, 70%, 50%)";
                        }
                        if(payment_mode=="Cheque")
                        {
                            obj.Cheque= amount;
                            obj.Chequecolor= "hsl(224, 70%, 50%)";
                        }
                        if(payment_mode=="Bank Transaction")
                        {
                            obj.Bank= amount;
                            obj.Bankcolor= "hsl(302, 70%, 50%)"; 
                        }
                    }
                    else
                    {
                        if(payment_mode=="Cheque")
                        {
                            obj.PDC= amount;
                            obj.PDCcolor= "hsl(334, 70%, 50%)";
                        }
                    }
                }
				console.log(arr);
                arr.push(obj)
            }
        }
        setGraph_data2(arr);
    }

	const Third_graph_set_up = (data) => {
        let arr=[];
        for(let i=0;i<data.length;i++)
        {
            let date="";
			console.log(data);
            if(arr.find(x=>x.dealer_id==data[i].dealer_id))
            {
                let p=arr.find(x=>x.dealer_id==data[i].dealer_id)
                for(let j=0;j<data[i].payment_details.length;j++)
                {   
                    let payment_info=data[i].payment_details[j].payment_info;
                    let payment_mode=data[i].payment_details[j].payment_method;
                    let amount=payment_info.reduce((p, o) => { return p + (Math.round(Number(o.amount) * 100) / 100) }, 0);
                    if(data[i].collection_entry_flag=="dealer-collection-entry")
                    {
                        if(payment_mode=="Cash")
                        { 
                            p.Cash= p.Cash+amount;
                        }
                        if(payment_mode=="Cheque")
                        {
                            p.Cheque= p.Cheque+amount;
                        }
                        if(payment_mode=="Bank Transaction")
                        {
                            p.Bank= p.Bank+amount;
                        }
                    }
                    else
                    {
                        if(payment_mode=="Cheque")
                        {
                            console.log(p.PDC);
                            console.log(amount);
                            p.PDC= p.PDC+amount;
                        } 
                    }
                }
            }
            else
            {
                let obj={};
                obj.dealer_name=data[i].dealer_display_name.length > 12 ? 
				data[i].dealer_display_name.substring(0,12) + "..." : 
				data[i].dealer_display_name;
				obj.dealer_id=data[i].dealer_id;
                obj.Cash=0;
                obj.Cheque=0;
                obj.Bank=0;
                obj.PDC=0;

                for(let j=0;j<data[i].payment_details.length;j++)
                {   
                    let payment_info=data[i].payment_details[j].payment_info;
                    let payment_mode=data[i].payment_details[j].payment_method;
                    let amount=payment_info.reduce((p, o) => { return p + (Math.round(Number(o.amount) * 100) / 100) }, 0);
                    if(data[i].collection_entry_flag=="dealer-collection-entry")
                    {
                        if(payment_mode=="Cash")
                        { 
                            obj.Cash= amount;
                            obj.cashcolor= "hsl(154, 70%, 50%)";
                        }
                        if(payment_mode=="Cheque")
                        {
                            obj.Cheque= amount;
                            obj.Chequecolor= "hsl(224, 70%, 50%)";
                        }
                        if(payment_mode=="Bank Transaction")
                        {
                            obj.Bank= amount;
                            obj.Bankcolor= "hsl(302, 70%, 50%)"; 
                        }
                    }
                    else
                    {
                        if(payment_mode=="Cheque")
                        {
                            obj.PDC= amount;
                            obj.PDCcolor= "hsl(334, 70%, 50%)";
                        }
                    }
                }
				//console.log(arr);
                arr.push(obj)
            }
        }
		//console.log(arr);
        setGraph_data3(arr);
    }

	const Fourth_graph_set_up = (data) => {
        let arr=[];
        for(let i=0;i<data.length;i++)
        {
            let date="";
			for(let j=0;j<data[i].payment_details.length;j++)
			{   
				let payment_info=data[i].payment_details[j].payment_info;
				let payment_mode=data[i].payment_details[j].payment_method;
				for(let k=0; k<payment_info.length; k++)
				{
					if(arr.find(x=>x.brand_id==payment_info[k].brand_id))
					{
						let p=arr.find(x=>x.brand_id==payment_info[k].brand_id)
						let amount=payment_info.reduce((p, o) => { return p + (Math.round(Number(o.amount) * 100) / 100) }, 0);
						if(data[i].collection_entry_flag=="dealer-collection-entry")
						{
							if(payment_mode=="Cash")
							{ 
								p.Cash= p.Cash+amount;
							}
							if(payment_mode=="Cheque")
							{
								p.Cheque= p.Cheque+amount;
							}
							if(payment_mode=="Bank Transaction")
							{
								p.Bank= p.Bank+amount;
							}
						}
						else
						{
							if(payment_mode=="Cheque")
							{
								p.PDC= p.PDC+amount;
							} 
						}
					}
					else
					{
						let obj={};
						let brand_name=data[i].brand_list.filter(option => option.brand_id ==payment_info[k].brand_id)[0].brand_name;
						obj.brand_name=brand_name.length > 12 ? 
						brand_name.substring(0,12) + "..." : 
						brand_name;
						obj.brand_id=payment_info[k].brand_id;
						obj.Cash=0;
						obj.Cheque=0;
						obj.Bank=0;
						obj.PDC=0;

						let amount=payment_info.reduce((p, o) => { return p + (Math.round(Number(o.amount) * 100) / 100) }, 0);
						if(data[i].collection_entry_flag=="dealer-collection-entry")
						{
							if(payment_mode=="Cash")
							{ 
								obj.Cash= amount;
								obj.cashcolor= "hsl(154, 70%, 50%)";
							}
							if(payment_mode=="Cheque")
							{
								obj.Cheque= amount;
								obj.Chequecolor= "hsl(224, 70%, 50%)";
							}
							if(payment_mode=="Bank Transaction")
							{
								obj.Bank= amount;
								obj.Bankcolor= "hsl(302, 70%, 50%)"; 
							}
						}
						else
						{
							if(payment_mode=="Cheque")
							{
								obj.PDC= amount;
								obj.PDCcolor= "hsl(334, 70%, 50%)";
							}
						}
						//console.log(arr);
						arr.push(obj)
					}
				}
			}  
        }
		//console.log(arr);
        setGraph_data4(arr);
    }

	const sendverificationmail = (id) => {
		console.log(id)
        let values = {"id": id, "page_name": page_name,"organization_id":organization_id};

        setMainSubmitButtonState(true)

        var headers = {
            headers: {
                "Content-Type": "application/json",
                "Accept": "*/*",
                "token": getCookie("access_token")
            }
        }

        axios.post(`${process.env.REACT_APP_SERVER_URL}/send-verification-mail`, values, headers).then((res) => {
            setMainSubmitButtonState(false)
            if (res.data && res.data.operation == "success") {
                swal("Great!", "Mail sent successfully!", "success")
				setSendVerificationModalShow(false);
            }
            else {
                swal("Oops!", "Something went wrong!", "error");
            }
        });
    }

	const Resendverificationmail = (id) => {
		console.log(id)
        let values = {"id": id, "page_name": page_name,"organization_id":organization_id};

        setMainSubmitButtonState(true)

        var headers = {
            headers: {
                "Content-Type": "application/json",
                "Accept": "*/*",
                "token": getCookie("access_token")
            }
        }

        axios.post(`${process.env.REACT_APP_SERVER_URL}/resend-verification-email`, values, headers).then((res) => {
            setMainSubmitButtonState(false)
            if (res.data && res.data.operation == "success") {
                swal("Great!", "Mail sent successfully!", "success")
				setResendVerificationModalShow(false);
            }
            else {
                swal("Oops!", "Something went wrong!", "error");
            }
        });
    }


	return (
		<>
			{
				(redirect == 1 || (read == 0 && write == 0)) ?
					<>
						<Redirect to={{
							pathname: "/unauthorized",
						}}
						/>
					</> :
					null
			}
			{
				redirect_to_login == 0 ? null : (redirect_to_login == 1 ? <>
					<Redirect to={{ pathname: "/login" }} />
				</> : null)
			}

			
					<div className="d-flex flex-column flex-column-fluid">
						<div className="app-toolbar py-3 py-lg-6" id="kt_app_toolbar">
							<div id="kt_app_toolbar_container" className="app-container container-xxl d-flex flex-stack">
								<h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">Dashboard
									<span className="h-20px border-gray-200 border-start ms-3 mx-2"></span>
									<small className="text-muted fs-7 fw-bold my-1 ms-1"></small>
								</h1>
							</div>
						</div>
						<div id="kt_app_content" className="app-content flex-column-fluid">
							<div id="kt_app_content_container" className="app-container container-xxl">
							{user.email ?
							<>
								{
									purchase_flag != "" ?
									<>
									{ Math.floor(moment.duration(ending_date.diff(starting_date)).asDays())>0 ?
									<div className="notice d-flex bg-light-primary rounded border-primary border border-dashed p-6 mb-6">
										<span className="svg-icon svg-icon-2tx svg-icon-primary me-4">
											<svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
												<path d="M18.5,8 C17.1192881,8 16,6.88071187 16,5.5 C16,4.11928813 17.1192881,3 18.5,3 C19.8807119,3 21,4.11928813 21,5.5 C21,6.88071187 19.8807119,8 18.5,8 Z M18.5,21 C17.1192881,21 16,19.8807119 16,18.5 C16,17.1192881 17.1192881,16 18.5,16 C19.8807119,16 21,17.1192881 21,18.5 C21,19.8807119 19.8807119,21 18.5,21 Z M5.5,21 C4.11928813,21 3,19.8807119 3,18.5 C3,17.1192881 4.11928813,16 5.5,16 C6.88071187,16 8,17.1192881 8,18.5 C8,19.8807119 6.88071187,21 5.5,21 Z" fill="#000000" opacity="0.3"></path>
												<path d="M5.5,8 C4.11928813,8 3,6.88071187 3,5.5 C3,4.11928813 4.11928813,3 5.5,3 C6.88071187,3 8,4.11928813 8,5.5 C8,6.88071187 6.88071187,8 5.5,8 Z M11,4 L13,4 C13.5522847,4 14,4.44771525 14,5 C14,5.55228475 13.5522847,6 13,6 L11,6 C10.4477153,6 10,5.55228475 10,5 C10,4.44771525 10.4477153,4 11,4 Z M11,18 L13,18 C13.5522847,18 14,18.4477153 14,19 C14,19.5522847 13.5522847,20 13,20 L11,20 C10.4477153,20 10,19.5522847 10,19 C10,18.4477153 10.4477153,18 11,18 Z M5,10 C5.55228475,10 6,10.4477153 6,11 L6,13 C6,13.5522847 5.55228475,14 5,14 C4.44771525,14 4,13.5522847 4,13 L4,11 C4,10.4477153 4.44771525,10 5,10 Z M19,10 C19.5522847,10 20,10.4477153 20,11 L20,13 C20,13.5522847 19.5522847,14 19,14 C18.4477153,14 18,13.5522847 18,13 L18,11 C18,10.4477153 18.4477153,10 19,10 Z" fill="#000000"></path>
											</svg>
										</span>
										<div className="d-flex flex-stack flex-grow-1 flex-wrap flex-md-nowrap">
											<div className="mb-3 mb-md-0 fw-bold">
												<h4 className="text-gray-800 fw-bolder">Activate Subscription Plan.</h4>
												{Math.floor(moment.duration(ending_date.diff(starting_date)).asDays())>1 ? 
												<div className="fs-6 text-gray-600 pe-7">Trial plan expires in {Math.floor(moment.duration(ending_date.diff(starting_date)).asDays())} days.</div>
												:
												0<Math.floor(moment.duration(ending_date.diff(starting_date)).asDays())<1 &&
												<div className="fs-6 text-gray-600 pe-7">Trial plan expires in less than 1 days.</div>
												}
											</div>
											{
												(write && user.email==Organization_admin)  ?
													<a href={`${process.env.REACT_APP_PAYMENT_SERVER_URL}/purchase/bdua1638osba09376b8b8`} className="btn btn-primary px-6 align-self-center text-nowrap rounded">Buy plan</a>
													:
													<button className="btn btn-primary px-6 align-self-center text-nowrap rounded" disabled>Buy plan</button>
											}
										</div>
									</div>
									:
									<div className="notice d-flex bg-light-danger rounded border-danger border border-dashed p-6 mb-6">
										<span className="svg-icon svg-icon-2tx svg-icon-danger me-4">
											<svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
												<path d="M18.5,8 C17.1192881,8 16,6.88071187 16,5.5 C16,4.11928813 17.1192881,3 18.5,3 C19.8807119,3 21,4.11928813 21,5.5 C21,6.88071187 19.8807119,8 18.5,8 Z M18.5,21 C17.1192881,21 16,19.8807119 16,18.5 C16,17.1192881 17.1192881,16 18.5,16 C19.8807119,16 21,17.1192881 21,18.5 C21,19.8807119 19.8807119,21 18.5,21 Z M5.5,21 C4.11928813,21 3,19.8807119 3,18.5 C3,17.1192881 4.11928813,16 5.5,16 C6.88071187,16 8,17.1192881 8,18.5 C8,19.8807119 6.88071187,21 5.5,21 Z" fill="#000000" opacity="0.3"></path>
												<path d="M5.5,8 C4.11928813,8 3,6.88071187 3,5.5 C3,4.11928813 4.11928813,3 5.5,3 C6.88071187,3 8,4.11928813 8,5.5 C8,6.88071187 6.88071187,8 5.5,8 Z M11,4 L13,4 C13.5522847,4 14,4.44771525 14,5 C14,5.55228475 13.5522847,6 13,6 L11,6 C10.4477153,6 10,5.55228475 10,5 C10,4.44771525 10.4477153,4 11,4 Z M11,18 L13,18 C13.5522847,18 14,18.4477153 14,19 C14,19.5522847 13.5522847,20 13,20 L11,20 C10.4477153,20 10,19.5522847 10,19 C10,18.4477153 10.4477153,18 11,18 Z M5,10 C5.55228475,10 6,10.4477153 6,11 L6,13 C6,13.5522847 5.55228475,14 5,14 C4.44771525,14 4,13.5522847 4,13 L4,11 C4,10.4477153 4.44771525,10 5,10 Z M19,10 C19.5522847,10 20,10.4477153 20,11 L20,13 C20,13.5522847 19.5522847,14 19,14 C18.4477153,14 18,13.5522847 18,13 L18,11 C18,10.4477153 18.4477153,10 19,10 Z" fill="#000000"></path>
											</svg>
										</span>
										<div className="d-flex flex-stack flex-grow-1 flex-wrap flex-md-nowrap">
											<div className="mb-3 mb-md-0 fw-bold">
												<h4 className="text-gray-800 fw-bolder">Activate Subscription Plan.</h4>
												{
												<div className="fs-6 text-gray-600 pe-7">Trial plan has expired.</div>
												}
											</div>
											{
												write ?
													<a href={`${process.env.REACT_APP_PAYMENT_SERVER_URL}/purchase/bdua1638osba09376b8b8`} className="btn btn-primary px-6 align-self-center text-nowrap rounded">Buy plan</a>
													:
													<button className="btn btn-primary px-6 align-self-center text-nowrap rounded" disabled>Buy plan</button>
											}
										</div>
									</div>
									}	
									</>
										:
										null
								}
							{verification_flag==="not verified" &&
								<>
								{verification_date!=="" && Math.floor((process.env.REACT_APP_VALIDATION_MAIL_EXPIRY*24)-duration)>0 ?
								<>
								{(verification_id != "" && message_flag=="instant") &&
								<div className="notice d-flex bg-light-warning rounded border-warning border border-dashed p-6 mb-6">
									<span className="svg-icon svg-icon-2tx svg-icon-warning me-4">
										<svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
											<path d="M18.5,8 C17.1192881,8 16,6.88071187 16,5.5 C16,4.11928813 17.1192881,3 18.5,3 C19.8807119,3 21,4.11928813 21,5.5 C21,6.88071187 19.8807119,8 18.5,8 Z M18.5,21 C17.1192881,21 16,19.8807119 16,18.5 C16,17.1192881 17.1192881,16 18.5,16 C19.8807119,16 21,17.1192881 21,18.5 C21,19.8807119 19.8807119,21 18.5,21 Z M5.5,21 C4.11928813,21 3,19.8807119 3,18.5 C3,17.1192881 4.11928813,16 5.5,16 C6.88071187,16 8,17.1192881 8,18.5 C8,19.8807119 6.88071187,21 5.5,21 Z" fill="#000000" opacity="0.3"></path>
											<path d="M5.5,8 C4.11928813,8 3,6.88071187 3,5.5 C3,4.11928813 4.11928813,3 5.5,3 C6.88071187,3 8,4.11928813 8,5.5 C8,6.88071187 6.88071187,8 5.5,8 Z M11,4 L13,4 C13.5522847,4 14,4.44771525 14,5 C14,5.55228475 13.5522847,6 13,6 L11,6 C10.4477153,6 10,5.55228475 10,5 C10,4.44771525 10.4477153,4 11,4 Z M11,18 L13,18 C13.5522847,18 14,18.4477153 14,19 C14,19.5522847 13.5522847,20 13,20 L11,20 C10.4477153,20 10,19.5522847 10,19 C10,18.4477153 10.4477153,18 11,18 Z M5,10 C5.55228475,10 6,10.4477153 6,11 L6,13 C6,13.5522847 5.55228475,14 5,14 C4.44771525,14 4,13.5522847 4,13 L4,11 C4,10.4477153 4.44771525,10 5,10 Z M19,10 C19.5522847,10 20,10.4477153 20,11 L20,13 C20,13.5522847 19.5522847,14 19,14 C18.4477153,14 18,13.5522847 18,13 L18,11 C18,10.4477153 18.4477153,10 19,10 Z" fill="#000000"></path>
										</svg>
									</span>
									<div className="d-flex flex-stack flex-grow-1 flex-wrap flex-md-nowrap">
										<div className="mb-3 mb-md-0 fw-bold">
											<h4 className="text-gray-800 fw-bolder">Send Verfication Mail.</h4>
											{Math.floor((process.env.REACT_APP_VALIDATION_MAIL_EXPIRY*24)-duration)>1 ?
											 <div className="fs-6 text-gray-600 pe-7">Verfication Mail expires in {Math.floor((process.env.REACT_APP_VALIDATION_MAIL_EXPIRY*24)-duration)} hrs.</div>
											:
											0<Math.floor(duration)<1 &&
											<div className="fs-6 text-gray-600 pe-7">Verfication Mail expires in less than 1 hrs.</div>
											}
										</div>
										{
											(verification_date!=="" && write && duration<Math.floor(process.env.REACT_APP_VALIDATION_MAIL_EXPIRY*24)) &&
											<button type="button"  onClick={(e) => {setSendVerificationModalShow(true);}} disabled={mainSubmitButtonState} className="btn btn-primary px-6 align-self-center text-nowrap rounded"><span className="indicator-label" data-kt-indicator={mainSubmitButtonState?"on":"off"} disabled={mainSubmitButtonState}>Send</span>
												<span className="indicator-progress">Please wait...
													<span className="spinner-border spinner-border-sm align-middle ms-2"></span></span></button>
										}
										{
										(verification_date!=="" && write && duration>Math.floor(process.env.REACT_APP_VALIDATION_MAIL_EXPIRY*24)) &&
											<button type="button"  onClick={(e) => {setResendVerificationModalShow(true);}} disabled={mainSubmitButtonState} className="btn btn-primary px-6 align-self-center text-nowrap rounded"><span className="indicator-label" data-kt-indicator={mainSubmitButtonState?"on":"off"} disabled={mainSubmitButtonState}>Resend</span>
												<span className="indicator-progress">Please wait...
													<span className="spinner-border spinner-border-sm align-middle ms-2"></span></span></button>
										}
									</div>
								</div>
								}
								</>
								:
								<div className="notice d-flex bg-light-warning rounded border-warning border border-dashed p-6 mb-6">
									<span className="svg-icon svg-icon-2tx svg-icon-warning me-4">
										<svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
											<path d="M18.5,8 C17.1192881,8 16,6.88071187 16,5.5 C16,4.11928813 17.1192881,3 18.5,3 C19.8807119,3 21,4.11928813 21,5.5 C21,6.88071187 19.8807119,8 18.5,8 Z M18.5,21 C17.1192881,21 16,19.8807119 16,18.5 C16,17.1192881 17.1192881,16 18.5,16 C19.8807119,16 21,17.1192881 21,18.5 C21,19.8807119 19.8807119,21 18.5,21 Z M5.5,21 C4.11928813,21 3,19.8807119 3,18.5 C3,17.1192881 4.11928813,16 5.5,16 C6.88071187,16 8,17.1192881 8,18.5 C8,19.8807119 6.88071187,21 5.5,21 Z" fill="#000000" opacity="0.3"></path>
											<path d="M5.5,8 C4.11928813,8 3,6.88071187 3,5.5 C3,4.11928813 4.11928813,3 5.5,3 C6.88071187,3 8,4.11928813 8,5.5 C8,6.88071187 6.88071187,8 5.5,8 Z M11,4 L13,4 C13.5522847,4 14,4.44771525 14,5 C14,5.55228475 13.5522847,6 13,6 L11,6 C10.4477153,6 10,5.55228475 10,5 C10,4.44771525 10.4477153,4 11,4 Z M11,18 L13,18 C13.5522847,18 14,18.4477153 14,19 C14,19.5522847 13.5522847,20 13,20 L11,20 C10.4477153,20 10,19.5522847 10,19 C10,18.4477153 10.4477153,18 11,18 Z M5,10 C5.55228475,10 6,10.4477153 6,11 L6,13 C6,13.5522847 5.55228475,14 5,14 C4.44771525,14 4,13.5522847 4,13 L4,11 C4,10.4477153 4.44771525,10 5,10 Z M19,10 C19.5522847,10 20,10.4477153 20,11 L20,13 C20,13.5522847 19.5522847,14 19,14 C18.4477153,14 18,13.5522847 18,13 L18,11 C18,10.4477153 18.4477153,10 19,10 Z" fill="#000000"></path>
										</svg>
									</span>
									<div className="d-flex flex-stack flex-grow-1 flex-wrap flex-md-nowrap">
										<div className="mb-3 mb-md-0 fw-bold">
											<h4 className="text-gray-800 fw-bolder">Send Verfication Mail.</h4>
											{
											<div className="fs-6 text-gray-600 pe-7">Verfication Mail has expired..</div>
											}
										</div>
										{
										(write) &&
											<button type="button"  onClick={(e) => {setResendVerificationModalShow(true);}} disabled={mainSubmitButtonState} className="btn btn-primary px-6 align-self-center text-nowrap rounded"><span className="indicator-label" data-kt-indicator={mainSubmitButtonState?"on":"off"} disabled={mainSubmitButtonState}>Resend</span>
												<span className="indicator-progress">Please wait...
													<span className="spinner-border spinner-border-sm align-middle ms-2"></span></span></button>
										}
									</div>
								</div>
								}
								</>
							}
								{(Collection_details===null && CurrencySymbol!=="") &&
								<>
								<div className="row gy-5 g-xl-10">
                                    <div className="col-xl-4">
                                        <div className="card card-xl-stretch mb-xl-8">
                                            <div className="card-body p-0">
                                                <div className="px-9 pt-7 card-rounded h-275px w-100 bg-primary">
                                                    <div className="d-flex flex-stack">
                                                        <h3 className="m-0 text-white fw-bold fs-3">Dealer Collection Summary</h3>
                                                    </div>
                                                    <div className="d-flex text-center flex-column text-white pt-8">
                                                        <span className="fw-semibold fs-7">You Balance</span>
                                                        <span className="fw-bold fs-2x pt-1">{CurrencySymbol} 0</span>
                                                    </div>
                                                </div>
                                                <div className="bg-body shadow-sm card-rounded mx-9 mb-9 px-6 py-9 position-relative z-index-1" style={{"marginTop":"-100px"}}>            
                                                    <div className="d-flex align-items-center mb-6"> 
                                                        <div className="symbol symbol-45px w-40px me-5">
                                                            <span className="symbol-label bg-lighten">                             
                                                                <span className="svg-icon svg-icon-1"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path opacity="0.3" d="M18.4 5.59998C21.9 9.09998 21.9 14.8 18.4 18.3C14.9 21.8 9.2 21.8 5.7 18.3L18.4 5.59998Z" fill="currentColor"></path>
                                                                <path d="M12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2ZM19.9 11H13V8.8999C14.9 8.6999 16.7 8.00005 18.1 6.80005C19.1 8.00005 19.7 9.4 19.9 11ZM11 19.8999C9.7 19.6999 8.39999 19.2 7.39999 18.5C8.49999 17.7 9.7 17.2001 11 17.1001V19.8999ZM5.89999 6.90002C7.39999 8.10002 9.2 8.8 11 9V11.1001H4.10001C4.30001 9.4001 4.89999 8.00002 5.89999 6.90002ZM7.39999 5.5C8.49999 4.7 9.7 4.19998 11 4.09998V7C9.7 6.8 8.39999 6.3 7.39999 5.5ZM13 17.1001C14.3 17.3001 15.6 17.8 16.6 18.5C15.5 19.3 14.3 19.7999 13 19.8999V17.1001ZM13 4.09998C14.3 4.29998 15.6 4.8 16.6 5.5C15.5 6.3 14.3 6.80002 13 6.90002V4.09998ZM4.10001 13H11V15.1001C9.1 15.3001 7.29999 16 5.89999 17.2C4.89999 16 4.30001 14.6 4.10001 13ZM18.1 17.1001C16.6 15.9001 14.8 15.2 13 15V12.8999H19.9C19.7 14.5999 19.1 16.0001 18.1 17.1001Z" fill="currentColor"></path>
                                                                </svg>
                                                                </span>
                                                            </span>
                                                        </div>
                                                        <div className="d-flex align-items-center flex-wrap w-100">
                                                            <div className="mb-1 pe-3 flex-grow-1">
                                                                <a href="#" className="fs-5 text-gray-800 text-hover-primary fw-bold">Cash</a>
                                                                {/* <div className="text-gray-400 fw-semibold fs-7">100 Regions</div> */}
                                                            </div>
                                                            <div className="d-flex align-items-center">
                                                                <div className="fw-bold fs-5 text-gray-800 pe-1">{CurrencySymbol} 0</div>                          
                                                            </div>
                                                        </div>               
                                                    </div>       
                                                    <div className="d-flex align-items-center mb-6">
                                                        <div className="symbol symbol-45px w-40px me-5">
                                                            <span className="symbol-label bg-lighten">                               
                                                                <span className="svg-icon svg-icon-1"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <rect x="2" y="2" width="9" height="9" rx="2" fill="currentColor"></rect>
                                                                <rect opacity="0.3" x="13" y="2" width="9" height="9" rx="2" fill="currentColor"></rect>
                                                                <rect opacity="0.3" x="13" y="13" width="9" height="9" rx="2" fill="currentColor"></rect>
                                                                <rect opacity="0.3" x="2" y="13" width="9" height="9" rx="2" fill="currentColor"></rect>
                                                                </svg>
                                                                </span>
                                                            </span>
                                                        </div>
                                                        <div className="d-flex align-items-center flex-wrap w-100">
                                                            <div className="mb-1 pe-3 flex-grow-1">
                                                                <a href="#" className="fs-5 text-gray-800 text-hover-primary fw-bold">Cheque</a>
                                                                {/* <div className="text-gray-400 fw-semibold fs-7">Quarter 2/3</div> */}
                                                            </div>
                                                            <div className="d-flex align-items-center">
                                                                <div className="fw-bold fs-5 text-gray-800 pe-1">{CurrencySymbol} 0</div>                                                                      
                                                                </div>
                                                        </div>                  
                                                    </div>           
                                                    <div className="d-flex align-items-center mb-6">
                                                        <div className="symbol symbol-45px w-40px me-5">
                                                            <span className="symbol-label bg-lighten">                             
                                                            <span className="svg-icon svg-icon-1"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path opacity="0.3" d="M15 19H7C5.9 19 5 18.1 5 17V7C5 5.9 5.9 5 7 5H15C16.1 5 17 5.9 17 7V17C17 18.1 16.1 19 15 19Z" fill="currentColor"></path>
                                                            <path d="M8.5 2H13.4C14 2 14.5 2.4 14.6 3L14.9 5H6.89999L7.2 3C7.4 2.4 7.9 2 8.5 2ZM7.3 21C7.4 21.6 7.9 22 8.5 22H13.4C14 22 14.5 21.6 14.6 21L14.9 19H6.89999L7.3 21ZM18.3 10.2C18.5 9.39995 18.5 8.49995 18.3 7.69995C18.2 7.29995 17.8 6.90002 17.3 6.90002H17V10.9H17.3C17.8 11 18.2 10.7 18.3 10.2Z" fill="currentColor"></path>
                                                            </svg>
                                                            </span>
                                                            </span>
                                                        </div>
                                                        <div className="d-flex align-items-center flex-wrap w-100">
                                                            <div className="mb-1 pe-3 flex-grow-1">
                                                                <a href="#" className="fs-5 text-gray-800 text-hover-primary fw-bold">Bank</a>
                                                                {/* <div className="text-gray-400 fw-semibold fs-7">80% Rate</div> */}
                                                            </div>
                                                            <div className="d-flex align-items-center">
                                                                <div className="fw-bold fs-5 text-gray-800 pe-1">{CurrencySymbol} 0</div>                                
                                                                </div>
                                                            </div>                
                                                        </div>       
                                                        <div className="d-flex align-items-center ">
                                                            <div className="symbol symbol-45px w-40px me-5">
                                                                <span className="symbol-label bg-lighten">                             
                                                                    <span className="svg-icon svg-icon-1"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path opacity="0.3" d="M19 22H5C4.4 22 4 21.6 4 21V3C4 2.4 4.4 2 5 2H14L20 8V21C20 21.6 19.6 22 19 22ZM12.5 18C12.5 17.4 12.6 17.5 12 17.5H8.5C7.9 17.5 8 17.4 8 18C8 18.6 7.9 18.5 8.5 18.5L12 18C12.6 18 12.5 18.6 12.5 18ZM16.5 13C16.5 12.4 16.6 12.5 16 12.5H8.5C7.9 12.5 8 12.4 8 13C8 13.6 7.9 13.5 8.5 13.5H15.5C16.1 13.5 16.5 13.6 16.5 13ZM12.5 8C12.5 7.4 12.6 7.5 12 7.5H8C7.4 7.5 7.5 7.4 7.5 8C7.5 8.6 7.4 8.5 8 8.5H12C12.6 8.5 12.5 8.6 12.5 8Z" fill="currentColor"></path>
                                                                    <rect x="7" y="17" width="6" height="2" rx="1" fill="currentColor"></rect>
                                                                    <rect x="7" y="12" width="10" height="2" rx="1" fill="currentColor"></rect>
                                                                    <rect x="7" y="7" width="6" height="2" rx="1" fill="currentColor"></rect>
                                                                    <path d="M15 8H20L14 2V7C14 7.6 14.4 8 15 8Z" fill="currentColor"></path>
                                                                    </svg>
                                                                    </span>
                                                                </span>
                                                            </div>
                                                            <div className="d-flex align-items-center flex-wrap w-100">
                                                                <div className="mb-1 pe-3 flex-grow-1">
                                                                    <a href="#" className="fs-5 text-gray-800 text-hover-primary fw-bold">PDC</a>
                                                                    {/* <div className="text-gray-400 fw-semibold fs-7">3090 Refunds</div> */}
                                                                </div>
                                                                <div className="d-flex align-items-center">
                                                                    <div className="fw-bold fs-5 text-gray-800 pe-1">{CurrencySymbol} 0</div>                              

                                                                </div>
                                                                </div>                 
                                                        </div>
                                                    </div>   
                                                </div>
                                            </div>
                                        </div>
									</div>
									</>}
								{Collection_details!=null &&
								<>
								<div className="row gy-5 g-xl-10">
                                    <div className="col-xl-4">
                                        <div className="card card-xl-stretch mb-xl-8">
                                            <div className="card-body p-0">
                                                <div className="px-9 pt-7 card-rounded h-275px w-100 bg-primary">
                                                    <div className="d-flex flex-stack">
                                                        <h3 className="m-0 text-white fw-bold fs-3">Dealer Collection Summary</h3>
                                                    </div>
                                                    <div className="d-flex text-center flex-column text-white pt-8">
                                                        <span className="fw-semibold fs-7">You Balance</span>
                                                        <span className="fw-bold fs-2x pt-1">{CurrencySymbol} {Collection_details.total_amount}</span>
                                                    </div>
                                                </div>
                                                <div className="bg-body shadow-sm card-rounded mx-9 mb-9 px-6 py-9 position-relative z-index-1" style={{"marginTop":"-100px"}}>            
                                                    <div className="d-flex align-items-center mb-6"> 
                                                        <div className="symbol symbol-45px w-40px me-5">
                                                            <span className="symbol-label bg-lighten">                             
                                                                <span className="svg-icon svg-icon-1"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path opacity="0.3" d="M18.4 5.59998C21.9 9.09998 21.9 14.8 18.4 18.3C14.9 21.8 9.2 21.8 5.7 18.3L18.4 5.59998Z" fill="currentColor"></path>
                                                                <path d="M12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2ZM19.9 11H13V8.8999C14.9 8.6999 16.7 8.00005 18.1 6.80005C19.1 8.00005 19.7 9.4 19.9 11ZM11 19.8999C9.7 19.6999 8.39999 19.2 7.39999 18.5C8.49999 17.7 9.7 17.2001 11 17.1001V19.8999ZM5.89999 6.90002C7.39999 8.10002 9.2 8.8 11 9V11.1001H4.10001C4.30001 9.4001 4.89999 8.00002 5.89999 6.90002ZM7.39999 5.5C8.49999 4.7 9.7 4.19998 11 4.09998V7C9.7 6.8 8.39999 6.3 7.39999 5.5ZM13 17.1001C14.3 17.3001 15.6 17.8 16.6 18.5C15.5 19.3 14.3 19.7999 13 19.8999V17.1001ZM13 4.09998C14.3 4.29998 15.6 4.8 16.6 5.5C15.5 6.3 14.3 6.80002 13 6.90002V4.09998ZM4.10001 13H11V15.1001C9.1 15.3001 7.29999 16 5.89999 17.2C4.89999 16 4.30001 14.6 4.10001 13ZM18.1 17.1001C16.6 15.9001 14.8 15.2 13 15V12.8999H19.9C19.7 14.5999 19.1 16.0001 18.1 17.1001Z" fill="currentColor"></path>
                                                                </svg>
                                                                </span>
                                                            </span>
                                                        </div>
                                                        <div className="d-flex align-items-center flex-wrap w-100">
                                                            <div className="mb-1 pe-3 flex-grow-1">
                                                                <a href="#" className="fs-5 text-gray-800 text-hover-primary fw-bold">Cash</a>
                                                                {/* <div className="text-gray-400 fw-semibold fs-7">100 Regions</div> */}
                                                            </div>
                                                            <div className="d-flex align-items-center">
                                                                <div className="fw-bold fs-5 text-gray-800 pe-1">{CurrencySymbol} {Collection_details.cash_amount}</div>                          
                                                            </div>
                                                        </div>               
                                                    </div>       
                                                    <div className="d-flex align-items-center mb-6">
                                                        <div className="symbol symbol-45px w-40px me-5">
                                                            <span className="symbol-label bg-lighten">                               
                                                                <span className="svg-icon svg-icon-1"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <rect x="2" y="2" width="9" height="9" rx="2" fill="currentColor"></rect>
                                                                <rect opacity="0.3" x="13" y="2" width="9" height="9" rx="2" fill="currentColor"></rect>
                                                                <rect opacity="0.3" x="13" y="13" width="9" height="9" rx="2" fill="currentColor"></rect>
                                                                <rect opacity="0.3" x="2" y="13" width="9" height="9" rx="2" fill="currentColor"></rect>
                                                                </svg>
                                                                </span>
                                                            </span>
                                                        </div>
                                                        <div className="d-flex align-items-center flex-wrap w-100">
                                                            <div className="mb-1 pe-3 flex-grow-1">
                                                                <a href="#" className="fs-5 text-gray-800 text-hover-primary fw-bold">Cheque</a>
                                                                {/* <div className="text-gray-400 fw-semibold fs-7">Quarter 2/3</div> */}
                                                            </div>
                                                            <div className="d-flex align-items-center">
                                                                <div className="fw-bold fs-5 text-gray-800 pe-1">{CurrencySymbol} {Collection_details.cheque_amount}</div>                                                                      
                                                                </div>
                                                        </div>                  
                                                    </div>           
                                                    <div className="d-flex align-items-center mb-6">
                                                        <div className="symbol symbol-45px w-40px me-5">
                                                            <span className="symbol-label bg-lighten">                             
                                                            <span className="svg-icon svg-icon-1"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path opacity="0.3" d="M15 19H7C5.9 19 5 18.1 5 17V7C5 5.9 5.9 5 7 5H15C16.1 5 17 5.9 17 7V17C17 18.1 16.1 19 15 19Z" fill="currentColor"></path>
                                                            <path d="M8.5 2H13.4C14 2 14.5 2.4 14.6 3L14.9 5H6.89999L7.2 3C7.4 2.4 7.9 2 8.5 2ZM7.3 21C7.4 21.6 7.9 22 8.5 22H13.4C14 22 14.5 21.6 14.6 21L14.9 19H6.89999L7.3 21ZM18.3 10.2C18.5 9.39995 18.5 8.49995 18.3 7.69995C18.2 7.29995 17.8 6.90002 17.3 6.90002H17V10.9H17.3C17.8 11 18.2 10.7 18.3 10.2Z" fill="currentColor"></path>
                                                            </svg>
                                                            </span>
                                                            </span>
                                                        </div>
                                                        <div className="d-flex align-items-center flex-wrap w-100">
                                                            <div className="mb-1 pe-3 flex-grow-1">
                                                                <a href="#" className="fs-5 text-gray-800 text-hover-primary fw-bold">Bank</a>
                                                                {/* <div className="text-gray-400 fw-semibold fs-7">80% Rate</div> */}
                                                            </div>
                                                            <div className="d-flex align-items-center">
                                                                <div className="fw-bold fs-5 text-gray-800 pe-1">{CurrencySymbol} {Collection_details.bank_amount}</div>                                
                                                                </div>
                                                            </div>                
                                                        </div>       
                                                        <div className="d-flex align-items-center ">
                                                            <div className="symbol symbol-45px w-40px me-5">
                                                                <span className="symbol-label bg-lighten">                             
                                                                    <span className="svg-icon svg-icon-1"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path opacity="0.3" d="M19 22H5C4.4 22 4 21.6 4 21V3C4 2.4 4.4 2 5 2H14L20 8V21C20 21.6 19.6 22 19 22ZM12.5 18C12.5 17.4 12.6 17.5 12 17.5H8.5C7.9 17.5 8 17.4 8 18C8 18.6 7.9 18.5 8.5 18.5L12 18C12.6 18 12.5 18.6 12.5 18ZM16.5 13C16.5 12.4 16.6 12.5 16 12.5H8.5C7.9 12.5 8 12.4 8 13C8 13.6 7.9 13.5 8.5 13.5H15.5C16.1 13.5 16.5 13.6 16.5 13ZM12.5 8C12.5 7.4 12.6 7.5 12 7.5H8C7.4 7.5 7.5 7.4 7.5 8C7.5 8.6 7.4 8.5 8 8.5H12C12.6 8.5 12.5 8.6 12.5 8Z" fill="currentColor"></path>
                                                                    <rect x="7" y="17" width="6" height="2" rx="1" fill="currentColor"></rect>
                                                                    <rect x="7" y="12" width="10" height="2" rx="1" fill="currentColor"></rect>
                                                                    <rect x="7" y="7" width="6" height="2" rx="1" fill="currentColor"></rect>
                                                                    <path d="M15 8H20L14 2V7C14 7.6 14.4 8 15 8Z" fill="currentColor"></path>
                                                                    </svg>
                                                                    </span>
                                                                </span>
                                                            </div>
                                                            <div className="d-flex align-items-center flex-wrap w-100">
                                                                <div className="mb-1 pe-3 flex-grow-1">
                                                                    <a href="#" className="fs-5 text-gray-800 text-hover-primary fw-bold">PDC</a>
                                                                    {/* <div className="text-gray-400 fw-semibold fs-7">3090 Refunds</div> */}
                                                                </div>
                                                                <div className="d-flex align-items-center">
                                                                    <div className="fw-bold fs-5 text-gray-800 pe-1">{CurrencySymbol} {Collection_details.pdc_amount}</div>                              

                                                                </div>
                                                                </div>                 
                                                        </div>
                                                    </div>   
                                                </div>
                                            </div>
                                        </div>
										<div className="col-xl-8">
											<div className="card card-xl-stretch mb-xl-8" style={{height: 500}}>
											<ResponsivePie
												data={Graph_data1}
												margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
												innerRadius={0.5}
												padAngle={0.7}
												cornerRadius={3}
												activeOuterRadiusOffset={8}
												borderWidth={1}
												borderColor={{
													from: 'color',
													modifiers: [
														[
															'darker',
															0.2
														]
													]
												}}
												arcLinkLabelsSkipAngle={10}
												arcLinkLabelsTextColor="#333333"
												arcLinkLabelsThickness={2}
												arcLinkLabelsColor={{ from: 'color' }}
												arcLabelsSkipAngle={10}
												arcLabelsTextColor={{
													from: 'color',
													modifiers: [
														[
															'darker',
															2
														]
													]
												}}
												defs={[
													{
														id: 'dots',
														type: 'patternDots',
														background: 'inherit',
														color: 'rgba(255, 255, 255, 0.3)',
														size: 4,
														padding: 1,
														stagger: true
													},
													{
														id: 'lines',
														type: 'patternLines',
														background: 'inherit',
														color: 'rgba(255, 255, 255, 0.3)',
														rotation: -45,
														lineWidth: 6,
														spacing: 10
													}
												]}
												fill={[
													{
														match: {
															id: 'Cash'
														},
														id: 'dots'
													},
													{
														match: {
															id: 'Cheque'
														},
														id: 'dots'
													}
												]}
												legends={[
													{
														anchor: 'bottom',
														direction: 'row',
														justify: false,
														translateX: 0,
														translateY: 56,
														itemsSpacing: 0,
														itemWidth: 100,
														itemHeight: 18,
														itemTextColor: '#999',
														itemDirection: 'left-to-right',
														itemOpacity: 1,
														symbolSize: 18,
														symbolShape: 'circle',
														effects: [
															{
																on: 'hover',
																style: {
																	itemTextColor: '#000'
																}
															}
														]
													}
												]}
											/>
											</div>
										</div>
									</div>
									</>
								}
								</>
								:
								<>
								{state == 2 ?
									<img src="/images/loader-06.svg" alt="" style={loaderstyle} />
									:
									<>

										<div className="flex-lg-row-fluid me-lg-15">
											<div className="card card-flush pt-3 mb-5 mb-lg-10">

												<div className="card-header">
													<div className="card-title">
														<h2 className="fw-bolder">Dashboard</h2>
													</div>
												</div>


												<div className="card-body pt-0">
													<div className="text-gray-500 fw-bold fs-5 mb-5"></div>
													<div className="d-flex align-items-center p-3 mb-2">


													</div>


													<div className="notice d-flex bg-light-primary rounded border-primary border border-dashed rounded-3 p-6">
														<div className="d-flex flex-stack flex-grow-1">
															<div className="fw-bold">
																<h4 className="text-gray-800 fw-bolder">Please Login</h4>
																<div className="fs-6 text-gray-600">
																</div>
															</div>

														</div>

													</div>

												</div>

											</div>
										</div>

									</>
								}
								</>
							}
								{/* {
									Quotalimit_count.length && Quotalimit_count[0].quotalimit_count > 0 ?
										<>
											<div className="notice d-flex bg-light-warning rounded border-warning border border-dashed p-6">


												<span className="svg-icon svg-icon-2tx svg-icon-warning me-4">
													<svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
														<circle fill="#000000" opacity="0.3" cx="12" cy="12" r="10" />
														<rect fill="#000000" x="11" y="7" width="2" height="8" rx="1" />
														<rect fill="#000000" x="11" y="16" width="2" height="2" rx="1" />
													</svg>
												</span>


												<div className="d-flex flex-stack flex-grow-1">

													<div className="fw-bold">
														<h4 className="text-gray-800 fw-bolder">We need your attention!</h4>
														<div className="fs-6 text-gray-600">Your daily limit has been reached to receive more alerts please
															{
																write ?
																	<Link className="fw-bolder" to={`/subscription-list/${Quotalimit_count[0].purchase_subcription_id}`}> Upgrade your subscription</Link>
																	:
																	<button className="fw-bolder" > Upgrade your subscription</button>
															}

														</div>
													</div>

												</div>

											</div><br />
										</>
										: null
								} */}

								{/* <div className="tradingview-widget-container" id="dashboard_script" >
									<div className="tradingview-widget-container__widget"></div>
								</div> */}

							</div>
						</div>
					</div>
					<Modal show={sendInvitationModalShow} onHide={() => { setSendVerificationModalShow() }} size="md" centered>
						<Modal.Header>
							<Modal.Title>Send Invitation</Modal.Title>
							<div className="btn btn-sm btn-icon btn-active-color-primary" onClick={() => { setSendVerificationModalShow(false) }}>
								<span className="svg-icon svg-icon-1">
									<svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
										<g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
											<rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
											<rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
										</g>
									</svg>
								</span>
							</div>
						</Modal.Header>
						<Modal.Body>
							<div className="form-group row">
								<div className="col-lg-12">
									<h3>Are you sure about send invitation?</h3>
								</div>
							</div>
						</Modal.Body>
						<Modal.Footer>
							<button type="button" onClick={(e) => { setSendVerificationModalShow(false) }} className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
							{write == 1 && <form >
								<button type="button" onClick={(e) => { sendverificationmail() }} className="btn btn-danger" data-kt-indicator={mainSubmitButtonState ? "on" : "off"} disabled={mainSubmitButtonState} ><span className="indicator-label">Send</span>
									<span className="indicator-progress">Please wait...
										<span className="spinner-border spinner-border-sm align-middle ms-2"></span></span></button>
							</form>}
						</Modal.Footer>
					</Modal>
					<Modal show={resendVerificationModalShow} onHide={() => { setResendVerificationModalShow() }} size="md" centered>
						<Modal.Header>
							<Modal.Title>Resend Invitation</Modal.Title>
							<div className="btn btn-sm btn-icon btn-active-color-primary" onClick={() => { setResendVerificationModalShow(false) }}>
								<span className="svg-icon svg-icon-1">
									<svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
										<g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
											<rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
											<rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
										</g>
									</svg>
								</span>
							</div>
						</Modal.Header>
						<Modal.Body>
							<div className="form-group row">
								<div className="col-lg-12">
									<h3>Are you sure about resend invitation?</h3>
								</div>
							</div>
						</Modal.Body>
						<Modal.Footer>
							<button type="button" onClick={(e) => { setResendVerificationModalShow(false) }} className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
							{write == 1 && <form >
								<button type="button" onClick={(e) => { Resendverificationmail() }} className="btn btn-danger" data-kt-indicator={mainSubmitButtonState ? "on" : "off"} disabled={mainSubmitButtonState} ><span className="indicator-label">Resend</span>
									<span className="indicator-progress">Please wait...
										<span className="spinner-border spinner-border-sm align-middle ms-2"></span></span></button>
							</form>}
						</Modal.Footer>
					</Modal>

			<Organization_modal OrganizationModalShow={OrganizationModalShow} setOrganizationModalShow1={setOrganizationModalShow} organization_name={organization_name} organization_list={organization_list} organization_id={organization_id} state_org={state_org} mainSubmitButtonState={mainSubmitButtonState} setMainSubmitButtonState={setMainSubmitButtonState}/>
			<Timer_modal clockTimerModalShow={clockTimerModalShow} setClockTimerModalShow1={setClockTimerModalShow} clock_timer={clock_timer} organization_id={organization_id} setTimer={setTimer} mainSubmitButtonState={mainSubmitButtonState} setMainSubmitButtonState={setMainSubmitButtonState}/>
			
		</>
	);
}
export default Dashboard;