import React, { lazy, Suspense, useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import swal from 'sweetalert';
import { Redirect } from "react-router-dom";
//import process.env from '../config.json';
import ReCAPTCHA from "react-google-recaptcha"

import $ from 'jquery';
function VerificationAccount(props) {
	//console.log(props);
	let modal = 2;
	let [RedirectState, setRedirectState] = useState(2);

	let [captchaToken, setCaptchaToken] = useState("");
	const [value, setValue] = useState();
	const [user_id, setuser_id] = useState();
	const [verification_status, setverification_status] = useState();
	const [verification_message, setverification_message] = useState("");
	const [verification_state, setverification_state] = useState(2);
	const [verification_date_time, setverification_date_time] = useState("");
	const [mainSubmitButtonState, setMainSubmitButtonState] = useState(false);

	const loaderstyle = {
		display: "block",
		marginLeft: "auto",
		marginRight: "auto",
		marginTop: "auto",
		marginBottom: "auto",
	};

	function onChange(value) {
		setCaptchaToken(value)
	}

	function setCookie(cname, cvalue, exdays) {
		var d = new Date();
		d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
		var expires = "expires=" + d.toUTCString();
		document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
	}

	function getCookie(cname) {
		var name = cname + "=";
		var ca = document.cookie.split(';');
		for (var i = 0; i < ca.length; i++) {
			var c = ca[i];
			while (c.charAt(0) == ' ') {
				c = c.substring(1);
			}
			if (c.indexOf(name) == 0) {
				return c.substring(name.length, c.length);
			}
		}
		return "";
	}


	useEffect(() => {
		async function getverificationmailstatus() {
			var object = {};
			const response = await fetch(
				`${process.env.REACT_APP_SERVER_URL}/check-verification-status-id`, { method: 'POST', body: JSON.stringify({ "id": props.match.params.id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
			);
			const body = await response.json();
			console.log(body)
			if (body.operation === "success") {
				if (body.verification_flag == "not verified") {
					verify_email();
				}
				else {
					setverification_state(1);
					setverification_message(body.message);
					setverification_status(body.verification_flag);
					setuser_id(body.user_id);
					// setverification_date_time(body[0].log_date_time);
				}
			}
			else {
				setverification_message(body.message);
				setverification_state(1);
				setverification_status("not found");
			}

		}
		getverificationmailstatus();
	}, [])

	const verify_email = () => {
		var object = {};
		object.id = props.match.params.id;
		setMainSubmitButtonState(true)
		axios.post(`${process.env.REACT_APP_SERVER_URL}/verify-email`, object).then((res) => {
			if (res.data.operation == "success") {
				setMainSubmitButtonState(false)
				setverification_state(1);
				swal("Great!", "Your email is successfully verified. ", "success", {
					buttons: {
						continue: {
							text: "OK",
							value: "continue",
						},
					}
				}).then((result) => {
					//console.log(result);
					switch (result) {
						case "continue":
							window.location = `/login`;
							break;
						default:
							window.location = `/login`;
							break;

					}
				});
			}
			else {
				setMainSubmitButtonState(false)
				if (res.data.message != "danger") {
					swal("Oops!", res.data.message, "error").then((res) => {
						window.location.reload();
					});
				}
				else {
					swal("Oops!", "something went wrong", "error").then((res) => {
						window.location.reload();
					});
				}
			}
		})
			.catch(function (error) {
				swal("Oops!", "something went wrong", "error").then((res) => {
					window.location.reload();
				});
			});
	}




	return (
		<>
			{
				(RedirectState == 1) ? ((getCookie("lastpage") != "") ? window.location.href = getCookie("lastpage") : window.location.href = "/"
				)
					:
					null
			}
			<div className="d-flex flex-column flex-root" id="kt_app_root">
				<div className="d-flex flex-column flex-lg-row flex-column-fluid"  style={{backgroundImage: "url(/assets/media/auth/bg5.jpg)",backgroundSize: "cover" }}>
					<div className="d-flex flex-lg-row-fluid w-lg-50 bgi-size-cover bgi-position-center order-1 order-lg-2">
						<div className="d-flex flex-column flex-center py-15 px-5 px-md-15 w-100">
							<a href="/login" className="mb-12">
								<img alt="Logo" src="https://cdnstatic.adocrm.com/static/ado-crm-logo.png" className="h-75px" />
							</a>
							<div class="d-flex flex-column flex-center text-center p-10">
								<div class="card card-flush w-md-650px py-5">
									<div class="card-body py-15 py-lg-20">
										{verification_state == 2 ?
											<img src="/images/loader-06.svg" alt="" style={loaderstyle} />
											:
											<div style={{ textAlign: "center"}}>
												{verification_status==="verified" ?
												<svg width="150" height="150" viewBox="150 150 450 450">
													<g transform="translate(0.000000,752.000000) scale(0.100000,-0.100000)" fill="#0cf072" stroke="none">
														<path d="M3700 5731 c-56 -45 -266 -183 -375 -246 -368 -213 -690 -329 -1104 -399 -79 -14 -147 -30 -152 -37 -12 -21 -22 -252 -15 -376 46 -834 297 -1509 792 -2130 103 -128 393 -419 519 -519 211 -168 355 -264 395 -264 76 0 463 302 700 547 538 553 870 1238 975 2012 34 250 43 686 16 730 -5 7 -73 23 -152 37 -508 86 -887 243 -1365 565 -88 60 -169 109 -180 109 -10 -1 -35 -13 -54 -29z m180 -412 c187 -133 473 -288 644 -348 130 -46 294 -88 436 -112 205 -34 205 -34 197 -344 -12 -462 -105 -837 -307 -1235 -214 -421 -548 -797 -950 -1069 -137 -93 -143 -93 -280 0 -57 38 -147 104 -199 146 -117 93 -342 318 -435 433 -210 263 -394 604 -491 910 -85 271 -124 511 -132 815 -6 240 0 277 49 308 12 9 79 25 148 36 142 24 306 66 436 112 171 60 457 215 644 348 65 47 93 61 120 61 27 0 55 -14 120 -61z" />
														<path d="M3685 5188 c-316 -224 -667 -379 -1000 -443 -77 -15 -151 -29 -164 -32 l-24 -4 6 -217 c7 -280 37 -469 114 -722 174 -576 566 -1105 1066 -1439 l77 -52 78 52 c249 167 504 415 681 664 329 462 501 996 501 1557 0 156 0 158 -22 162 -328 61 -509 114 -698 203 -133 63 -295 155 -430 247 -58 39 -107 72 -110 72 -3 1 -36 -20 -75 -48z m822 -790 c29 -27 29 -57 1 -94 -67 -86 -862 -1016 -884 -1033 -32 -25 -81 -27 -112 -5 -18 13 -239 264 -345 392 -52 64 -24 129 55 123 10 0 90 -87 180 -191 153 -180 163 -189 179 -173 9 10 195 230 414 488 218 259 408 480 422 493 31 28 60 28 90 0z" />
													</g>
												</svg>
												:
												verification_status==="not found" ?
												<svg  viewBox="0 0 64 80" width="150" height="150"><g fill="#f0140c"><path d="M40.62012,17.0997A44.5408,44.5408,0,0,1,32,12.15536a44.51923,44.51923,0,0,1-8.62012,4.94434,35.27843,35.27843,0,0,1-8.334,2.1377A36.87166,36.87166,0,0,0,18.291,36.30771,36.79949,36.79949,0,0,0,32,51.9415,36.801,36.801,0,0,0,45.709,36.30869,36.87471,36.87471,0,0,0,48.9541,19.2374,35.27855,35.27855,0,0,1,40.62012,17.0997ZM38.707,37.32919A1,1,0,0,1,37.293,38.74326L32,33.45029l-5.293,5.293A.99992.99992,0,0,1,25.293,37.32919l5.293-5.293-5.293-5.293A.99992.99992,0,0,1,26.707,25.32919L32,30.62216l5.293-5.293A.99992.99992,0,0,1,38.707,26.74326l-5.293,5.293Z"/><path d="M54.91168,14.98605a.636.636,0,0,0-.53558-.59063A45.67141,45.67141,0,0,1,43.5,11.6434,57.38138,57.38138,0,0,1,32.35211,5.15091a.59754.59754,0,0,0-.70422,0A57.38138,57.38138,0,0,1,20.5,11.6434a45.67141,45.67141,0,0,1-10.8761,2.752.636.636,0,0,0-.53558.59063,48.92817,48.92817,0,0,0,4.2323,23.07709A48.18526,48.18526,0,0,0,31.46484,58.80881a1.00124,1.00124,0,0,0,1.07038,0A48.18562,48.18562,0,0,0,50.67938,38.06314,48.92817,48.92817,0,0,0,54.91168,14.98605ZM47.52734,37.13974A38.82131,38.82131,0,0,1,32.78027,53.81161a1.47255,1.47255,0,0,1-1.5625-.001A38.8201,38.8201,0,0,1,16.47266,37.13876a38.8851,38.8851,0,0,1-3.40137-18.4209,1.4905,1.4905,0,0,1,1.25976-1.38964,35.03714,35.03714,0,0,0,8.28907-2.07813,43.61475,43.61475,0,0,0,8.52148-4.9375,1.46754,1.46754,0,0,1,1.71778.001,43.62456,43.62456,0,0,0,8.5205,4.93652,35.092,35.092,0,0,0,8.293,2.0791,1.49008,1.49008,0,0,1,1.25586,1.39063A38.8815,38.8815,0,0,1,47.52734,37.13974Z"/></g></svg>
												:
												<svg viewBox="0 0 64 80" width="150" height="150"><g fill="#ebc81c"><path d="M32,19.75c-1.00122,0-2.03967.67444-1.999,1.61621.16229,3.71564.95251,11.19843,1.30273,14.90039a.74317.74317,0,0,0,1.39258,0c.35181-3.71869,1.1405-11.18585,1.30273-14.90039C34.03967,20.42444,33.00122,19.75,32,19.75Z"/><path d="M40.62012,17.06348A44.55522,44.55522,0,0,1,32,12.11914a44.54886,44.54886,0,0,1-8.62012,4.94434,35.26115,35.26115,0,0,1-8.33447,2.13769A36.8664,36.8664,0,0,0,18.291,36.27148,36.80257,36.80257,0,0,0,32,51.90527,36.79916,36.79916,0,0,0,45.709,36.27148a36.87074,36.87074,0,0,0,3.24561-17.07031A35.29493,35.29493,0,0,1,40.62012,17.06348ZM32,46a3,3,0,1,1,3-3A3.00328,3.00328,0,0,1,32,46Zm2.6875-9.5459a2.7209,2.7209,0,0,1-5.375,0c-.33282-3.52771-1.14294-11.18817-1.30957-15A3.79164,3.79164,0,0,1,32,17.75a3.79168,3.79168,0,0,1,3.99707,3.7041C35.83044,25.26593,35.02032,32.92639,34.6875,36.4541Z"/><circle cx="32" cy="43" r="1"/><path d="M54.91187,14.95361a.6353.6353,0,0,0-.53919-.595A45.66019,45.66019,0,0,1,43.5,11.60718a57.36157,57.36157,0,0,1-11.1416-6.488.59841.59841,0,0,0-.7168,0A57.36157,57.36157,0,0,1,20.5,11.60718,45.66019,45.66019,0,0,1,9.62732,14.35864a.6353.6353,0,0,0-.53919.595,48.92747,48.92747,0,0,0,4.23249,23.07331A48.18657,48.18657,0,0,0,31.674,58.9032a.59766.59766,0,0,0,.6521,0A48.18657,48.18657,0,0,0,50.67938,38.02692,48.92747,48.92747,0,0,0,54.91187,14.95361ZM47.52832,37.10352A38.831,38.831,0,0,1,32.78076,53.77539a1.47612,1.47612,0,0,1-1.562,0A38.83369,38.83369,0,0,1,16.47168,37.10352,38.9062,38.9062,0,0,1,13.0708,18.68164,1.49452,1.49452,0,0,1,14.33008,17.292a35.02815,35.02815,0,0,0,8.29-2.07812,43.62922,43.62922,0,0,0,8.52148-4.9375,1.46516,1.46516,0,0,1,1.71826.002,43.66787,43.66787,0,0,0,8.52,4.93555,35.08875,35.08875,0,0,0,8.294,2.0791,1.49273,1.49273,0,0,1,1.25537,1.38867A38.9062,38.9062,0,0,1,47.52832,37.10352Z"/></g></svg>
												}
												<div className="fs-3 fw-bold text-muted mt-10"><a className="link-primary fw-bolder"> {user_id}</a> {verification_message}</div>
											</div>
										}
									</div>
								</div>
							</div>
							<h1 className="text-white fs-2qx fw-bolder text-center mb-7">Fast, Efficient and Productive</h1>

							{/* <div className="text-white fs-base text-center">In this kind of post,
						<a href="#" className="opacity-75-hover text-warning fw-bold me-1">the blogger</a>introduces a person they’ve interviewed
						<br />and provides some background information about
						<a href="#" className="opacity-75-hover text-warning fw-bold me-1">the interviewee</a>and their
						<br />work following this is a transcript of the interview.</div> */}
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
export default VerificationAccount;