// import AsideNavbar from './includes/aside-navbar.js';
// import Header from './includes/header.js';
import BasePage from "./BasePage";
// import Footer from './includes/footer.js';
// import ActivityLogAlert from './includes/activity-log-alert.js';

function App() {
  return (
  <>		
		<BasePage />
		
		{/*<ActivityLogAlert />*/}
  </>
  );
}

export default App;
