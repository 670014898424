import React, {lazy,Suspense,useState,useEffect} from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import swal from 'sweetalert';
import { Redirect } from "react-router-dom";
//import process.env from '../config.json';
import ReCAPTCHA from "react-google-recaptcha";
import { Link } from 'react-router-dom';
import {setCookie,getCookie,delete_cookie} from '../common_component/cookie.js';

function Signin() {
	
	const [RedirectState, setRedirectState] = useState(2);
	const [organization_id, setOrganization_id] = useState("");
	const [captchaToken, setCaptchaToken] = useState("");
	const [passwordType, setPasswordType] = useState("password");
	function onChange(value) {
		setCaptchaToken(value)
	}
		
	const login =(e)=>{
		
		//let domain = (new URL(url));
			e.preventDefault();
			var object = {};
			object.email = document.getElementById("email").value;
			object.password = document.getElementById("password").value;
			object.token = captchaToken;
			
			var exp=/^[a-z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
			if(object.email=="")
			{
				swal("Oops!", "Please enter an email id", "error");
				return false;
			}
			else if (!object.email.match(exp))
			{
				swal("Oops!", "Please enter a valid email id", "error");
				return false;
			}
			
			if(object.password=="")
			{
				swal("Oops!", "Please enter password.", "error");
				return false;
			}
			// else if (!/^(?=.*[A-Za-z])(?=.*\d).{8,}$/.test(object.password)) {
			// 	swal("Oops!", "Password must be at least 8 characters long and contain both letters and numbers.", "error");
			// 	return false;
			// }
			
			if(object.token=="")
			{
				swal("Oops!", "Please Verify the captcha", "error");
				return false;
			}
			else if(!isNaN(object.token))
			{
				swal("Oops!", "Please Verify the captcha", "error");
				return false;
			}
			
			if(e.target.id == "kt_sign_in_submit")
			{
				e.target.setAttribute("data-kt-indicator", "on");
				e.target.disabled = true;
			}
			else
			{
				document.getElementById("kt_sign_in_submit").setAttribute("data-kt-indicator", "on");
				document.getElementById("kt_sign_in_submit").disabled = true;
			}
			
			var headers = {
			  headers: {
				"Content-Type": "application/json",
				"Accept": "*/*"
			  }
			}
			
			axios.post(`${process.env.REACT_APP_SERVER_URL}/login`,object, headers).then((res) => {
				
				if(res.data.operation == "success")
				{
					if(res.data.quick_set_up==0)
					{
						setCookie("access_token", "", -1,true);
						setCookie("organization_id", "", -1,true);
						var object = {};
						var headers = {
							headers: {
								"Content-Type": "application/json",
								"token": res.data.token,
								"Accept": "*/*"
							}
						}
						axios.post(`${process.env.REACT_APP_SERVER_URL}/show-default-organization`, object, headers).then((res1) => {
							if (res1.data.length>0) {
								setCookie('organization_id', res1.data[0].organization_id, 1,true);
								setOrganization_id(res1.data[0].organization_id);
								setCookie('access_token', res.data.token, 1,true);
								setRedirectState(1);
								onsubmit();
							}
						})
					}
					else
					{						
						setCookie('access_token', res.data.token, 1,true);
						window.location.href = "/quick-set-up";
					}
				}
				else
				{
					if(res.data.message != "danger")
					{
						swal("Oops!", res.data.message, "error").then((res) => {
							window.location.reload();
						});
					}
					else
					{
						swal("Oops!", "Username or Password is wrong", "error").then((res) => {
							window.location.reload();
						});
					}
				}
			})
			.catch(function (error) {
				swal("Oops!", "something went wrong", "error").then((res) => {
					window.location.reload();
				});
			});
	}
	
	useEffect(() => {
			var se = document.createElement('script');
			se.src = "/assets/js/custom/authentication/sign-in/general.js";
			document.body.appendChild(se);
	},[])

	const onsubmit = () =>{		
		if(getCookie("lastpage") != "")
		{
			window.location.href = getCookie("lastpage");
		}
		else{
			window.location.href = "/dashboard"
		}
	}

	const togglePassword =()=>{
		if(passwordType==="password")
		{
		 setPasswordType("text")
		 return;
		}
		setPasswordType("password")
	  }
	
	return (
		<>
		<div className="d-flex flex-column flex-root" id="kt_app_root">			

			<div class="d-flex flex-column flex-column-fluid flex-lg-row" style={{backgroundImage:"url(assets/media/auth/bg4.jpg)"}}>
				<div class="d-flex flex-center w-lg-50 pt-15 pt-lg-0 px-10">
					<div class="d-flex flex-center flex-lg-start flex-column">
						<a href="/" class="mb-7">
							<img alt="Logo"src="https://cdnstatic.adoerp.com/static/ado-erp-logo.png" className="h-75px" />
						</a>
						<h2 class="text-white fw-normal m-0">Branding tools designed for your business</h2>
					</div>
				</div>
				<div class="d-flex flex-column-fluid flex-lg-row-auto justify-content-center justify-content-lg-end p-12 p-lg-20" >
					<div class="bg-body d-flex flex-column align-items-stretch flex-center rounded-4 w-md-600px p-20">
						<div class="d-flex flex-center flex-column flex-column-fluid px-lg-10 pb-15 pb-lg-20">
							<div class="form w-100">
								<div className="text-center mb-11">
									<h1 className="text-dark fw-bolder mb-3">Sign In</h1>
									<div className="text-gray-500 fw-semibold fs-6">Your Social Campaigns</div>
								</div>
								<div className="row g-3 mb-9">
									<div className="col-md-6">
										<a href="#" className="btn btn-flex btn-outline btn-text-gray-700 btn-active-color-primary bg-state-light flex-center text-nowrap w-100">
										<img alt="Logo" src="assets/media/svg/brand-logos/google-icon.svg" className="h-15px me-3" />Sign in with Google</a>
									</div>
									<div className="col-md-6">
										<a href="#" className="btn btn-flex btn-outline btn-text-gray-700 btn-active-color-primary bg-state-light flex-center text-nowrap w-100">
										<img alt="Logo" src="assets/media/svg/brand-logos/apple-black.svg" className="theme-light-show h-15px me-3" />
										<img alt="Logo" src="assets/media/svg/brand-logos/apple-black-dark.svg" className="theme-dark-show h-15px me-3" />Sign in with Apple</a>
									</div>
								</div>
								<div className="separator separator-content my-14">
									<span className="w-125px text-gray-500 fw-semibold fs-7">Or with email</span>
								</div>
								<div className="fv-row mb-8">
									<input type="text" placeholder="Email" name="email" id="email" autoComplete="off" className="form-control bg-transparent" />
								</div>
								<div className="position-relative mb-3">
									<input className="form-control bg-transparent" type={passwordType} placeholder="Password" name="password" id="password" autoComplete="off" />
									<span className="btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-n2" onClick={(e) => { togglePassword(e) }}>
										{passwordType==="password" ?
										<i className="bi bi-eye-slash fs-2"></i>
										:
										<i className="bi bi-eye fs-2"></i>}
									</span>
								</div>
								<div className="d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8">
									<div></div>
									<a href="/forgot-password-email" className="link-primary">Forgot Password ?</a>
								</div>
								<div className="d-grid mb-10">
									<div className="text-center">
										<ReCAPTCHA sitekey={`${process.env.REACT_APP_RECAPTCHA_SITE_KEY}`} onChange={onChange} className="btn btn-lg" size="compact" />
										<br />
										<button type="submit" onClick={(e) => {login(e)}} id="kt_sign_in_submit" className="btn btn-lg btn-primary w-100 mb-5">
											<span className="indicator-label">Continue</span>
											<span className="indicator-progress">Please wait...
											<span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
										</button>
									</div>
								</div>
								<div className="text-gray-500 text-center fw-semibold fs-6">Please sign in to continue 
								{/* <a href="/register" className="link-primary"> Sign up</a> */}
								</div> 
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		</>
	);
}
export default Signin;