import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import Select, { components } from 'react-select';
import { Link, Redirect } from 'react-router-dom'
import moment from 'moment';
import MUIDataTable from "mui-datatables";
import { MuiThemeProvider, createTheme } from '@material-ui/core';
import './date-range.css'
import { setCookie, getCookie } from '../common_component/cookie.js';
import Organization_modal from '../common_component/organization_modal.js';
import Timer_modal from '../common_component/timer_modal.js';
import { Modal, Button, Form } from 'react-bootstrap';


const BalanceData = () => {
    const [user, setUser] = useState({});
    // const [userRole, setUserRole] = useState("")
    const [organization_id, setOrganization_id] = useState("")
    const [organization_list, setorganization_list] = useState([]);
    const timer = useRef(0)
    const [clock_timer, setClockTimer] = useState(0);
    const [state, setstate] = useState(2);

    const timer_interval_ref = useRef();
    const [clockTimerModalShow, setClockTimerModalShow] = useState(false);
    const [state_org, setstate_org] = useState(2);
    const [organization_name, setOrganization_name] = useState("");
    const [OrganizationModalShow, setOrganizationModalShow] = useState(false);
    const [redirect_to_login, setRedirect_to_login] = useState(2);

    const [userTheme, setUserTheme] = useState((localStorage.getItem("kt_metronic_theme_mode_menu") == "system") ? window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light" : localStorage.getItem("kt_metronic_theme_mode_menu"))

    const [balanceSheetDataList, setBalanceSheetDataList] = useState([])
    const [listCount, setListCount] = useState(0)
    const [currentPage, setCurrentPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [sortColumn, setSortColumn] = useState(null)
    const [sortValue, setSortValue] = useState(null)
    const [searchValue, setSearchValue] = useState(null)
    // const [startDate, setStartDate] = useState(moment.utc().subtract(6, "days").format("YYYY-MM-DD"))
    // const [endDate, setEndDate] = useState(moment.utc().format("YYYY-MM-DD"))
    const [balanceSheetDataState, setBalanceSheetDataState] = useState(false)

    const [confirmationModalShow, setConfirmationModalShow] = useState(false)

    const [data, setData] = useState([])

    const [timezone_change, set_timezone_change] = useState("+00:00");

    const [visibility, setvisibility] = useState(0);
    const [redirect, setredirect] = useState(0);
    const [read, setread] = useState(2);
    const [write, setwrite] = useState(2);

    var page_name = "data-sheet-list";

    const loaderstyle = {
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: "auto",
        marginBottom: "auto",
    };

    const options = {
        filter: true,
        selectableRows: "none",
        fixedHeader: false,
        responsive: "standard",
        tableBodyHeight: "480px",
        tableBodyMaxHeight: "",
    };

    const columns = [

        {
            name: "dealer_name",
            label: "Dealer Name",
            options: {
                sort: false
            }
        },
        {
            name: "dealer_email",
            label: "Dealer Email",
            options: {
                sort: false
            }
        },
        {
            name: "brand_name",
            label: "Brand",
            options: {
                sort: false,
            }
        },
        {
            name: "recievable_amount",
            label: "Recievable Amount",
            options: {
                sort: false,
                customBodyRender: (value) => {
                    return (
                        <div className='text-center'>{value}</div>
                    );
                },
            }
        },        
        {
            name: "date_time",
            label: "Time & date",
            options: {
                sort: false,
            }
        },
        {
            name: "add_status",
            label: "Status",
            options: {
                sort: true,
                customBodyRender: (add_status, tableMeta, updateValue) => {
                    let c
                    if (add_status === "CONFIRMED") {
                        c = "green"
                    }
                    else if (add_status === "PENDING_CONFIRMATION") {
                        c = "orange"
                    }                   
                    else {
                        c = "red"
                    }

                    return (
                        <div style={{ color: c }} >
                            {add_status.replace("_CONFIRMATION", "").toLowerCase()
                                .replace(/_/g, ' ').replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())}
                        </div>
                    )
                }
            }
        },
        {
            name: "actions",
            label: "Actions",
            options: {
                filter: false,
                sort: false,
                download: false
            }
        }
    ];

    useEffect(() => {
        if (getCookie("access_token") != "") {
            if (getCookie("organization_id") != "") {
                setOrganization_id(getCookie("organization_id"));
            }
            else {
                var object = {};
                object.page_name = page_name;
                var headers = {
                    headers: {
                        "Content-Type": "application/json",
                        "token": getCookie("access_token"),
                        "Accept": "*/*"
                    }
                }

                axios.post(`${process.env.REACT_APP_SERVER_URL}/show-organization`, object, headers).then((res) => {
                    if (res.data.length == 1) {
                        setCookie('organization_id', res.data[0].organization_id, 1, true);
                        setOrganization_id(res.data[0].organization_id)
                    }
                    else {
                        setstate_org(1);
                        setorganization_list(res.data);
                        setOrganizationModalShow(true);
                    }
                })
            }
        }
        else {
            setRedirect_to_login(1);
        }
    }, [])

    useEffect(() => {
        setCookie("lastpage", window.location.href, 1, false)
        const validateJWT = () => {

            if (getCookie("access_token") != "") {
                var object = {};
                object.token = getCookie("access_token");

                var headers = {
                    headers: {
                        "Content-Type": "application/json",
                        "Accept": "*/*"
                    }
                }
                axios.post(`${process.env.REACT_APP_SERVER_URL}/get-user`, object, headers).then((res) => {

                    if (res.data.operation == "success") {
                        var user_object = {};
                        user_object.email = res.data.email;
                        user_object.name = res.data.name;
                        user_object.img = res.data.img;
                        setUser(user_object)
                    }
                    else {
                        setRedirect_to_login(1);
                    }
                })
                    .catch(function (error) {
                        setRedirect_to_login(1);
                    });

                object.organization_id = organization_id;
                axios.post(`${process.env.REACT_APP_SERVER_URL}/audience-info`, object, headers).then((res) => {

                    if (res.data.operation == "success") {
                        var token = res.data.audience_license;
                        if (res.data.organization_flag == 0) {
                            setstate_org(3);
                            setOrganization_name(res.data.organization_name);
                            setOrganizationModalShow(true);
                        }
                        else {
                            var path = window.location.pathname;
                            path = path.replace(/\/$/, "");
                            path = decodeURIComponent(path);
                            var original_url = path.split("/")[1]
                            let index = 0;
                            let temp = -1;
                            for (let i = 0; i < res.data.user_access.access.length; i++) {
                                if (temp == 1) break;
                                let component = res.data.user_access.access[i].inside_components;
                                for (let j = 0; j < component.length; j++) {
                                    if (temp == 1) break;
                                    if (component[j].hasOwnProperty(original_url)) {
                                        index = i;
                                        if (component[j][original_url] == 1 && res.data.user_access.access[index].visibility == 1) {
                                            temp = 1;
                                            setvisibility(1);
                                            setread(component[j]["read"])
                                            setwrite(component[j]["write"])
                                            // setwrite(0)
                                            // break;
                                        }
                                        else {
                                            setredirect(1);
                                        }
                                    }
                                    else if (component[j].hasOwnProperty("key")) {
                                        let sub_inside_components = component[j].inside_components
                                        for (let k = 0; k < sub_inside_components.length; k++) {
                                            if (sub_inside_components[k].hasOwnProperty(original_url)) {
                                                index = i
                                                if (sub_inside_components[k][original_url] == 1 && res.data.user_access.access[index].visibility == 1 && component[j].visibility == 1) {
                                                    temp = 1;
                                                    setvisibility(1);
                                                    setread(sub_inside_components[k]["read"])
                                                    setwrite(sub_inside_components[k]["write"])
                                                    // setwrite(0)
                                                    break;
                                                }
                                                else {
                                                    setredirect(1);
                                                }
                                            }
                                        }
                                    }
                                    else {
                                        temp = 2;
                                    }
                                }
                            }

                            if (temp == 2) {
                                setredirect(1);
                            }

                        }
                        if (!token.includes("ufhdsupw29fher") || !token.includes("pdbwu27dh6schs") || !token.includes("0dh27agiqbcrwi")) {
                            setCookie('access_token', res.data.audience_license, 1, true)
                            timer.current = process.env.REACT_APP_JWT_EXPIRY
                        }
                        else {
                            timer.current = res.data.audience_count
                        }
                    }
                    else {
                        setRedirect_to_login(1);
                    }
                })
                    .catch(function (error) {
                        console.log(error);
                        setRedirect_to_login(1);
                    });
            }
            else {
                setRedirect_to_login(1);
            }
        }
        organization_id != "" && validateJWT();
    }, [organization_id])

    useEffect(() => {
        timer_interval_ref.current = setInterval(() => {
            // console.log(timer)

            timer.current = timer.current - 1
            if (timer.current > 0 && timer.current < process.env.REACT_APP_JWT_EXPIRY_NOTICE) {
                if (!clockTimerModalShow) {
                    setClockTimer(process.env.REACT_APP_JWT_EXPIRY_NOTICE)
                    setClockTimerModalShow(true)
                }
            }
        }, 1000);

        return () => { clearInterval(timer_interval_ref) };
    }, []);

    async function getBalanceSheetDataList(cp, rpp, sort_c, sort_v, search_v) {
        setBalanceSheetDataState(true)
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/get-user-balance-data`, { method: 'POST', body: JSON.stringify({ "email": user.email, "current_page": cp, "rows_per_page": rpp, "sort_column": sort_c, "sort_value": sort_v, "search_value": search_v, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        // console.log(body)

        if (body.operation == "success") {
            setBalanceSheetDataList(body.data.list);
            setListCount(body.data.count)

            setBalanceSheetDataState(false)
        }
        else {
            throw new Error(body.message, { cause: "api error" })
        }
    }

    async function getTimezoneInformation() {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/get-organization-timezone-information`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        if (body.operation === "success") {
            if (body.data.length > 0) {
                if (body.data[0].timezone_value != "" && body.data[0].timezone_value != null) {
                    const timezone = body.data[0].timezone_value;
                    set_timezone_change(timezone);
                }
            }
        }
        else {
            throw new Error("timezone api error", { cause: "api error" })
        }
    }

    useEffect(() => {
        if ((read || write) && (user.email && visibility)) {
            let p1 = getTimezoneInformation()
            let p2 = getBalanceSheetDataList(currentPage, rowsPerPage, sortColumn, sortValue, searchValue);

            Promise.all([p1, p2])
                .then(() => {
                    setstate(1)
                    console.log("all apis done")
                })
                .catch((errx) => {
                    console.log(errx)
                    setstate(3)
                })
        }
    }, [user, visibility, read, write])

    useEffect(() => {
        if (state == 1) {
            getBalanceSheetDataList(currentPage, rowsPerPage, sortColumn, sortValue, searchValue)
        }
    }, [currentPage, rowsPerPage, sortColumn, sortValue, searchValue])

    useEffect(() => {

        let temp_data = []

        balanceSheetDataList.forEach((obj) => {
            let temp_obj = {};

            temp_obj.brand_name = obj.brand_name
            temp_obj.dealer_name = obj.dealer_name
            temp_obj.dealer_email = obj.dealer_email
            temp_obj.recievable_amount = obj.recievable_amount
            temp_obj.date_time = moment(obj.timestamp).format("ll")
            temp_obj.add_status = obj.status

            let id = obj.balance_sheet_data_uid

            temp_obj.actions = <div className="dropdown">
                <button onClick={(e) => { e.stopPropagation() }} className="btn btn-sm btn-secondary rounded" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false"><i className="fas fa-bars px-0"></i></button>
                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                    <li className="py-1"> <Link className="dropdown-item" to={`/balance-details/${id}`}>View details</Link> </li>
                </ul>
            </div>

            temp_data.push(temp_obj)
        })
        setData(temp_data);

    }, [balanceSheetDataList])

    useEffect(() => {
        let id = setInterval(() => {
            setUserTheme((localStorage.getItem("kt_metronic_theme_mode_menu") == "system") ? window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light" : localStorage.getItem("kt_metronic_theme_mode_menu"))
        }, 10)

        return () => {
            clearInterval(id)
        }
    }, [])

    return (
        <>
            {
                (redirect == 1 || (read == 0 && write == 0)) ?
                    <>
                        <Redirect to={{
                            pathname: "/unauthorized",
                        }}
                        />
                    </> :
                    null
            }
            {
                redirect_to_login == 0 ? null : (redirect_to_login == 1 ? <>
                    <Redirect to={{ pathname: "/login" }} />
                </> : null)
            }

            <div className="d-flex flex-column flex-column-fluid">
                <div className="app-toolbar py-3 py-lg-6" id="kt_app_toolbar">
                    <div id="kt_app_toolbar_container" className="app-container container-xxl d-flex flex-stack">
                        <h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">Dealer Balance Data</h1>
                    </div>
                </div>
                <div id="kt_app_content" className="app-content flex-column-fluid">
                    <div id="kt_app_content_container" className="app-container container-xxl">

                        <div className="card">
                            <div className="card-body pt-6">
                                {
                                    state == 2 ?
                                        <img src="/images/loader-06.svg" alt="" style={loaderstyle} />
                                        :
                                        state == 1 ?
                                            <>
                                                <MuiThemeProvider theme={createTheme({ palette: { type: userTheme } })}>
                                                    <MUIDataTable
                                                        title={
                                                            <>
                                                                {/* <span className='me-20'>Balance Data</span> */}
                                                                {
                                                                    balanceSheetDataState &&
                                                                    <>
                                                                        <span>Loading...</span>
                                                                        <svg width="50" height="15" viewBox="0 45 100 10">
                                                                            <g transform="translate(20 50)"><circle cx="0" cy="0" r="6" fill="#e15b64"><animateTransform attributeName="transform" type="scale" begin="-0.375s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite"></animateTransform></circle></g>
                                                                            <g transform="translate(40 50)"><circle cx="0" cy="0" r="6" fill="#f8b26a"><animateTransform attributeName="transform" type="scale" begin="-0.25s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite"></animateTransform></circle></g>
                                                                            <g transform="translate(60 50)"><circle cx="0" cy="0" r="6" fill="#abbd81"><animateTransform attributeName="transform" type="scale" begin="-0.125s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite"></animateTransform></circle></g>
                                                                            <g transform="translate(80 50)"><circle cx="0" cy="0" r="6" fill="#81a3bd"><animateTransform attributeName="transform" type="scale" begin="0s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite"></animateTransform></circle></g>
                                                                        </svg>
                                                                    </>
                                                                }
                                                            </>
                                                        }
                                                        data={data}
                                                        columns={columns}
                                                        options={{
                                                            ...options,
                                                            download: false,
                                                            filter: false,
                                                            searchPlaceholder: 'Search Invoices',
                                                            serverSide: true,
                                                            count: listCount,
                                                            jumpToPage: true,
                                                            onChangePage: (val) => { setCurrentPage(val) },
                                                            onChangeRowsPerPage: (val) => { setCurrentPage(0); setRowsPerPage(val) },
                                                            onColumnSortChange: (col, dir) => { setSortColumn(col); setSortValue(dir) },
                                                            onSearchChange: (val) => { setCurrentPage(0); setSearchValue(val) },
                                                            // onTableChange: (action, tableState)=>{console.log(action, tableState);}
                                                        }}
                                                    />
                                                </MuiThemeProvider>
                                            </>
                                            // :
                                            // state == 3 ?
                                            //     <>
                                            //         <div className="notice bg-light-primary rounded border-primary border border-dashed rounded-3 p-6">
                                            //             <h4 className="text-gray-800 fw-bolder">Create a new Dealer Invoice. All added invoices can be viewed from here</h4>
                                            //             <div className='mt-10 text-center'>
                                            //                 <Link className='btn btn-success rounded' to={`/create-dealer-invoice`}>Create an Invoice</Link>
                                            //             </div>
                                            //         </div>
                                            //     </>
                                            :
                                            <>
                                                <div className="notice bg-light-danger rounded border-primary border border-dashed rounded-3 p-6">
                                                    <h4 className="text-gray-800 fw-bolder">Something went wrong</h4>
                                                    <div className='mt-10 text-center'>
                                                        <button className='btn btn-success rounded' onClick={() => { window.location.reload() }}>Reload the page</button>
                                                    </div>
                                                </div>
                                            </>
                                }
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <Organization_modal OrganizationModalShow={OrganizationModalShow} setOrganizationModalShow1={setOrganizationModalShow} organization_name={organization_name} organization_list={organization_list} organization_id={organization_id} state_org={state_org} />
            <Timer_modal clockTimerModalShow={clockTimerModalShow} setClockTimerModalShow1={setClockTimerModalShow} clock_timer={clock_timer} organization_id={organization_id} timer_ref={timer} />
        </>
    )
};

export default BalanceData;
