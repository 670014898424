import React, { lazy, Suspense, useState, useEffect,useRef } from 'react';
import ReactDOM from 'react-dom';
//import process.env from '../config.json';
import AsideNavbar from './aside-navbar.js';
import Header from './header.js';
import Footer from './footer.js';
import axios from 'axios';
import swal from 'sweetalert';
import $ from 'jquery';
import moment from 'moment';
import { Modal } from 'react-bootstrap';
import { Link,Redirect } from 'react-router-dom';
import CryptoJS from 'crypto-js'

import country_arr from './common_arr/countries-arr.js';
import s_a from './states-arr.js';
import currency_arr from './currency-arr.js';
import Select from 'react-select';
import Grid from "@material-ui/core/Grid";
import DateFnsUtils from '@date-io/date-fns';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";

import {setCookie,getCookie} from '../common_component/cookie.js';
import Organization_modal from '../common_component/organization_modal.js';
import Timer_modal from '../common_component/timer_modal.js';

export const GstSettings = () => {

	const timer_interval_ref = useRef();
	const [clockTimerModalShow, setClockTimerModalShow] = useState(false);
    const [state_org, setstate_org] = useState(2);
	const [organization_name, setOrganization_name] = useState("");
	const [OrganizationModalShow, setOrganizationModalShow] = useState(false);
	const [redirect_to_login, setRedirect_to_login] = useState(2);

	const [GSTModalShow, setGSTModalShow] = useState(false);
	const [DefaultTaxModalShow, setDefaultTaxModalShow] = useState(false);
	const [OnlineFilingModalShow, setOnlineFilingModalShow] = useState(false);
	const [AccountSelectionList, setAccountSelectionList] = useState([]);

	const [Registered_date, setRegistered_date] = useState("");
	const [gst_id, setgst_id] = useState("");
	const [gst_status, setgst_status] = useState("");
	const [LegalName, setLegalName] = useState("");
	const [TradeName, setTradeName] = useState("");
	const [GstIN, setGstIN] = useState("");
	const [Reverse_charge, setReverse_charge] = useState(false);
	const [Import, setImport] = useState(false);
	const [Digital_service, setDigital_service] = useState(false);
	const [Tracking_account, setTracking_account] = useState("");
	const [Online_filing_settings, setOnline_filing_settings] = useState({"GstIN_Username":"","Reporting_Period":"","Return_form":"","Gst_Filing_Approval":"",UserType:"",UserValue:[]});
	const [Default_tax_preference, setDefault_tax_preference] = useState({"Inter_State_Tax":"","Intra_State_Tax":""});
	const [ReportingPeriodlist, setReportingPeriodlist] = useState([{ label: "Monthly", value: "Monthly" },{ label: "Quaterly", value: "Quaterly" }]);
	const [UserTypelist, setUserTypelist] = useState([{ label: "Individual User", value: "Individual User" },{ label: "Group User", value: "Group User" }]);
	const [UserValuelist, setUserValuelist] = useState([]);

	const [accessToken, setaccessToken] = useState('');
	const [state, setstate] = useState(2);
	const [loading, setloading] = useState(true);
	const [user, setUser] = useState({});
	const [username, setUsername] = useState("");
	const [user_id, setUser_id] = useState("");
	const [Organization_id, setOrganization_id] = useState("");
	const [GstDetails, setGstDetails] = useState([]);

    const [organization_list, setorganization_list] = useState([]);
	const [Userlist, setUserlist] = useState([]);
	const [RoleList, setRoleList] = useState([]);
	
	const [gstEwayBillConfigStatusList, setGstEwayBillConfigStatusList] = useState([])

	const [mainSubmitButtonState, setMainSubmitButtonState] = useState(false)

	const [TaxGrouplist, setTaxGrouplist] = useState([{ label: "Agriculture", value: "Agriculture" }, { label: "Construction", value: "Construction" },{ label: "Education", value: "Education" },{ label: "Engineering", value: "Engineering" },{ label: "Entertainment", value: "Entertainment" }])
    const [Taxlist, setTaxlist] = useState([{ label: "English", value: "English" }]);
	const [selectedDate, setSelectedDate] = useState(new Date("2014-08-18T21:11:54"));

	const [ewayBillConfigModalShow, setEwayBillConfigModalShow] = useState(false)
	const [configUsername, setConfigUsername] = useState("")
    const [configPassword, setConfigPassword] = useState("")
    const [configGstinUid, setConfigGstinUid] = useState("")
    const [configGstinNumber, setConfigGstinNumber] = useState("")
	const [ewayBillConfigSubmitButtonState, setEwayBillConfigSubmitButtonState] = useState(false)

	const [visibility, setvisibility] = useState(0);
    const [redirect, setredirect] = useState(0);
    const [Selected_organization_id, setSelected_organization_id] = useState("");
    const [read, setread] = useState(2);
    const [write, setwrite] = useState(2);
	const [userTheme,setUserTheme]=useState((localStorage.getItem("kt_metronic_theme_mode_menu")=="system")?window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light":localStorage.getItem("kt_metronic_theme_mode_menu"))

	const [timer, setTimer] = useState(0);
	const [clock_timer, setClockTimer] = useState(0);

	var page_name = "gst-settings";

	const loaderstyle = {
		display: "block",
		marginLeft: "auto",
		marginRight: "auto",
		marginTop: "auto",
		marginBottom: "auto",
	};

	let dropdownStyleDark = {
        option: (provided, state) => ({
            ...provided,
            color: '#dddddf',
            backgroundColor: state.isSelected?provided.backgroundColor: state.isFocused ? '#5f5f8d':provided.backgroundColor ,
        }),
        control: (provided, state) => ({
            ...provided,
            backgroundColor: "#2b2b40",
            borderColor:"#4c4c71"
        }),
        placeholder: (provided, state) => ({
            ...provided,
            color: "#dddddf",
        }),
        singleValue: (provided, state) => ({
            ...provided,
            color: "#dddddf",
        }),
        menu: (provided, state) => ({
            ...provided,
            backgroundColor: "#2b2b40",
        })
    }

	useEffect(() => {
        let id = setInterval(()=>{
          setUserTheme((localStorage.getItem("kt_metronic_theme_mode_menu")=="system")?window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light":localStorage.getItem("kt_metronic_theme_mode_menu"))
        },10)
      
        return () => {
          clearInterval(id)
        }
    }, [])

	useEffect(() => {
        if (getCookie("access_token") != "") {
            if(getCookie("organization_id")!="")
            {
                setOrganization_id(getCookie("organization_id"));  
            }
            else
            {
                var object = {};
                object.page_name=page_name;
                var headers = {
                    headers: {
                        "Content-Type": "application/json",
                        "token": getCookie("access_token"),
                        "Accept": "*/*"
                    }
                }

                axios.post(`${process.env.REACT_APP_SERVER_URL}/show-default-organization`, object, headers).then((res) => {

                    if (res.data.length>0) {
                        setCookie('organization_id', res.data[0].organization_id, 1,true);
                        setOrganization_id(res.data[0].organization_id)
                    }
                
                })    
            }
        }
        else {
            setRedirect_to_login(1);
        }
    }, [])

	useEffect(() => {
		setCookie("lastpage", window.location.href, 1,false)
		const validateJWT = () => {

			if (getCookie("access_token") != "") {
				var object = {};
				object.token = getCookie("access_token");

				var headers = {
					headers: {
						"Content-Type": "application/json",
						"Accept": "*/*"
					}
				}
				axios.post(`${process.env.REACT_APP_SERVER_URL}/get-user`, object, headers).then((res) => {

					if (res.data.operation == "success") {
						var user_object = {};
						user_object.email = res.data.email;
						user_object.name = res.data.name;
						user_object.img = res.data.img;
						setUser_id(user_object.email)
						setUser(user_object)
						setUsername(res.data.name)
					}
					else {
						swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
							window.location.href = "/login"
						});
					}
				})
					.catch(function (error) {
						swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
							window.location.href = "/login"
						});
					});
				object.organization_id=Organization_id;
				axios.post(`${process.env.REACT_APP_SERVER_URL}/audience-info`, object, headers).then((res) => {

					if (res.data.operation == "success") {
						var token = res.data.audience_license;
						if (res.data.organization_flag == 0) {
                            setstate_org(3);
							setOrganization_name(res.data.organization_name);
							setOrganizationModalShow(true);
                        }
                        else {

                            var path = window.location.pathname;
                            path = path.replace(/\/$/, "");
                            path = decodeURIComponent(path);
                            var original_url = path.split("/")[1]
                            let index = 0;
							let temp = -1;
                            for (let i = 0; i < res.data.user_access.access.length; i++) 
							{
								if(temp == 1) break;
                                let component = res.data.user_access.access[i].inside_components;
                                for (let j = 0; j < component.length; j++) {
                                    if (component[j].hasOwnProperty(original_url)) {
                                        index = i;
                                        if (res.data.user_access.access[index].inside_components[j][original_url] == 1 && res.data.user_access.access[index].visibility == 1) {
											temp = 1;
                                            setvisibility(1);
                                            setread(res.data.user_access.access[index].inside_components[j]["read"])
                                            setwrite(res.data.user_access.access[index].inside_components[j]["write"])
                                            // setwrite(0)
                                            break;
                                        }
                                        else {
                                            setredirect(1);
                                        }

                                    }
									else if(component[j].hasOwnProperty("key")){
                                        let sub_inside_components= component[j].inside_components
                                        for (let k = 0; k < sub_inside_components.length; k++) {
                                            if(sub_inside_components[k].hasOwnProperty(original_url)){
                                                index=i
                                                if (sub_inside_components[k][original_url] == 1 && res.data.user_access.access[index].visibility == 1 && component[j].visibility == 1) {
                                                    temp = 1;
                                                    setvisibility(1);
                                                    setread(sub_inside_components[k]["read"])
                                                    setwrite(sub_inside_components[k]["write"])
                                                    // setwrite(0)
                                                    break;
                                                    
                                                }
                                                else {
                                                    setredirect(1);
                                                }
                                            }
                                        }
                                    }
									else
									{
										temp = 2;
									}
                                }
                            }
							
							if(temp == 2)
                            {
                                setredirect(1);
                            }
                        }

						if (!token.includes("ufhdsupw29fher") || !token.includes("pdbwu27dh6schs") || !token.includes("0dh27agiqbcrwi")) {
							setCookie('access_token', res.data.audience_license, 1,true)
							setTimer(process.env.REACT_APP_JWT_EXPIRY);
							//setClockTimer(process.env.REACT_APP_JWT_EXPIRY);
						}
						else {
							setTimer(res.data.audience_count)
							//setClockTimer(res.data.audience_count)
						}
					}
					else {
						setRedirect_to_login(1);
					}
				})
					.catch(function (error) {
						console.log(error);
						setRedirect_to_login(1);
					});
			}
			else {
				setRedirect_to_login(1);
			}
		}
		Organization_id!="" && validateJWT();
		
	}, [Organization_id])

	useEffect(() => {
        timer_interval_ref.current = setInterval(() => {
            setTimer((timer)=>{
                if (timer > 0 && timer < process.env.REACT_APP_JWT_EXPIRY_NOTICE) {
                    if (!clockTimerModalShow) {
                        setClockTimer(process.env.REACT_APP_JWT_EXPIRY_NOTICE)
                        setClockTimerModalShow(true)
                    }
                }
                return timer - 1
            })
        }, 1000);
        
        return () => {clearInterval(timer_interval_ref)};
    }, []);


	async function getgstdetails() {
		var object = {};
		object.email = user.email;

		const response = await fetch(
			`${process.env.REACT_APP_SERVER_URL}/get-gst-details`, { method: 'POST', body: JSON.stringify({ "email": user.email,"organization_id": Organization_id, "page_name": page_name }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
		);
		const body = await response.json();

		if (body.data.length > 0) {
			setGstDetails(body.data)  
		}
	}

	async function getuserdetails() {
		var object = {};
		object.email = user.email;

		const response = await fetch(
			`${process.env.REACT_APP_SERVER_URL}/user-datatable`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name,"organization_id":Organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
		);
		const body = await response.json();
	
		if (body.length > 0) {
			var temp_dropdown = [];
			body.map((obj) => {
				if (obj.log_status === "Active") {
					var temp_obj = {};
					temp_obj.label = obj.name;
					temp_obj.value = obj.user_id;
					temp_dropdown.push(temp_obj);
				}
			})
			console.log(temp_dropdown);
			setUserlist(temp_dropdown);
		}
	}

	async function getroledetails() {
		var object = {};
		object.email = user.email;

		const response = await fetch(
			`${process.env.REACT_APP_SERVER_URL}/get-roles`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name,"organization_id":Organization_id,"service":process.env.module_name}), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
		);
		const body = await response.json();
		if (body.length > 0) {
			var temp_dropdown = [];
			body.map((obj) => {
				if (obj.log_status === "Active") {
					var temp_obj = {};
					temp_obj.label = obj.user_role;
					//temp_obj.value = obj.user_role_id;
					temp_obj.value = obj.user_role;
					temp_dropdown.push(temp_obj);
				}
			})
			console.log(temp_dropdown);
			setRoleList(temp_dropdown);
		}
	}

	async function gettaxdetails() {
		var object = {};
		object.email = user.email;

		const response = await fetch(
			`${process.env.REACT_APP_SERVER_URL}/get-regular-tax`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name,"organization_id":Organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
		);
		const body = await response.json();
		var temp_dropdown = [];
		if (body.operation=="success") {
			if(body.data.length>0)
			{
				body.data.map((obj) => {
					var temp_obj = {};
					temp_obj.label = obj.tax_name;
					temp_obj.value = obj.tax_id;
					temp_dropdown.push(temp_obj);
				})
				setTaxlist(temp_dropdown); 
			}
		}
	}

	async function gettaxgroupdetails() {
		var object = {};
		object.email = user.email;

		const response = await fetch(
			`${process.env.REACT_APP_SERVER_URL}/tax-group-datatable`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name,"organization_id":Organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
		);
		const body = await response.json();
		var temp_dropdown = [];
		if (body.operation=="success") {
			if(body.data.length>0)
			{
				body.data.map((obj) => {
					var temp_obj = {};
					temp_obj.label = obj.tax_group_name;
					temp_obj.value = obj.tax_group_id;
					temp_dropdown.push(temp_obj);
				})
				setTaxGrouplist(temp_dropdown); 
			}  
		}
	}

	async function selectiveaccounthead() {
        let arr=[
            {"category_name":"Expense","headArray":[]},
        ]
        const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/selective-account-head`, { method: 'POST', body: JSON.stringify({"organization_id":Organization_id,"headRequest":arr,"email": user.email, "page_name": page_name }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") }
                }
            );
            const body = await response.json();
            //console.log(body)
            if(body.operation==="success"){
                let temp_dropdown=[];
                console.log(body.data);
                body.data.map((obj) => {
                    var temp_obj = {};
                    temp_obj.label=obj.category_name;
                    let temp_dropdown1=[];
                    obj.headArray.map((obj1) => {
                        var temp_obj1 = {};
                        temp_obj1.label = obj1.name;
                        temp_obj1.value = obj1.hid;
                        /*if(obj1.name==="Cost of Goods Sold")
                        {
                            setTracking_account({label:obj1.name,value:obj1.hid});
                        }*/
                        temp_dropdown1.push(temp_obj1);
                    })
                    temp_obj.options=temp_dropdown1;
                    temp_dropdown.push(temp_obj);
                }) 
                setAccountSelectionList(temp_dropdown);
            }
    }

	async function getGstEwayBillConfigStatus() {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/get-e-way-bill-config-status-list`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "organization_id": Organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        // console.log(body)
        if (body.length != 0) {
            setGstEwayBillConfigStatusList(body.data)
        }
    }

	useEffect(() => {
        if((read || write) &&(user.email && visibility))
        {
            let p1 = getuserdetails();
            let p2 = getroledetails();
            let p3 = gettaxdetails();
            let p4 = gettaxgroupdetails();
            let p5 = selectiveaccounthead();
			let p6 = getgstdetails();
			let p7 = getGstEwayBillConfigStatus();
            Promise.all([p1,p2.p3,p4,p5,p6,p7])
            .then(()=>{
                setstate(1);
                console.log("all apis done")
            })
            .catch((err)=>{
                setstate(0);
                console.log(err)
            })
        }
    }, [user, visibility, read, write]);

	const handleDateChange = (date) => {
		console.log(date)
		setSelectedDate(date);
	  };

	const submitGstDetails = (e) => {
		e.preventDefault();

		var values = {"page_name":page_name,"id":gst_id,"GstIN":GstIN,"LegalName":LegalName,"TradeName": TradeName,"Registered_date":Registered_date,"Reverse_charge":Reverse_charge,"Digital_service":Digital_service,"Import":Import,"Tracking_account":Tracking_account,"Online_filing_settings":Online_filing_settings,"Default_tax_preference":Default_tax_preference,"organization_id":Organization_id};
		
		var headers = {
			headers: {
				"Content-Type": "application/json",
				"Accept": "*/*",
				"token": getCookie("access_token")
			}
		}
		var reg = /^([0-9]{2}[a-zA-Z]{4}([a-zA-Z]{1}|[0-9]{1})[0-9]{4}[a-zA-Z]{1}([a-zA-Z]|[0-9]){3}){0,15}$/;
		if (!GstIN.match(reg)) {
		swal("Warning!", "Incorrect format for GstIn!", "Warning").then((value) => {
			
		});
		return false;
		
		}
		  setMainSubmitButtonState(true); 
		if(gst_id=="")
		{
			axios.post(`${process.env.REACT_APP_SERVER_URL}/save-gst-details`, values, headers).then((res) => {
				setMainSubmitButtonState(false)
				if (res.data && res.data.operation == "success") {
					swal("Great!", "Gst details saved successfully!", "success").then((value) => {
						window.location.reload();
					});
				}
				else {
					swal("Oops!", "Something went wrong!", "error");
				}
			});
		}
		else
		{
			axios.post(`${process.env.REACT_APP_SERVER_URL}/update-gst-details`, values, headers).then((res) => {
				setMainSubmitButtonState(false)
				if (res.data && res.data.operation == "success") {
					swal("Great!", "Gst details updated successfully!", "success").then((value) => {
						window.location.reload();
					});
				}
				else {
					swal("Oops!", "Something went wrong!", "error");
				}
			});
		}
	}

	const submitStatus = (unique_id,add_status) => {
		var id = id;
		setstate(2);
		var values = { "id": unique_id,"add_status":add_status,"organization_id":Organization_id,"page_name": page_name };
		setMainSubmitButtonState(true)
		var headers = {
			headers: {
				"Content-Type": "application/json",
				"Accept": "*/*",
				"token": getCookie("access_token")
			}
		}

		axios.post(`${process.env.REACT_APP_SERVER_URL}/update-gst-status`, values, headers).then((res) => {
			setMainSubmitButtonState(false)
			if (res.data && res.data.operation == "success") {
				window.location.reload();
				swal("Great!", "Status changed successfully!", "success").then((value) => {
					window.location.reload();
				});
			}
			else {
				swal("Oops!", "Something went wrong!", "error");
			}
		});
	}
	const EditGstDetails = (id) => {
        for (let i = 0; i < GstDetails.length; i++) {
            if (id == GstDetails[i].unique_id) {
                setgst_id(id);
                setGstIN(GstDetails[i].GstIn);
                setRegistered_date(moment(GstDetails[i].Registered_date).format("yyyy-MM-DD"));
                setLegalName(GstDetails[i].legal_name);
                setTradeName(GstDetails[i].trade_name);
				console.log(GstDetails[i].Digital_service)
				if(GstDetails[i].Digital_service==1)
				{
					setDigital_service(true);
				}
                if(GstDetails[i].Reverse_Charge==1)
				{
					setReverse_charge(true); 
				}
				if(GstDetails[i].Import==1)
				{
					setImport(true);
				}
				if(GstDetails[i].Default_tax_preferences!=="")
				{
					setDefault_tax_preference(JSON.parse(GstDetails[i].Default_tax_preferences));
				}
                if(GstDetails[i].online_filing_settings!=="")
				{
					setOnline_filing_settings(JSON.parse(GstDetails[i].online_filing_settings));
				}
                setTracking_account(GstDetails[i].Tracking_account);
				
            }
        }
    }

	const CreateGstDetails = (id) => {
		setgst_id("");
		setGstIN("");
		setRegistered_date("");
		setLegalName("");
		setTradeName("");
		setDigital_service(false);
		setReverse_charge(false); 
		setImport(false);
		setDefault_tax_preference({});
		setOnline_filing_settings({});
		setTracking_account("");
    }

	const UserValueLoad = (value) => {
		if(value==="Individual User")
		{
			setUserValuelist(Userlist);
		}
		else
		{
			setUserValuelist(RoleList);
		}
	}

	const ValueLoad = (value) => {
		let json=Online_filing_settings;
		json.UserType="Individual User";
		setUserValuelist(Userlist);
		setOnline_filing_settings(json);
	}

	const eWayBillConfigSubmit = () => {

        if(configUsername == ""){
            swal("Alert!","Username cannot be empty","warning")
            return
        }
        if(configPassword == ""){
            swal("Alert!","Password cannot be empty","warning")
            return
        }

		// let encrypted = CryptoJS.AES.encrypt(configPassword, process.env.REACT_APP_GSTIN_CONFIG_KEY).toString();
		// let decrypted = (CryptoJS.AES.decrypt(encrypted, process.env.REACT_APP_GSTIN_CONFIG_KEY)).toString(CryptoJS.enc.Utf8);

		// console.log(encrypted,decrypted)

        var object = {}

        object.username = configUsername
        object.password = CryptoJS.AES.encrypt(configPassword, process.env.REACT_APP_GSTIN_CONFIG_KEY).toString()
        object.gstin_uid = configGstinUid
        object.gstin_number = configGstinNumber

        object.organization_id = Organization_id
        object.page_name = page_name;

        // console.log(object);
        setEwayBillConfigSubmitButtonState(true)

        var headers = {
            headers: {
                "Content-Type": "application/json",
                "Accept": "*/*",
                "token": getCookie("access_token")
            }
        }
        axios.post(`${process.env.REACT_APP_SERVER_URL}/add-e-way-bill-config`, object, headers).then((res) => {
            setEwayBillConfigSubmitButtonState(false)
            if (res.data.operation === "success") {
                swal("Great!", `E Way Bill configuration saved successfully!`, "success")
				handleEwayBillConfigModalClose()
				getGstEwayBillConfigStatus()
            }
            else {
                swal("Oops!", res.data.message, "error");
            }
        })
        .catch(function (error) {
            console.log(error)
        });
    }

	const handleEwayBillConfigModalClose = () => {
		setEwayBillConfigModalShow(false)

		setConfigUsername("")
		setConfigPassword("")
		setConfigGstinUid("")
		setConfigGstinNumber("")
		setEwayBillConfigSubmitButtonState(false)
	}

	return (
		<>

			{
                (redirect == 1 || (read == 0 && write == 0)) ?
                    <>
                        <Redirect to={{
                            pathname: "/unauthorized",
                        }}
                        />
                    </> :
                    null
            }
			
				<div className="d-flex flex-column flex-column-fluid">
					<div className="app-toolbar py-3 py-lg-6" id="kt_app_toolbar">
						<div id="kt_app_toolbar_container" className="app-container container-xxl d-flex flex-stack">
							<h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">GSTIN Settings
								
								<small className="text-muted fs-7 fw-bold my-1 ms-1"></small>
							</h1>
							<div className="d-flex align-items-center gap-2 gap-lg-3">
								<a onClick={(e) => { CreateGstDetails();setGSTModalShow(true); }} className="btn btn-sm fw-bold btn-primary">New GSTIN</a>
							</div>
						</div>
					</div>
					<div id="kt_app_content" className="app-content flex-column-fluid">
						<div id="kt_app_content_container" className="app-container container-xxl">
					{state == 1 ?
						<>
						<div className="row g-6 g-xl-9">
							{GstDetails.length > 0 && GstDetails.map((obj, index) => {
								return (
									<>
										<div className="col-lg-6">
											<div className="card card-flush h-lg-100">
												<div className="card-header mt-6">
													<div className="card-title flex-column">
														<h3 className="fw-bolder mb-1">{obj.GstIn}</h3>
														{obj.add_status == "Active" ? <span className="badge badge-light-success me-auto rounded">Active</span> : (obj.add_status == "Stopped" ? <span className="badge badge-light-danger me-auto rounded">Stopped</span> : <span className="badge badge-light-danger me-auto rounded">Deactivated</span>)}
													</div>
													<div className="card-toolbar">
														<div className="dropdown">
															{
																(write) ?
																<>
																	<button className="btn btn-sm btn-secondary rounded" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false"><i className="fas fa-bars px-0"></i></button>
																	<ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
																		<li className="py-1"><a onClick={(e) => { EditGstDetails(obj.unique_id);setGSTModalShow(true); }}  className="dropdown-item">Edit Details</a></li>
																		{obj.add_status=="Active" ? <li className="py-1"><a onClick={(e) => {setgst_id(obj.unique_id);setgst_status(obj.add_status);}} data-bs-toggle="modal" data-bs-target="#ChangeStatus" className="dropdown-item">Marks as inactive</a></li>:<li><a onClick={(e) => { submitStatus(obj.unique_id,obj.add_status) }} className="dropdown-item">Marks as inactive</a></li>}
																		<li className="py-1"><a onClick={(e) => { EditGstDetails(obj.unique_id);setOnlineFilingModalShow(true); }} className="dropdown-item">Online filing settings</a></li>
																		<li className="py-1"><a onClick={(e) => { EditGstDetails(obj.unique_id); setDefaultTaxModalShow(true); }}  className="dropdown-item">Default tax preference</a></li>
																		{
																			gstEwayBillConfigStatusList.find(x=>x.gstin_uid==obj.unique_id)==undefined?
																			<li className="py-1"><a onClick={(e) => { setEwayBillConfigModalShow(true); setConfigGstinUid(obj.unique_id); setConfigGstinNumber(obj.GstIn); }} className="dropdown-item">Add E Way Bill Configuration</a></li>
																			:gstEwayBillConfigStatusList.find(x=>x.gstin_uid==obj.unique_id).status == "PENDING_VERIFICATION"?
																			<li className="py-1"><a onClick={(e) => { swal("Alert!","This feature is currently in progress","warning") }} className="dropdown-item">Verify E Way Bill Configuration</a></li>
																			:null																			
																		}
																	</ul>
																</>:null
															}
														</div>
													</div>	
												</div>
												<div className="d-flex flex-wrap">
													<div className="card-body p-9">
														<div className="fs-6 d-flex justify-content-between my-4">
															<div className="fw-bold">Business Legal Name</div>
															<div>{obj.legal_name}</div>
														</div>
														<div className="separator separator-dashed"></div>
														<div className="fs-6 d-flex justify-content-between my-4">
															<div className="fw-bold">Business Trade Name</div>
															<div>{obj.trade_name}</div>
														</div>
														<div className="separator separator-dashed"></div>
														<div className="fs-6 d-flex justify-content-between my-4">
															<div className="fw-bold">Registered Date</div>
															<div>{moment(obj.Registered_date).format('ll')}</div>
														</div>
														<div className="separator separator-dashed"></div>
														<div className="fs-6 d-flex justify-content-between my-4">
															<div className="fw-bold">Branch Name</div>
															<div className="symbol-group symbol-hover">
																<div className="badge badge-light-success rounded">{obj.Branch}</div>
															</div>
														</div>
														<div className="separator separator-dashed"></div>
														<div className="fs-6 d-flex justify-content-between my-4">
															<div className="fw-bold">E Way Bill Config Status</div>
															<div className="symbol-group symbol-hover">
																<div className="badge badge-light-success rounded">
																	{gstEwayBillConfigStatusList.find(x=>x.gstin_uid==obj.unique_id)==undefined?"NOT_CONNECTED":gstEwayBillConfigStatusList.find(x=>x.gstin_uid==obj.unique_id).status}
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</>
								);
							})	
							}
						</div>
						</>
						:
						<div className="card modal-body pt-0 pb-15 px-5 px-xl-20">
								<br />
									{state == 2 ?
										<img src="/images/loader-06.svg" alt="" style={loaderstyle} />
										:
										<>
											<div className="flex-lg-row-fluid me-lg-15">
												<div className="card card-flush pt-3 mb-5 mb-lg-10">

													<div className="card-header">
														<div className="card-title">
															<h2 className="fw-bolder">GSTIN List</h2>

														</div>
													</div>
													<div className="card-body pt-0">
														<div className="text-gray-500 fw-bold fs-5 mb-5"></div>
														<div className="d-flex align-items-center p-3 mb-2">
														</div>
														<div className="notice d-flex bg-light-primary rounded border-primary border border-dashed rounded-3 p-6">
															<div className="d-flex flex-stack flex-grow-1">
																<div className="fw-bold">
																	<h4 className="text-gray-800 fw-bolder">No Data Available</h4>
																	<div className="fs-6 text-gray-600">
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</>
									}
								<br />
							</div>
							}
					</div>
				</div>
			</div>
			<Modal show={DefaultTaxModalShow} onHide={()=>{setDefaultTaxModalShow()}} size="lg" centered>
				<Modal.Header>
					<Modal.Title>Default Tax Preference</Modal.Title>
					<div className="btn btn-sm btn-icon btn-active-color-primary" onClick={()=>{setDefaultTaxModalShow(false)}}>
						<span className="svg-icon svg-icon-1">
							<svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
								<g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
									<rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
									<rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
								</g>
							</svg>
						</span>
					</div>
				</Modal.Header>
				<Modal.Body>
					<div className="form-group row">
						<div className="row mb-5">
							<div className="col-md-12 fv-row">
								<label className="required fs-5 fw-bold mb-2">Intra State Tax Rate</label>
								<Select
									styles={userTheme=='dark'?dropdownStyleDark:{}}
									name="category"
									id="category"
									options={TaxGrouplist}
									onChange={(event) => { 
										Default_tax_preference.Intra_State_Tax=event.value;
									}}
									value={TaxGrouplist.filter(option => option.value == Default_tax_preference.Intra_State_Tax).length != 0 && TaxGrouplist.filter(option => option.value ==Default_tax_preference.Intra_State_Tax)[0]}
									placeholder="--Select tax group--"
								/>
							</div>
						</div>
						<div className="row mb-5">
							<div className="col-md-12 fv-row">
								<label className="required fs-5 fw-bold mb-2">Inter State Tax Rate</label>
								<Select
									
									styles={userTheme=='dark'?dropdownStyleDark:{}}
									name="category"
									id="category"
									options={Taxlist}
									onChange={(event) => { 
										Default_tax_preference.Inter_State_Tax=event.value;
									}}
									value={Taxlist.filter(option => option.value == Default_tax_preference.Inter_State_Tax).length != 0 && Taxlist.filter(option => option.value ==Default_tax_preference.Inter_State_Tax)[0]}
									placeholder="--Select tax--"
								/>
							</div>
						</div>
					</div>
				</Modal.Body>
				<Modal.Footer> 
					<button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
					{write==1 &&<form >
						<button type="button" onClick={(e) => { submitGstDetails(e) }} className="btn btn-primary"  data-kt-indicator={mainSubmitButtonState?"on":"off"} disabled={mainSubmitButtonState} ><span className="indicator-label">Update</span>
						<span className="indicator-progress">Please wait...
							<span className="spinner-border spinner-border-sm align-middle ms-2"></span></span></button>
					</form>}
				</Modal.Footer>
			</Modal>

			<Modal show={OnlineFilingModalShow} onHide={()=>{setOnlineFilingModalShow()}} size="lg" centered>
				<Modal.Header>
					<Modal.Title>Online Filing Settings</Modal.Title>
					<div className="btn btn-sm btn-icon btn-active-color-primary" onClick={()=>{setOnlineFilingModalShow(false)}}>
						<span className="svg-icon svg-icon-1">
							<svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
								<g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
									<rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
									<rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
								</g>
							</svg>
						</span>
					</div>
				</Modal.Header>
				<Modal.Body>
				<div className="form-group row">
					<div className="col-lg-12">
						<div className="row mb-5">
							<div className="col-md-12 fv-row">
								<label className="required fs-5 fw-bold mb-2">GSTIN Username</label>
								<input id="subcategory_name" type="text" className="form-control form-control-solid" placeholder="" name="Organization-name" value={Online_filing_settings.GstIN_Username} onChange={(e) => {Online_filing_settings.GstIN_Username=e.target.value}} />
							</div>
						</div>
						<div className="row mb-5">
							<div className="col-md-12 fv-row">
								<label className="required fs-5 fw-bold mb-2">Reporting Period</label>
								<Select
									styles={userTheme=='dark'?dropdownStyleDark:{}}
									name="category"
									id="category"
									options={ReportingPeriodlist}
									onChange={(event) => { 
										Online_filing_settings.Reporting_Period=event.value;
									}}
									value={ReportingPeriodlist.filter(option => option.value == Online_filing_settings.Reporting_Period).length != 0 && ReportingPeriodlist.filter(option => option.value ==Online_filing_settings.Reporting_Period)[0]}
									placeholder="--Select Reporting Period--"
								/>
							</div>
						</div>
						<div className="row mb-5">
							<div className="col-md-12 fv-row">
								<label className="required fs-5 fw-bold mb-2">Generate First Tax Return From</label>
								{/*<input type="month" onChange={(e) => {console.log(e.value)}} id="start" name="start"
       							min="" value=""/>*/}
								<MuiPickersUtilsProvider utils={MomentUtils}>
      							<Grid>
								<DatePicker
								variant="inline"
								openTo="year"
								views={["year", "month"]}
								label="Year and Month"
								helperText="Start from year selection"
								value={Online_filing_settings.selectedDate}
								onChange={ (date) => Online_filing_settings.selectedDate=date}
								/>
							</Grid>
							</MuiPickersUtilsProvider>
							</div>
						</div>
						<div className="row mb-6">
							<label className="fs-5 fw-bold mb-2">GST Filing Approval</label>
							<div className="col-lg-8 fv-row fv-plugins-icon-container fv-plugins-bootstrap5-row-valid">
								<div className="d-flex align-items-center mt-3">
									<label className="form-check form-check-custom form-check-inline form-check-solid me-5 is-valid">
										<input className="form-check-input" name="communication[]" type="checkbox"  checked={Online_filing_settings.Gst_Filing_Approval} onChange={(e) => {ValueLoad(e.target.checked); Online_filing_settings.Gst_Filing_Approval=e.target.checked;
										}}/>
										<span className="fw-semibold ps-2 fs-6">Enable approval process for filing GST Returns</span>
									</label>
								</div>
							<div className="fv-plugins-message-container invalid-feedback"></div></div>
						</div>
						
						{Online_filing_settings.Gst_Filing_Approval===true &&
						<div className="row mb-5">
							<div className="col-md-5 fv-row">
								<label className="required fs-5 fw-bold mb-2">User Type</label>
								<Select
									styles={userTheme=='dark'?dropdownStyleDark:{}}
									name="category"
									id="category"
									options={UserTypelist}
									onChange={(event) => { 
										UserValueLoad(event.value);
										Online_filing_settings.UserType=event.value;
										Online_filing_settings.UserValue=[];
									}}
									value={UserTypelist.filter(option => option.value == Online_filing_settings.UserType).length != 0 && UserTypelist.filter(option => option.value ==Online_filing_settings.UserType)[0]}
									placeholder="--Select User Type--"
								/>
							</div>
							<div className="col-md-5 fv-row">
								<label className="required fs-5 fw-bold mb-2">{Online_filing_settings.UserType==="Individual User"?<>User List</>:<>Group List</>}</label>
								<Select
									styles={userTheme=='dark'?dropdownStyleDark:{}}
									name="category"
									id="category"
									isMulti
									options={UserValuelist}
									onChange={(event) => { 
										Online_filing_settings.UserValue=event.map(a => a.value);
									}}
									value={Online_filing_settings.UserValue.map((e) => { return { label: e, value: e } })}
									placeholder={`--Select--`}
								/>
							</div>
								</div>}
					</div>
				</div>
				</Modal.Body>
				<Modal.Footer> 
					<button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
					{write==1 &&<form >
						<button type="button" onClick={(e) => { submitGstDetails(e) }} className="btn btn-primary"  data-kt-indicator={mainSubmitButtonState?"on":"off"} disabled={mainSubmitButtonState} ><span className="indicator-label">Update</span>
						<span className="indicator-progress">Please wait...
							<span className="spinner-border spinner-border-sm align-middle ms-2"></span></span></button>
					</form>}
				</Modal.Footer>
			</Modal>

			<Modal show={GSTModalShow} onHide={()=>{setGSTModalShow()}} size="lg" centered>
				<Modal.Header>
					<Modal.Title>{gst_id==""?<>New GstIn</>:<>Update GstIn Details</>}</Modal.Title>
					<div className="btn btn-sm btn-icon btn-active-color-primary" onClick={()=>{setGSTModalShow(false)}}>
						<span className="svg-icon svg-icon-1">
							<svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
								<g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
									<rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
									<rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
								</g>
							</svg>
						</span>
					</div>
				</Modal.Header>
				<Modal.Body>
				<div className="modal-body py-10 px-lg-17">
					<div className="scroll-y me-n7 pe-7" id="kt_modal_new_address_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_new_address_header" data-kt-scroll-wrappers="#kt_modal_new_address_scroll" data-kt-scroll-offset="300px">
						<div className="row mb-5">
							<div className="col-md-12 fv-row">
								<label className="required fs-5 fw-bold mb-2">GSTIN</label>
								<input id="subcategory_name" type="text" className="form-control form-control-solid" placeholder="" name="Organization-name" value={GstIN} onChange={(e) => { setGstIN(e.target.value) }} />
							</div>
						</div>
						<div className="row mb-5">
							<div className="col-md-12 fv-row">
								<label className="fs-5 fw-bold mb-2">Business Legal Name</label>
								<input id="subcategory_name" type="text" className="form-control form-control-solid" placeholder="" name="Organization-name" value={LegalName} onChange={(e) => { setLegalName(e.target.value) }} />
							</div>
						</div>
						<div className="row mb-5">
							<div className="col-md-12 fv-row">
								<label className="fs-5 fw-bold mb-2">Business Trade Name</label>
								<input id="subcategory_name" type="text" className="form-control form-control-solid" placeholder="" name="Organization-name" value={TradeName} onChange={(e) => { setTradeName(e.target.value) }} />
							</div>
						</div>
						<div className="row mb-5">
							<div className="col-md-6 fv-row">
								<label className="fs-5 fw-bold mb-2">Registered Date</label>
								<input type="date" name="Expense_date" className="form-control" id="Registered_date" placeholder="Sales Start Date" value={Registered_date}
									onChange={(e) => {
										setRegistered_date(e.target.value);
									}}
								/>
							</div>
						</div>
						<div className="row mb-6">
							<label className="fs-5 fw-bold mb-2">Reverse Charge</label>
							<div className="col-lg-8 fv-row fv-plugins-icon-container fv-plugins-bootstrap5-row-valid">
								<div className="d-flex align-items-center mt-3">
									<label className="form-check form-check-custom form-check-inline form-check-solid me-5 is-valid">
										<input className="form-check-input" name="communication[]" type="checkbox" checked={Reverse_charge} onChange={(e) => {setReverse_charge(e.target.checked);
										}}/>
										<span className="fw-semibold ps-2 fs-6">Enable Reverse Charge in Sales transactions</span>
									</label>
								</div>
							<div className="fv-plugins-message-container invalid-feedback"></div></div>
						</div>
						<div className="row mb-6">
							<label className="fs-5 fw-bold mb-2">Import / Export</label>
							<div className="col-lg-8 fv-row fv-plugins-icon-container fv-plugins-bootstrap5-row-valid">
								<div className="d-flex align-items-center mt-3">
									<label className="form-check form-check-custom form-check-inline form-check-solid me-5 is-valid">
										<input className="form-check-input" name="communication[]" type="checkbox" checked={Import} onChange={(e) => {setImport(e.target.checked);
										}}/>
										<span className="fw-semibold ps-2 fs-6">My business is involved in SEZ / Overseas Trading</span>
									</label>
								</div>
							<div className="fv-plugins-message-container invalid-feedback"></div></div>
						</div>
						{Import===true &&
						<div className="row mb-6">
							<label className="fs-5 fw-bold mb-2">Tracking Account</label>
								<Select
									styles={userTheme=='dark'?dropdownStyleDark:{}}
									name="category"
									id="category"
									options={AccountSelectionList}
									onChange={(val) => {
										//console.log(val); 
										setTracking_account(val);
									}}
									value={Tracking_account}
									placeholder="--Select Account--"
								/>
								<span className="fw-semibold ps-2 fs-6"></span>
									
							<div className="fv-plugins-message-container invalid-feedback"></div>
						</div>}
						<div className="row mb-6">
							<label className="fs-5 fw-bold mb-2">Digital Services</label>
							<div className="col-lg-8 fv-row fv-plugins-icon-container fv-plugins-bootstrap5-row-valid">
								<div className="d-flex align-items-center mt-3">
									<label className="form-check form-check-custom form-check-inline form-check-solid me-5 is-valid">
										<input className="form-check-input" name="communication[]" type="checkbox" checked={Digital_service} onChange={(e) => {setDigital_service(e.target.checked);
										}}/>
										<span className="fw-semibold ps-2 fs-6">Track sale of digital services to overseas customers</span>
									</label>
								</div>
							<div className="fv-plugins-message-container invalid-feedback"></div></div>
						</div>
					</div>
				</div>
				</Modal.Body>
				<Modal.Footer> 
				{write==1 &&<button onClick={(e) => { submitGstDetails(e) }} type="submit" id="kt_modal_new_address_submit" className="btn btn-primary rounded" data-kt-indicator={mainSubmitButtonState?"on":"off"} disabled={mainSubmitButtonState}>
					<span className="indicator-label">{gst_id==""?<>Save</>:<>Update</>}</span>
					<span className="indicator-progress">Please wait...
						<span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
				</button>}
				</Modal.Footer>
			</Modal>



			<div className="modal fade" id="ChangeStatus" tabindex="-1" aria-hidden="true">
				<div className="modal-dialog mw-650px">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title" id="exampleModalLabel">Change Status</h5>
							<div className="modal-header pb-0 border-0 justify-content-end">
								<div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">
									<span className="svg-icon svg-icon-1">
										<svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
											<g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
												<rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
												<rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
											</g>
										</svg>
									</span>
								</div>
							</div>
						</div>
						<div className="modal-body">
							<div className="form-group row">
								<div className="col-lg-12">
									<h4>
										Are you sure you want to marks as {gst_status==="Active"?<>Inactive</>:<>Active</>}?
									</h4>
								</div>
							</div>
						</div>
						<div className="modal-footer">
							<button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
							{write==1 &&<form >
								{gst_status==="Active"?
								<button type="button" onClick={(e) => { submitStatus(gst_id,gst_status) }} className="btn btn-danger"  data-kt-indicator={mainSubmitButtonState?"on":"off"} disabled={mainSubmitButtonState} ><span className="indicator-label">Inactive</span>
								<span className="indicator-progress">Please wait...
									<span className="spinner-border spinner-border-sm align-middle ms-2"></span></span></button>
									:
									<button type="button" onClick={(e) => { submitStatus(gst_id,gst_status) }} className="btn btn-success"  data-kt-indicator={mainSubmitButtonState?"on":"off"} disabled={mainSubmitButtonState} ><span className="indicator-label">Active</span>
								<span className="indicator-progress">Please wait...
									<span className="spinner-border spinner-border-sm align-middle ms-2"></span></span></button>
								}
							</form>}
						</div>
					</div>
				</div>
			</div>

			<Modal show={ewayBillConfigModalShow} onHide={() => { handleEwayBillConfigModalClose(); }} centered>
                <Modal.Header>
                    <Modal.Title>Add Configuration</Modal.Title>
                    <div className="btn btn-sm btn-icon btn-active-color-primary" onClick={() => { handleEwayBillConfigModalClose(); }}>
                        <span className="svg-icon svg-icon-1">
                            <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                    <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                    <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                </g>
                            </svg>
                        </span>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className="container">

						<div className='row gx-0 mb-2'>
							<div className='col-md-6'><label className='fs-5 fw-bold required'>Username</label></div>
							<div className='col-md-6'><input type="text" className="form-control form-control-solid" value={configUsername} onChange={(e) => { setConfigUsername(e.target.value.trim()) }} /></div>
						</div>
						<div className='row gx-0 mb-2'>
							<div className='col-md-6'><label className='fs-5 fw-bold required'>Password</label></div>
							<div className='col-md-6'><input type="password" className="form-control form-control-solid" value={configPassword} onChange={(e) => { setConfigPassword(e.target.value) }} /></div>
						</div>
						<div className='row gx-0 mb-2'>
							<div className='col-md-6'><label className='fs-5 fw-bold required'>GSTIN</label></div>
							<div className='col-md-6'><input type="text" className="form-control form-control-solid" value={configGstinNumber} readOnly disabled/></div>
						</div>

                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-danger btn-sm rounded" onClick={() => { handleEwayBillConfigModalClose() }}>Close</button>
					{
						write == 1 &&
						<button className="btn btn-primary btn-sm" data-kt-indicator={ewayBillConfigSubmitButtonState ? "on" : "off"} disabled={ewayBillConfigSubmitButtonState} onClick={() => { eWayBillConfigSubmit() }}>
							<span className="indicator-label">Save</span>
							<span className="indicator-progress">Please wait...
								<span className="spinner-border spinner-border-sm align-middle ms-2"></span>
							</span>
						</button>
					}
                </Modal.Footer>
            </Modal>

			<Organization_modal OrganizationModalShow={OrganizationModalShow} setOrganizationModalShow1={setOrganizationModalShow} organization_name={organization_name} organization_list={organization_list} organization_id={Organization_id} state_org={state_org} mainSubmitButtonState={mainSubmitButtonState} setMainSubmitButtonState={setMainSubmitButtonState}/>
			<Timer_modal clockTimerModalShow={clockTimerModalShow} setClockTimerModalShow1={setClockTimerModalShow} clock_timer={clock_timer} organization_id={Organization_id} setTimer={setTimer} mainSubmitButtonState={mainSubmitButtonState} setMainSubmitButtonState={setMainSubmitButtonState}/>
		</>);

};

export default GstSettings;
