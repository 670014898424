import React, {useState} from 'react';
import { Modal } from 'react-bootstrap';
import axios from 'axios';
import swal from 'sweetalert';
//import { CountdownCircleTimer } from "react-countdown-circle-timer";
//import './timer-style.css';
import {getCookie} from './cookie.js';

/*async function SaveIssueDetails() {
	const err = new Error(Err_msg);
	const stack = err.stack;
	const lineNumber = stack.split("\n")[3];
	console.log(lineNumber);
	var object = {};
	object.email = user.email;
	//setItem_flag(2);
	const response = await fetch(
		`${process.env.REACT_APP_SERVER_URL}/save-issue-details`, { method: 'POST', body: JSON.stringify({ "message":err.message,"line_no":lineNumber,"page_url":window.location.pathname, "email": user.email, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
	);
	const body = await response.json();
	var temp_dropdown = [];
	if (body.message === "success") 
	{
		swal("Great!", "Issue reported successfully!", "success").then((value) => {
			window.location.href="/";
		});
	}
	else {
		//setItem_flag(4);
	}
}*/

export default function Report_modal(props){
	const [mainSubmitButtonState, setMainSubmitButtonState] = useState(false)

	return (
		<Modal show={props.reportModalShow} /*onHide={()=>{props.setReportModalShow(false)}}*/ size="lg" centered>
			<Modal.Header>
				<Modal.Title>Report an issue</Modal.Title>
				<div className="btn btn-sm btn-icon btn-active-color-primary" onClick={()=>{props.setReportModalShow(false)}}>
				<span className="svg-icon svg-icon-1">
					<svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
						<g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
							<rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
							<rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
						</g>
					</svg>
				</span>
			</div>
			</Modal.Header>
			<Modal.Body>
			<div className="form-group row">
				<div className="col-lg-12">
					{!props.reportModalFlag ?
					<h3>There is some error in the page. Do you want reoprt it to the developer?</h3>
					:
					<h3>This issue already reported through your id.</h3>
					}
				</div>
			</div>
			</Modal.Body>
			<Modal.Footer>
				<button type="button" className="btn btn-secondary" onClick={(e) => {props.setReportModalShow(false)}}>Close</button>			
					{!props.reportModalFlag&&
					<button onClick={(e) => {
					let object={}
					object.token = getCookie("access_token")
					object.organization_id = props.organization_id
					object.message=props.Err_msg
					object.Err_details=props.Err_details
					object.page_url=window.location.pathname
					object.email= props.email
					object.page_name= props.page_name
					let headers = {
						headers: {
							"Content-Type": "application/json",
							"token": getCookie("access_token"),
							"Accept": "*/*"
						}
					}
					setMainSubmitButtonState(true);
					axios.post(`${process.env.REACT_APP_SERVER_URL}/save-issue-details`, object, headers).then((res) => {
						setMainSubmitButtonState(false);
						swal("Great!", "Issue reported successfully!", "success").then((value) => {
							window.location.href="/";
						});
					})
				}} className="btn btn-primary" data-kt-indicator={mainSubmitButtonState?"on":"off"} disabled={mainSubmitButtonState}>
					<span className="indicator-label">Report</span>
					<span className="indicator-progress">Please wait...
						<span className="spinner-border spinner-border-sm align-middle ms-2"></span>
					</span>
					</button>}
			</Modal.Footer>
		</Modal>
	);
};
