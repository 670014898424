import React, {useState,useRef} from "react";
import { Redirect, Switch, Route} from "react-router-dom";

import Dashboard from './includes/dashboard.js';
import Signin from './includes/sign-in.js';
import Signup from './includes/sign-up.js';
import QuickSetUp from './includes/quick-set-up.js';

import ForgotPassword from"./includes/forgot-password.js";
import FirstStepForgotPassword from "./includes/first-step-forgot-password.js";
import VerificationAccount from"./includes/verification-account.js";
import CreateAccount from"./includes/create-account.js";
import FirstStepInvitation from "./includes/first-step-invitation.js";

import NotFound from "./includes/NotFound.js";
import UnAuthorized from "./includes/unAuthorized.js";
import ActivityLogAlert from './includes/activity-log-alert.js';

import AsideNavbar from "./includes/aside-navbar.js";
import Header from "./includes/header.js";
import Footer from "./includes/footer.js";


import DealerSaleEntry from "./includes/dealer-sale-entry.js";
import ISDSaleEntry from "./includes/isd-sale-entry.js";
import EditDealerSaleEntry from "./includes/edit-dealer-sale-entry.js";
import EditISDSaleEntry from "./includes/edit-isd-sale-entry.js";
import SaleList from "./includes/sale-list.js";

import ShowCategory from "./includes/category-list.js";
import ShowSubCategory from "./includes/subcategory-list.js";
import ItemList from "./includes/item-list.js";
import Brands from "./includes/brands.js";
import GstSettings from "./includes/gst-settings.js";
import Approvals from "./includes/approvals.js";
import Settings from "./includes/settings.js";

import OrganizationProfile from './includes/organization-profile.js';
import AccountOverview from './includes/account-overview.js';
import TaxList from "./includes/tax-list.js";
import TaxGroupList from "./includes/tax-group-list.js";
import ShowCustomer from "./includes/show-customer.js";

import DealerCollectionSummary from "./includes/dealer-collection-summary.js";
import DealerCollectionList from "./includes/dealer-collection-list.js";

import BalanceData from "./includes/balance-data.js";
import BalanceDetails from "./includes/balance-details.js";

export default function BasePage() {

	return (
		<>
		<Switch>
			<Route exact path={"/login"} component={Signin} />
			<Route exact path={"/register"} component={Signup} />
			<Route exact path={"/quick-set-up"} component={QuickSetUp} />
			<Route exact path={"/quick-set-up/:id"} component={QuickSetUp} />
			<Route exact path={"/verification-account/:id"} component={VerificationAccount} />
			<Route exact path={"/forgot-password/:id"} component={ForgotPassword} />
			<Route exact path={"/forgot-password-email"} component={FirstStepForgotPassword} />
			<Route exact path={"/create-account/:id"} component={CreateAccount} />
			<Route exact path={"/first-step-invitation"} component={FirstStepInvitation} />
			<Route exact from="/not-found" component={NotFound} />
			<Route exact path={"/unauthorized"} component={UnAuthorized} />
			<Route component={DefaultContainer} />
		</Switch>
		</>
	);
}

const DefaultContainer = () => {
	const [asideExpand, setAsideExpand] = useState(false);
	const carRef = useRef();
	return (
	<>	
			<div className="d-flex flex-column flex-root app-root" id="kt_app_root">
				<div className="app-page flex-column flex-column-fluid" id="kt_app_page">
				<ActivityLogAlert />
					<Header asideRefFunc={setAsideExpand} carRef={carRef} />
					<div className="app-wrapper flex-column flex-row-fluid" id="kt_app_wrapper">
					<AsideNavbar asideRef={asideExpand} asideRefFunc={setAsideExpand} carRef={carRef}/>
						<div className="app-main flex-column flex-row-fluid" id="kt_app_main">
						<Switch>
							<Route exact path={"/dashboard"} component={Dashboard} />

							<Route exact path={"/dealer-sale-entry"} component={DealerSaleEntry} />
							<Route exact path={"/isd-sale-entry"} component={ISDSaleEntry} />
							<Route exact path={"/edit-dealer-sale-entry/:id"} component={EditDealerSaleEntry} />
							<Route exact path={"/edit-isd-sale-entry/:id"} component={EditISDSaleEntry} />
							<Route exact path={"/sale-list"} component={SaleList} />

							<Route exact path={"/data-sheet-list"} component={BalanceData} />
							<Route exact path={"/balance-details/:id"} component={BalanceDetails} />

							<Route exact path={"/brands"} component={Brands} />
							<Route exact from="/category-list" component={ShowCategory} />
							<Route exact from="/subcategory-list" component={ShowSubCategory} />
							<Route exact from="/item-list" component={ItemList} />
							<Route exact path={"/gst-settings"} component={GstSettings} />
							<Route exact path={"/tax-list"} component={TaxList} />
							<Route exact path={"/tax-group-list"} component={TaxGroupList} />
							<Route exact path={"/settings"} component={Settings} />
							<Route exact path={"/approvals"} component={Approvals} />

							<Route exact from="/organization-profile" component={OrganizationProfile} />
							<Route exact from="/account-overview" component={AccountOverview} />
							<Route exact from="/customer-list" component={ShowCustomer} />

							<Route exact path={"/dealer-collection-summary"} component={DealerCollectionSummary} />
							<Route exact path={"/dealer-collection-list"} component={DealerCollectionList} />

							<Route exact from="/" component={Dashboard} />				
							<Route from="*" ><Redirect to="/not-found" /> </Route>
						</Switch>
						</div>
						<Footer />
					</div>
				</div>
			
		</div>
	</>
	)
}
	