import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import swal from 'sweetalert';
import { Redirect } from 'react-router-dom'
import { Modal } from "react-bootstrap";
import Select, { components } from 'react-select';
import MUIDataTable from "mui-datatables";
import { MuiThemeProvider, createTheme } from '@material-ui/core';

import { setCookie, getCookie } from '../common_component/cookie.js';
import Organization_modal from '../common_component/organization_modal.js';
import Timer_modal from '../common_component/timer_modal.js';

// import uuid from 'react-uuid';
// import moment from 'moment';
// import Dropzone from 'react-dropzone-uploader';
// import 'react-dropzone-uploader/dist/styles.css'
// import Select, {components} from 'react-select';
// import removeImg from './icons/remove.svg'
// import ReactTagInput from "@pathofdev/react-tag-input"; 
// import "@pathofdev/react-tag-input/build/index.css";
// import currency_arr from './currency-arr.js' ;
// import ReactDOM from 'react-dom';
// import $ from 'jquery';


function Brands(props) {
    const [user, setUser] = useState({});
    const [organization_id, setOrganization_id] = useState("")
    const [organization_list, setorganization_list] = useState([]);
    const timer = useRef(0)
    const [clock_timer, setClockTimer] = useState(0);
    const [state, setstate] = useState(2);

    const timer_interval_ref = useRef();
    const [clockTimerModalShow, setClockTimerModalShow] = useState(false);
    const [state_org, setstate_org] = useState(2);
    const [organization_name, setOrganization_name] = useState("");
    const [OrganizationModalShow, setOrganizationModalShow] = useState(false);
    const [redirect_to_login, setRedirect_to_login] = useState(2);

    const [userTheme, setUserTheme] = useState((localStorage.getItem("kt_metronic_theme_mode_menu") == "system") ? window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light" : localStorage.getItem("kt_metronic_theme_mode_menu"))

    const [brandsList, setBrandsList] = useState([])
    const [listCount, setListCount] = useState(0)
    const [currentPage, setCurrentPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [sortColumn, setSortColumn] = useState(null)
    const [sortValue, setSortValue] = useState(null)
    const [searchValue, setSearchValue] = useState(null)
    const [brandsListApiState, setBrandsListApiState] = useState(false)

    const [data, setData] = useState([])
    const [brandId, setbrandId] = useState("");
    const [status, setstatus] = useState("");
    const [StatusModalShow, setStatusModalShow] = useState(false);
    const [mainSubmitButtonState, setMainSubmitButtonState] = useState(false);


    const [addBrandModalShow, setAddBrandModalShow] = useState(false)
    const [brandName, setBrandName] = useState("")
    const [brandDescription, setBrandDescription] = useState("")
    const [brandImageFile, setBrandImageFile] = useState(null)
    const [brandSubmitButtonState, setBrandSubmitButtonState] = useState(false)

    const [editBrandModalShow, setEditBrandModalShow] = useState(false)
    const [brandIdEdit, setBrandIdEdit] = useState("")
    const [brandNameEdit, setBrandNameEdit] = useState("")
    const [brandDescriptionEdit, setBrandDescriptionEdit] = useState("")
    const [brandImageFileEdit, setBrandImageFileEdit] = useState(null)
    const [editBrandSubmitButtonState, setEditBrandSubmitButtonState] = useState(false)

    const [visibility, setvisibility] = useState(0);
    const [redirect, setredirect] = useState(0);
    const [read, setread] = useState(2);
    const [write, setwrite] = useState(2);

    var page_name = "brands";

    const options = {
        fixedHeader: false,
        selectableRows: "none",
        responsive: "standard",
        tableBodyHeight: "400px",
        tableBodyMaxHeight: "",
    };

    let dropdownStyleDark = {
        option: (provided, state) => ({
            ...provided,
            color: '#dddddf',
            backgroundColor: state.isSelected ? provided.backgroundColor : state.isFocused ? '#5f5f8d' : provided.backgroundColor,
        }),
        control: (provided, state) => ({
            ...provided,
            backgroundColor: "#2b2b40",
            borderColor: "#4c4c71"
        }),
        placeholder: (provided, state) => ({
            ...provided,
            color: "#dddddf",
        }),
        singleValue: (provided, state) => ({
            ...provided,
            color: "#dddddf",
        }),
        menu: (provided, state) => ({
            ...provided,
            backgroundColor: "#2b2b40",
        })
    }

    const loaderstyle = {
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: "auto",
        marginBottom: "auto",
    };

    const columns = [
        {
            name: "brand_name",
            label: "Brand Name",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "brand_description",
            label: "Brand Description",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "add_status",
            label: "Status",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    let color
                    if (value == "Active")
                        color = "green"
                    else
                        color = "red"

                    return (
                        <p
                            className='my-auto'
                            value={value}
                            onChange={event => updateValue(event.target.value, tableMeta.columnIndex)}
                            style={{ color: color }}>{value}
                        </p>
                    )
                }
            }
        }
        // {
        //     name: "actions",
        //     label: "Actions",
        //     options: {
        //         filter: false,
        //         sort: false,
        //         download: false
        //     }
        // }
    ]

    useEffect(() => {
        if (getCookie("access_token") != "") {
            if (getCookie("organization_id") != "") {
                setOrganization_id(getCookie("organization_id"));
            }
            else {
                var object = {};
                object.page_name = page_name;
                var headers = {
                    headers: {
                        "Content-Type": "application/json",
                        "token": getCookie("access_token"),
                        "Accept": "*/*"
                    }
                }

                axios.post(`${process.env.REACT_APP_SERVER_URL}/show-organization`, object, headers).then((res) => {
                    if (res.data.length == 1) {
                        setCookie('organization_id', res.data[0].organization_id, 1, true);
                        setOrganization_id(res.data[0].organization_id)
                    }
                    else {
                        setstate_org(1);
                        setorganization_list(res.data);
                        setOrganizationModalShow(true);
                    }
                })
            }
        }
        else {
            setRedirect_to_login(1);
        }
    }, [])

    useEffect(() => {
        setCookie("lastpage", window.location.href, 1, false)
        const validateJWT = () => {

            if (getCookie("access_token") != "") {
                var object = {};
                object.token = getCookie("access_token");

                var headers = {
                    headers: {
                        "Content-Type": "application/json",
                        "Accept": "*/*"
                    }
                }
                axios.post(`${process.env.REACT_APP_SERVER_URL}/get-user`, object, headers).then((res) => {

                    if (res.data.operation == "success") {
                        var user_object = {};
                        user_object.email = res.data.email;
                        user_object.name = res.data.name;
                        user_object.img = res.data.img;
                        setUser(user_object)
                    }
                    else {
                        setRedirect_to_login(1);
                    }
                })
                    .catch(function (error) {
                        setRedirect_to_login(1);
                    });

                object.organization_id = organization_id;
                axios.post(`${process.env.REACT_APP_SERVER_URL}/audience-info`, object, headers).then((res) => {

                    if (res.data.operation == "success") {
                        var token = res.data.audience_license;
                        if (res.data.organization_flag == 0) {
                            setstate_org(3);
                            setOrganization_name(res.data.organization_name);
                            setOrganizationModalShow(true);
                        }
                        else {
                            var path = window.location.pathname;
                            path = path.replace(/\/$/, "");
                            path = decodeURIComponent(path);
                            var original_url = path.split("/")[1]
                            let index = 0;
                            let temp = -1;
                            for (let i = 0; i < res.data.user_access.access.length; i++) {
                                if (temp == 1) break;
                                let component = res.data.user_access.access[i].inside_components;
                                for (let j = 0; j < component.length; j++) {
                                    if (temp == 1) break;
                                    if (component[j].hasOwnProperty(original_url)) {
                                        index = i;
                                        if (component[j][original_url] == 1 && res.data.user_access.access[index].visibility == 1) {
                                            temp = 1;
                                            setvisibility(1);
                                            setread(component[j]["read"])
                                            setwrite(component[j]["write"])
                                            // setwrite(0)
                                            // break;
                                        }
                                        else {
                                            setredirect(1);
                                        }
                                    }
                                    else if (component[j].hasOwnProperty("key")) {
                                        let sub_inside_components = component[j].inside_components
                                        for (let k = 0; k < sub_inside_components.length; k++) {
                                            if (sub_inside_components[k].hasOwnProperty(original_url)) {
                                                index = i
                                                if (sub_inside_components[k][original_url] == 1 && res.data.user_access.access[index].visibility == 1 && component[j].visibility == 1) {
                                                    temp = 1;
                                                    setvisibility(1);
                                                    setread(sub_inside_components[k]["read"])
                                                    setwrite(sub_inside_components[k]["write"])
                                                    // setwrite(0)
                                                    break;

                                                }
                                                else {
                                                    setredirect(1);
                                                }
                                            }
                                        }
                                    }
                                    else {
                                        temp = 2;
                                    }
                                }
                            }

                            if (temp == 2) {
                                setredirect(1);
                            }

                        }
                        if (!token.includes("ufhdsupw29fher") || !token.includes("pdbwu27dh6schs") || !token.includes("0dh27agiqbcrwi")) {
                            setCookie('access_token', res.data.audience_license, 1, true)
                            timer.current = process.env.REACT_APP_JWT_EXPIRY;
                        }
                        else {
                            timer.current = res.data.audience_count;
                        }
                    }
                    else {
                        setRedirect_to_login(1);
                    }
                })
                    .catch(function (error) {
                        console.log(error);
                        setRedirect_to_login(1);
                    });
            }
            else {
                setRedirect_to_login(1);
            }
        }
        organization_id != "" && validateJWT();
    }, [organization_id])

    useEffect(() => {
        timer_interval_ref.current = setInterval(() => {
            // console.log(timer)

            timer.current = timer.current - 1
            if (timer.current > 0 && timer.current < process.env.REACT_APP_JWT_EXPIRY_NOTICE) {
                if (!clockTimerModalShow) {
                    setClockTimer(process.env.REACT_APP_JWT_EXPIRY_NOTICE)
                    setClockTimerModalShow(true)
                }
            }
        }, 1000);

        return () => { clearInterval(timer_interval_ref) };
    }, []);

    async function getBrands(cp, rpp, sort_c, sort_v, search_v) {
        setBrandsListApiState(true)
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/get-brands`, { method: 'POST', body: JSON.stringify({ "email": user.email, "current_page": cp, "rows_per_page": rpp, "sort_column": sort_c, "sort_value": sort_v, "search_value": search_v, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        // console.log(body)
        if (body.data.list.length > 0) {
            setBrandsList(body.data.list)
            setListCount(body.data.count)
            setBrandsListApiState(false)
        }
        else {
            setBrandsList([]);
            setListCount(0);
            setBrandsListApiState(false);
            setData([]);
            setstate(1);
        }
    }

    useEffect(() => {
        if ((read || write) && (user.email && visibility)) {

            let p1 = getBrands(currentPage, rowsPerPage, sortColumn, sortValue, searchValue)

            Promise.all([p1])
                .then(() => {
                    //setstate(1)
                    console.log("all apis done")
                })
                .catch((err) => {
                    setstate(3)
                    console.log(err)
                })
        }

    }, [user, visibility, read, write])

    useEffect(() => {
        if (state == 1) {
            getBrands(currentPage, rowsPerPage, sortColumn, sortValue, searchValue)
        }
    }, [currentPage, rowsPerPage, sortColumn, sortValue, searchValue])

    useEffect(() => {
        if (brandsList.length > 0) {
            let temp_data = brandsList.map((obj) => {

                let temp_obj = {};
                temp_obj.brand_name = obj.brand_name
                temp_obj.brand_description = obj.brand_description
                temp_obj.add_status = obj.add_status;

                let id = obj.brand_id;

                return temp_obj
            })
            setData(temp_data);
            setstate(1);
        }
    }, [brandsList])

    useEffect(() => {
        let id = setInterval(() => {
            setUserTheme((localStorage.getItem("kt_metronic_theme_mode_menu") == "system") ? window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light" : localStorage.getItem("kt_metronic_theme_mode_menu"))
        }, 10)

        return () => {
            clearInterval(id)
        }
    }, [])

    return (
        <>
            {
                (redirect == 1 || (read == 0 && write == 0)) ?
                    <>
                        <Redirect to={{
                            pathname: "/unauthorized",
                        }}
                        />
                    </> :
                    null
            }
            {
                redirect_to_login == 1 ? <>
                    <Redirect to={{ pathname: "/login" }} />
                </> : null
            }

            <div className="d-flex flex-column flex-column-fluid">
                <div className="app-toolbar py-3 py-lg-6" id="kt_app_toolbar">
                    <div id="kt_app_toolbar_container" className="app-container container-xxl d-flex flex-stack">
                        <h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">Brands</h1>
                    </div>
                </div>
                <div id="kt_app_content" className="app-content flex-column-fluid">
                    <div id="kt_app_content_container" className="app-container container-xxl">
                        {
                            state == 1 ?
                                <>
                                    <MuiThemeProvider theme={createTheme({ palette: { type: userTheme } })}>
                                        <MUIDataTable
                                            title={
                                                <>
                                                    <span className='me-20'>Brands</span>
                                                    {
                                                        brandsListApiState &&
                                                        <>
                                                            <span>Loading...</span>
                                                            <svg width="50" height="15" viewBox="0 45 100 10">
                                                                <g transform="translate(20 50)"><circle cx="0" cy="0" r="6" fill="#e15b64"><animateTransform attributeName="transform" type="scale" begin="-0.375s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite"></animateTransform></circle></g>
                                                                <g transform="translate(40 50)"><circle cx="0" cy="0" r="6" fill="#f8b26a"><animateTransform attributeName="transform" type="scale" begin="-0.25s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite"></animateTransform></circle></g>
                                                                <g transform="translate(60 50)"><circle cx="0" cy="0" r="6" fill="#abbd81"><animateTransform attributeName="transform" type="scale" begin="-0.125s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite"></animateTransform></circle></g>
                                                                <g transform="translate(80 50)"><circle cx="0" cy="0" r="6" fill="#81a3bd"><animateTransform attributeName="transform" type="scale" begin="0s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite"></animateTransform></circle></g>
                                                            </svg>
                                                        </>
                                                    }
                                                </>
                                            }
                                            data={data}
                                            columns={columns}
                                            options={{
                                                ...options,
                                                download: false,
                                                filter: false,
                                                searchPlaceholder: 'Search Brands',
                                                serverSide: true,
                                                count: listCount,
                                                //jumpToPage: true,
                                                onChangePage: (val) => { setCurrentPage(val) },
                                                onChangeRowsPerPage: (val) => { setCurrentPage(0); setRowsPerPage(val) },
                                                onColumnSortChange: (col, dir) => { setSortColumn(col); setSortValue(dir) },
                                                onSearchChange: (val) => { setCurrentPage(0); setSearchValue(val) },
                                                // onTableChange: (action, tableState)=>{console.log(action, tableState);}
                                            }}
                                        />
                                    </MuiThemeProvider>
                                </>
                                :
                                state == 2 ?
                                    <div className="card">
                                        <div className="card-body pt-6">
                                            <img src="/images/loader-06.svg" alt="" style={loaderstyle} />
                                        </div>
                                    </div>
                                    :
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="notice d-flex bg-light-primary rounded border-primary border border-dashed rounded-3 p-6">
                                                <div className="d-flex flex-stack flex-grow-1">
                                                    <div className="fw-bold">
                                                        <h4 className="text-gray-800 fw-bolder">Something went wrong. Please reload this page.</h4>
                                                        <div className="fs-6 text-gray-600"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                        }
                    </div>
                </div>
            </div>
            <Organization_modal OrganizationModalShow={OrganizationModalShow} setOrganizationModalShow1={setOrganizationModalShow} organization_name={organization_name} organization_list={organization_list} organization_id={organization_id} state_org={state_org} />
            <Timer_modal clockTimerModalShow={clockTimerModalShow} setClockTimerModalShow1={setClockTimerModalShow} clock_timer={clock_timer} organization_id={organization_id} timer_ref={timer} />
        </>
    );
}


export default Brands;