import React, { lazy, Suspense, useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import swal from 'sweetalert';
import { Redirect } from "react-router-dom";
//import process.env from '../config.json';
import ReCAPTCHA from "react-google-recaptcha"
import moment from 'moment'
import copy from "copy-to-clipboard";

import { CountdownCircleTimer } from "react-countdown-circle-timer";
import { setCookie, getCookie } from '../common_component/cookie.js';

function ActivityLogAlert() {

	const [alert_log, setAlertLog] = useState([]);
	const [state, setstate] = useState(2);

	const [timer, setTimer] = useState(0);
	const [clock_timer, setClockTimer] = useState(0);

	var page_name = "activity-log-alert";

	const loaderstyle = {
		display: "block",
		marginLeft: "auto",
		marginRight: "auto",
		marginTop: "auto",
		marginBottom: "auto",
	};

	useEffect(() => {
		const validateJWT = () => {

			if (getCookie("access_token") != "") {
				var object = {};
				object.token = getCookie("access_token");

				var headers = {
					headers: {
						"Content-Type": "application/json",
						"Accept": "*/*",
						"token": getCookie("access_token")
					}
				}

				async function getorganizationdetails() {
					const response = await fetch(
						`${process.env.REACT_APP_SERVER_URL}/show-organization`, { method: 'POST', body: JSON.stringify({ "page_name": page_name }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
					);
					const body = await response.json();

					//setEditModal(body);

					if (body.length > 0) {
						setAlertLog(body)
						setstate(1);
					}
					else {
						setstate(0);
					}
				}
				getorganizationdetails();
			}
		}

		validateJWT();
	}, [])

	useEffect(() => {
		if (timer > 0) {
			const timer_decrease = setInterval(() => {
				setTimer(timer - 1);
				if (timer > 0 && timer < process.env.REACT_APP_JWT_EXPIRY_NOTICE) {
					if (!document.getElementById("kt_modal_timer").classList.contains("show")) {
						setClockTimer(process.env.REACT_APP_JWT_EXPIRY_NOTICE)
						window.$('#kt_modal_timer').modal('show');
					}
				}
			}, 1000);

			return () => clearInterval(timer_decrease);
		}
	}, [timer]);

	const copyToClipboard = (val) => {
		copy(val);
	}

	return (
		<div id="kt_activities" className="bg-body" data-kt-drawer="true" data-kt-drawer-name="activities" data-kt-drawer-activate="true" data-kt-drawer-overlay="true" data-kt-drawer-direction="end" data-kt-drawer-toggle="#kt_activities_toggle" data-kt-drawer-close="#kt_activities_close">
			<div className="card shadow-none border-0 rounded-0">
				<div className="card-header" id="kt_activities_header">
					<h3 className="card-title fw-bolder text-dark">
						My Organizations</h3>
					<div className="card-toolbar">
						<button type="button" className="btn btn-sm btn-icon btn-active-light-primary me-n5" id="kt_activities_close">
							<span className="svg-icon svg-icon-1">
								<svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
									<g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
										<rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
										<rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
									</g>
								</svg>
							</span>
						</button>
					</div>
				</div>
				<div className="card-body position-relative" id="kt_activities_body">

					<div id="kt_activities_scroll" className="position-relative scroll-y me-n5 pe-5" data-kt-scroll="true" data-kt-scroll-height="auto" data-kt-scroll-wrappers="#kt_activities_body" data-kt-scroll-dependencies="#kt_activities_header, #kt_activities_footer" data-kt-scroll-offset="5px">

						<div className="timeline">
							{
								alert_log.length > 0 ? alert_log.map((obj, index) => {
									if (index < 5) {
										return (
											<div key={index} className="col-lg-12">													
												<input type="radio" className="btn-check" name="account_type" value="personal" id={obj.organization_id} onClick={(e) => { setCookie('organization_id', obj.organization_id, 1, true); window.location.reload(); }} checked={(getCookie("organization_id") == obj.organization_id)} readOnly />
												<label className="btn btn-outline btn-outline-dashed btn-outline-default p-7 d-flex align-items-center mb-10" htmlFor={obj.organization_id}>
													<span className="svg-icon svg-icon-3x me-5">
														<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
															<path opacity="0.3" d="M20.9 12.9C20.3 12.9 19.9 12.5 19.9 11.9C19.9 11.3 20.3 10.9 20.9 10.9H21.8C21.3 6.2 17.6 2.4 12.9 2V2.9C12.9 3.5 12.5 3.9 11.9 3.9C11.3 3.9 10.9 3.5 10.9 2.9V2C6.19999 2.5 2.4 6.2 2 10.9H2.89999C3.49999 10.9 3.89999 11.3 3.89999 11.9C3.89999 12.5 3.49999 12.9 2.89999 12.9H2C2.5 17.6 6.19999 21.4 10.9 21.8V20.9C10.9 20.3 11.3 19.9 11.9 19.9C12.5 19.9 12.9 20.3 12.9 20.9V21.8C17.6 21.3 21.4 17.6 21.8 12.9H20.9Z" fill="black"></path>
															<path d="M16.9 10.9H13.6C13.4 10.6 13.2 10.4 12.9 10.2V5.90002C12.9 5.30002 12.5 4.90002 11.9 4.90002C11.3 4.90002 10.9 5.30002 10.9 5.90002V10.2C10.6 10.4 10.4 10.6 10.2 10.9H9.89999C9.29999 10.9 8.89999 11.3 8.89999 11.9C8.89999 12.5 9.29999 12.9 9.89999 12.9H10.2C10.4 13.2 10.6 13.4 10.9 13.6V13.9C10.9 14.5 11.3 14.9 11.9 14.9C12.5 14.9 12.9 14.5 12.9 13.9V13.6C13.2 13.4 13.4 13.2 13.6 12.9H16.9C17.5 12.9 17.9 12.5 17.9 11.9C17.9 11.3 17.5 10.9 16.9 10.9Z" fill="black"></path>
														</svg>
													</span>
													<span className="d-block fw-bold text-start">
														<span className="text-dark fw-bolder d-block fs-4 mb-2">{obj.organization_name}</span>
														<div className="d-flex align-items-center mb-2">
															<span className="text-muted fw-semibold fs-6">Organization ID:</span><span className="badge badge-light-info fs-3 me-4">{obj.organization_id}</span>
															<button onClick={(e) => { copyToClipboard(obj.organization_id) }} className={`btn btn-primary btn-icon btn-sm btn-outline-light`}>
																<span className="svg-icon svg-icon-2">
																	<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
																		<path opacity="0.5" d="M18 2H9C7.34315 2 6 3.34315 6 5H8C8 4.44772 8.44772 4 9 4H18C18.5523 4 19 4.44772 19 5V16C19 16.5523 18.5523 17 18 17V19C19.6569 19 21 17.6569 21 16V5C21 3.34315 19.6569 2 18 2Z" fill="currentColor"></path>
																		<path fillRule="evenodd" clipRule="evenodd" d="M14.7857 7.125H6.21429C5.62255 7.125 5.14286 7.6007 5.14286 8.1875V18.8125C5.14286 19.3993 5.62255 19.875 6.21429 19.875H14.7857C15.3774 19.875 15.8571 19.3993 15.8571 18.8125V8.1875C15.8571 7.6007 15.3774 7.125 14.7857 7.125ZM6.21429 5C4.43908 5 3 6.42709 3 8.1875V18.8125C3 20.5729 4.43909 22 6.21429 22H14.7857C16.5609 22 18 20.5729 18 18.8125V8.1875C18 6.42709 16.5609 5 14.7857 5H6.21429Z" fill="currentColor"></path>
																	</svg>
																</span>
															</button>
														</div>
													</span>
												</label>
												<div className="fv-plugins-message-container invalid-feedback"></div>
											</div>
										);
									}
								})
								: state == 2 &&
								<img src="/images/loader-06.svg" alt="" style={loaderstyle} />
							}
						</div>
					</div>
				</div>

				<div className="card-footer py-5 text-center" id="kt_activities_footer">
					<a href="/show-organizations" className="btn btn-bg-white text-primary">Manage All Organizations
						<span className="svg-icon svg-icon-3 svg-icon-primary">
							<svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
								<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
									<polygon points="0 0 24 0 24 24 0 24" />
									<rect fill="#000000" opacity="0.5" transform="translate(8.500000, 12.000000) rotate(-90.000000) translate(-8.500000, -12.000000)" x="7.5" y="7.5" width="2" height="9" rx="1" />
									<path d="M9.70710318,15.7071045 C9.31657888,16.0976288 8.68341391,16.0976288 8.29288961,15.7071045 C7.90236532,15.3165802 7.90236532,14.6834152 8.29288961,14.2928909 L14.2928896,8.29289093 C14.6714686,7.914312 15.281055,7.90106637 15.675721,8.26284357 L21.675721,13.7628436 C22.08284,14.136036 22.1103429,14.7686034 21.7371505,15.1757223 C21.3639581,15.5828413 20.7313908,15.6103443 20.3242718,15.2371519 L15.0300721,10.3841355 L9.70710318,15.7071045 Z" fill="#000000" fillRule="nonzero" transform="translate(14.999999, 11.999997) scale(1, -1) rotate(90.000000) translate(-14.999999, -11.999997)" />
								</g>
							</svg>
						</span>
					</a>
				</div>

			</div>
		</div>
	);
}
export default ActivityLogAlert;