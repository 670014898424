import React, { lazy, Suspense, useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import swal from 'sweetalert';
import { Redirect } from "react-router-dom";
//import process.env from '../config.json';
import ReCAPTCHA from "react-google-recaptcha"

import $ from 'jquery';
function FirstStepForgotPassword() {
	let modal = 2;
	let [RedirectState, setRedirectState] = useState(2);

	let [captchaToken, setCaptchaToken] = useState("");

	const [value, setValue] = useState();
	
	const [check, setcheck] = useState(0);

	const [forget_id, setforget_id] = useState("");

	function onChange(value) {
		setCaptchaToken(value)
	}

	function setCookie(cname, cvalue, exdays) {
		var d = new Date();
		d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
		var expires = "expires=" + d.toUTCString();
		document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
	}

	function getCookie(cname) {
		var name = cname + "=";
		var ca = document.cookie.split(';');
		for (var i = 0; i < ca.length; i++) {
			var c = ca[i];
			while (c.charAt(0) == ' ') {
				c = c.substring(1);
			}
			if (c.indexOf(name) == 0) {
				return c.substring(name.length, c.length);
			}
		}
		return "";
	}

	useEffect(() => {
		/*var se = document.createElement('script');
		se.src = "/assets/js/custom/authentication/sign-up/general.js";
		document.body.appendChild(se);*/
	}, [])


	const register = (e) => {

		e.preventDefault();
		var object = {};
		object.email = document.getElementById("email").value;

		var exp = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
		if (object.email == "") {
			//console.log("7");
			swal("Warning!", "Please provide a  email", "warning");
			return false;
		}
		else if (!object.email.match(exp)) {
			//console.log("8");
			swal("Warning!", "Please provide a valid email", "warning");
			return false;
		}

		if (e.target.id == "kt_sign_up_submit") {
			e.target.setAttribute("data-kt-indicator", "on");
			e.target.disabled = true;
		}
		else {
			document.getElementById("kt_sign_up_submit").setAttribute("data-kt-indicator", "on");
			document.getElementById("kt_sign_up_submit").disabled = true;
		}



		axios.post(`${process.env.REACT_APP_SERVER_URL}/check-user`, object).then((res) => {

			if (res.data.operation == "success") {
				swal("", "A link has been sent to your email id.\n Please check your email.", "success").then((res) => {
					window.location.reload();
				});					
			}
			else {
				if (res.data.message != "danger")
				{
					swal("Oops!", res.data.message, "error").then((res) => { 
						window.location.reload(); 
					});
				}
				else
				{
					swal("Oops!", "This email is not registered us", "error").then((res) => {
						window.location.reload();
					});
				}
			}
		})
		.catch(function (error) {
			swal("Oops!", "something went wrong", "error").then((res) => {
				window.location.reload();
			});
		});
	}
	
	return (
		<>
			
			<div className="bg-dark header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed toolbar-tablet-and-mobile-fixed aside-enabled aside-fixed" style={{ "--kt-toolbar-height": "55px", "--kt-toolbar-height-tablet-and-mobile": "55px" }} >
				<div className="d-flex flex-column flex-root">
					<div className="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed" style={{ "background-size1": "100% 50%", "background-image": "url(assets/media/misc/outdoor.png)" }}>
						<div className="d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20">
							<a href="/login" className="mb-12">
								<img alt="Logo" src="https://cdnstatic.adoerp.com/static/ado-erp-logo.png" className="h-45px" />
							</a>
							<div className="w-lg-600px bg-white rounded shadow-sm p-10 p-lg-15 mx-auto">
								<form className="form w-100" novalidate="novalidate" id="kt_sign_up_form" onSubmit={(e) => { register(e) }}>

									
									<div className="row fv-row mb-7">
										<div className="col-xl-12">
											<label className="form-label fw-bolder text-dark fs-6">Email</label>
											<input className="form-control form-control-lg form-control-solid" type="email" placeholder="" id="email" name="email" autocomplete="off" />
										</div>
										<div className="text-muted">Please enter email.</div>

									</div>

									<div className="text-center">
										<br/>
										<button type="button" id="kt_sign_up_submit" className="btn btn-lg btn-primary rounded" onClick={(e) => { register(e) }}>
											<span className="indicator-label">Next</span>
											<span className="indicator-progress">Please wait...
												<span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
										</button>
									</div>
								</form>
							</div>
						</div>
						<div className="d-flex flex-center flex-column-auto p-10">
							<div style={{height:'200px'}} className="d-flex align-items-center fw-bold fs-6">

							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	
	);
}
export default FirstStepForgotPassword;