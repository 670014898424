import React, { useState, useEffect, useRef } from 'react';

import axios from 'axios';
import swal from 'sweetalert';
import { Link, Redirect } from 'react-router-dom'
import Select from 'react-select';
import moment from 'moment';
import { MuiThemeProvider, createTheme } from '@material-ui/core';
import MUIDataTable from "mui-datatables";
import { Modal } from 'react-bootstrap';
import SetColor from './SetColor.js';

import { setCookie, getCookie } from '../common_component/cookie.js';
import Organization_modal from '../common_component/organization_modal.js';
import Timer_modal from '../common_component/timer_modal.js';

import tds_section_arr from './common_arr/tds-section-arr.js';
import tcs_section_arr from './common_arr/tcs-section-arr.js';

export const TaxList = () => {
    const timer_interval_ref = useRef();
    const [clockTimerModalShow, setClockTimerModalShow] = useState(false);
    const [state_org, setstate_org] = useState(2);
    const [organization_name, setOrganization_name] = useState("");
    const [OrganizationModalShow, setOrganizationModalShow] = useState(false);
    const [TDSPayableAccountSelectionList, setTDSPayableAccountSelectionList] = useState([]);
    const [TDSReceivableAccountSelectionList, setTDSReceivableAccountSelectionList] = useState([]);
    const [TCSPayableAccountSelectionList, setTCSPayableAccountSelectionList] = useState([]);
    const [TCSReceivableAccountSelectionList, setTCSReceivableAccountSelectionList] = useState([]);

    const [user, setUser] = useState({});
    const [state, setstate] = useState(2);
    const [timer, setTimer] = useState(0);
    const [clock_timer, setClockTimer] = useState(0);

    const [data, setData] = useState([]);
    const [taxList, setTaxList] = useState([]);

    const [tax_id, settax_id] = useState("");
    const [TaxName, setTaxName] = useState("");
    const [TaxType, setTaxType] = useState("");
    const [TaxCategory, setTaxCategory] = useState("");
    const [Percentage, setPercentage] = useState(0);

    const [redirect_to_login, setRedirect_to_login] = useState(2);

    const [visibility, setvisibility] = useState(0);
    const [redirect, setredirect] = useState(0);
    const [read, setread] = useState(2);
    const [write, setwrite] = useState(2);

    const [userTheme, setUserTheme] = useState((localStorage.getItem("kt_metronic_theme_mode_menu") == "system") ? window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light" : localStorage.getItem("kt_metronic_theme_mode_menu"))

    const [organization_id, setOrganization_id] = useState("");
    const [organization_list, setorganization_list] = useState([]);

    const [mainSubmitButtonState, setMainSubmitButtonState] = useState(false);

    const [TDSTypelist, setTDSTypelist] = useState([]);
    const [TCSTypelist, setTCSTypelist] = useState([]);

    const [TDSAcountDetails, setTDSAcountDetails] = useState({ "TDS_payable_account": "", "TDS_receivable_account": "" });
    const [TCSAcountDetails, setTCSAcountDetails] = useState({ "TCS_payable_account": "", "TCS_receivable_account": "" });

    const [RegularTaxModalShow, setRegularTaxModalShow] = useState(false);
    const [TDSModalShow, setTDSModalShow] = useState(false);
    const [TCSModalShow, setTCSModalShow] = useState(false);

    var page_name = "tax-list";

    const loaderstyle = {
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: "auto",
        marginBottom: "auto",
    };

    const options = {
        fixedHeader: false,
        filter: true,
        selectableRows: "none",
        filterType: "dropdown",
        responsive: "vertical",
        tableBodyHeight: "400px",
        searchPlaceholder: 'Search Merchant',
    };

    let dropdownStyleDark = {
        option: (provided, state) => ({
            ...provided,
            color: '#dddddf',
            backgroundColor: state.isSelected ? provided.backgroundColor : state.isFocused ? '#5f5f8d' : provided.backgroundColor,
        }),
        control: (provided, state) => ({
            ...provided,
            backgroundColor: "#2b2b40",
            borderColor: "#4c4c71"
        }),
        placeholder: (provided, state) => ({
            ...provided,
            color: "#dddddf",
        }),
        singleValue: (provided, state) => ({
            ...provided,
            color: "#dddddf",
        }),
        menu: (provided, state) => ({
            ...provided,
            backgroundColor: "#2b2b40",
        })
    }

    const columns = [
        {
            name: "tax_name",
            label: "Tax Name",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "Percentage",
            label: "Percentage",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "tax_type",
            label: "Tax Type",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "Category",
            label: "Category",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "status",
            label: "Status",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <SetColor
                            value={value}
                            index={tableMeta.columnIndex}
                            change={event => updateValue(event)}
                        />
                    )
                }
            }
        }
    ];

    useEffect(() => {
        if (getCookie("access_token") != "") {
            if (getCookie("organization_id") != "") {
                setOrganization_id(getCookie("organization_id"));
            }
            else {
                var object = {};
                object.page_name = page_name;
                var headers = {
                    headers: {
                        "Content-Type": "application/json",
                        "token": getCookie("access_token"),
                        "Accept": "*/*"
                    }
                }

                axios.post(`${process.env.REACT_APP_SERVER_URL}/show-organization`, object, headers).then((res) => {
                    if (res.data.length == 1) {
                        setCookie('organization_id', res.data[0].organization_id, 1, true);
                        setOrganization_id(res.data[0].organization_id)
                    }
                    else {
                        setstate_org(1);
                        setorganization_list(res.data);
                        setOrganizationModalShow(true);
                    }
                })
            }
        }
        else {
            setRedirect_to_login(1);
        }
    }, [])

    useEffect(() => {
        setCookie("lastpage", window.location.href, 1, false)
        const validateJWT = () => {

            if (getCookie("access_token") != "") {
                var object = {};
                object.token = getCookie("access_token");

                var headers = {
                    headers: {
                        "Content-Type": "application/json",
                        "Accept": "*/*"
                    }
                }
                axios.post(`${process.env.REACT_APP_SERVER_URL}/get-user`, object, headers).then((res) => {

                    if (res.data.operation == "success") {
                        var user_object = {};
                        user_object.email = res.data.email;
                        user_object.name = res.data.name;
                        user_object.img = res.data.img;

                        setUser(user_object)
                    }
                    else {
                        swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
                            window.location.href = "/login"
                        });
                    }
                })
                    .catch(function (error) {
                        swal("Session Expired!", "Please login to continue using.", "warning").then((value) => {
                            window.location.href = "/login"
                        });
                    });
                object.organization_id = organization_id;
                axios.post(`${process.env.REACT_APP_SERVER_URL}/audience-info`, object, headers).then((res) => {

                    if (res.data.operation == "success") {
                        var token = res.data.audience_license;
                        if (res.data.organization_flag == 0) {
                            setstate_org(3);
                            setOrganization_name(res.data.organization_name);
                            setOrganizationModalShow(true);
                        }
                        else {
                            var path = window.location.pathname;
                            path = path.replace(/\/$/, "");
                            path = decodeURIComponent(path);
                            var original_url = path.split("/")[1]
                            let index = 0;
                            let temp = -1;
                            for (let i = 0; i < res.data.user_access.access.length; i++) {
                                if (temp == 1) break;
                                let component = res.data.user_access.access[i].inside_components;
                                for (let j = 0; j < component.length; j++) {
                                    if (component[j].hasOwnProperty(original_url)) {
                                        index = i;
                                        if (res.data.user_access.access[index].inside_components[j][original_url] == 1 && res.data.user_access.access[index].visibility == 1) {
                                            temp = 1;
                                            setvisibility(1);
                                            setread(res.data.user_access.access[index].inside_components[j]["read"])
                                            setwrite(res.data.user_access.access[index].inside_components[j]["write"])
                                            // setwrite(0)
                                            break;
                                        }
                                        else {
                                            setredirect(1);
                                        }
                                    }
                                    else if (component[j].hasOwnProperty("key")) {
                                        let sub_inside_components = component[j].inside_components
                                        for (let k = 0; k < sub_inside_components.length; k++) {
                                            if (sub_inside_components[k].hasOwnProperty(original_url)) {
                                                index = i
                                                if (sub_inside_components[k][original_url] == 1 && res.data.user_access.access[index].visibility == 1 && component[j].visibility == 1) {
                                                    temp = 1;
                                                    setvisibility(1);
                                                    setread(sub_inside_components[k]["read"])
                                                    setwrite(sub_inside_components[k]["write"])
                                                    // setwrite(0)
                                                    break;

                                                }
                                                else {
                                                    setredirect(1);
                                                }
                                            }
                                        }
                                    }
                                    else {
                                        temp = 2;
                                    }
                                }
                            }

                            if (temp == 2) {
                                setredirect(1);
                            }
                        }
                        if (!token.includes("ufhdsupw29fher") || !token.includes("pdbwu27dh6schs") || !token.includes("0dh27agiqbcrwi")) {
                            setCookie('access_token', res.data.audience_license, 1, true)
                            setTimer(process.env.REACT_APP_JWT_EXPIRY);
                            //setClockTimer(process.env.REACT_APP_JWT_EXPIRY);
                        }
                        else {
                            setTimer(res.data.audience_count)
                            //setClockTimer(res.data.audience_count)
                        }
                    }
                    else {
                        setRedirect_to_login(1);
                    }
                })
                    .catch(function (error) {
                        setRedirect_to_login(1);
                    });
            }
            else {
                setRedirect_to_login(1);
            }
        }

        organization_id != "" && validateJWT();
    }, [organization_id])

    useEffect(() => {
        setTDSTypelist(tds_section_arr.tds_section_arr.map(o => { return { label: o.section_name + "(" + o.section_no + ")", value: o.id } }));
        setTCSTypelist(tcs_section_arr.tcs_section_arr.map(o => { return { label: o.section_name + "(" + o.section_no + ")", value: o.id } }));
    }, []);

    useEffect(() => {
        let id = setInterval(() => {
            setUserTheme((localStorage.getItem("kt_metronic_theme_mode_menu") == "system") ? window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light" : localStorage.getItem("kt_metronic_theme_mode_menu"))
        }, 10)

        return () => {
            clearInterval(id)
        }
    }, [])

    useEffect(() => {
        timer_interval_ref.current = setInterval(() => {
            setTimer((timer) => {
                if (timer > 0 && timer < process.env.REACT_APP_JWT_EXPIRY_NOTICE) {
                    if (!clockTimerModalShow) {
                        setClockTimer(process.env.REACT_APP_JWT_EXPIRY_NOTICE)
                        setClockTimerModalShow(true)
                    }
                }
                return timer - 1
            })
        }, 1000);

        return () => { clearInterval(timer_interval_ref) };
    }, []);


    async function getTaxList() {

        var object = {};
        object.email = user.email;

        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/tax-datatable`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        // console.log(body.data)

        if (body.operation == "success") {
            setTaxList(body.data);
        }
        else {
            throw "tax list api error"
        }
    }
    async function selectiveaccounthead() {
        let arr = [
            { "category_name": "Assets", "headArray": ["TCS Receivable", "TDS Receivable", "Goods In Transit", "Advance Tax", "Prepaid Expenses"] },
            { "category_name": "Liability", "headArray": ["TDS Payable", "TCS Payable"] }
        ]
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/selective-account-head`, { method: 'POST', body: JSON.stringify({ "organization_id": organization_id, "headRequest": arr, "email": user.email, "page_name": page_name }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        //console.log(body.data)

        if (body.operation === "success") {
            let temp_dropdown = [];

            body.data.map((obj) => {
                let temp_obj = {};
                temp_obj.label = obj.category_name;
                let temp_dropdown1 = [];
                obj.headArray.map((obj1) => {
                    let temp_obj1 = {};
                    temp_obj1.label = obj1.name;
                    temp_obj1.value = obj1.hid;
                    if (obj1.name === "TDS Payable") {
                        arr.push(temp_obj1);
                        temp_obj.options = arr;
                        setTDSPayableAccountSelectionList(temp_obj);
                    }
                    else if (obj1.name === "TCS Payable") {
                        arr.push(temp_obj1);
                        temp_obj.options = arr;
                        setTCSPayableAccountSelectionList(temp_obj);
                    }
                    else if (obj1.name === "Goods In Transit" || obj1.name === "Advance Tax" || obj1.name === "Prepaid Expenses") {
                        arr.push(temp_obj1);
                        temp_obj.options = arr;
                        setTDSReceivableAccountSelectionList(temp_obj);
                    }
                    // console.log(temp_obj)

                    temp_dropdown1.push(temp_obj1);
                })
                temp_obj.options = temp_dropdown1;

                temp_dropdown.push(temp_obj);

            })

            console.log(temp_dropdown)
        }
    }

    useEffect(() => {
        if ((read || write) && (user.email && visibility)) {

            let p1 = getTaxList()
            // let p2 = selectiveaccounthead()

            Promise.all([p1])
                .then(() => {
                    setstate(1)
                    console.log("all apis done")
                })
                .catch((err) => {
                    setstate(3)
                    console.log(err)
                })
        }

    }, [user, visibility, read, write])

    useEffect(() => {

        if (taxList.length > 0) {
            let temp_data = taxList.map((obj) => {

                let temp_obj = {};
                temp_obj.tax_name = obj.tax_name;
                if (obj.category === "TDS") {
                    temp_obj.tax_type = TDSTypelist.find(option => option.value == obj.tax_type).label;
                }
                else if (obj.category === "TCS") {
                    temp_obj.tax_type = TCSTypelist.find(option => option.value == obj.tax_type).label;
                }
                else {
                    temp_obj.tax_type = obj.tax_type;
                }

                temp_obj.Category = obj.category;
                temp_obj.Percentage = obj.tax_percentage;
                temp_obj.status = obj.tax_status;
                temp_obj.tax_id = obj.tax_id;

                let id = obj.tax_id;

                // temp_obj.actions =
                //     <div className="dropdown">
                //         <button className="btn btn-sm btn-secondary rounded" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false" disabled={write ? false : true}><i className="fas fa-bars px-0"></i></button>
                //         {
                //             write &&
                //             <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                //                 <li className="py-1"><a className="dropdown-item" onClick={(e) => { updateTax(id); }} >Edit details</a></li>
                //                 <li className="py-1">
                //                     <a className="dropdown-item" href="#" 
                //                         onClick={(e) => { 
                //                             swal({
                //                                 title: "Are you sure?",
                //                                 text: "Are you sure you want to change Status?",
                //                                 icon: "warning",
                //                                 buttons: true,
                //                                 dangerMode: true,
                //                             })
                //                             .then((willDelete) => {
                //                                 if (willDelete) {
                //                                     changeStatusTax(id,obj.tax_status)
                //                                 } 
                //                             });
                //                         }} 
                //                     >Change Status</a>
                //                 </li>
                //             </ul>
                //         }
                //     </div>

                return temp_obj
            })
            setData(temp_data);
        }

    }, [taxList])


    // const updateTax = (id) => {
    //     for (let i = 0; i < taxList.length; i++) {
    //         if (id == taxList[i].tax_id) {
    //             settax_id(taxList[i].tax_id)
    //             setPercentage(parseInt(taxList[i].tax_percentage))
    //             setTaxName(taxList[i].tax_name);
    //             setTaxType(taxList[i].tax_type);
    //             if (taxList[i].category === "TDS") {
    //                 setTDSModalShow(true);
    //             }
    //             else if (taxList[i].category === "TCS") {
    //                 setTCSModalShow(true);
    //             }
    //             else {
    //                 setRegularTaxModalShow(true);
    //             }
    //         }
    //     }
    // }

    // const submitTax = () => {
    //     settax_id("")
    //     setPercentage(0)
    //     setTaxName("");
    // }

    // const changeStatusTax = (id,status) => {
    //     var values = { "id": id,"status":status,"page_name": page_name, "organization_id": organization_id };
    //     setstate(2);
    //     setMainSubmitButtonState(true)
    //     var headers = {
    //         headers: {
    //             "Content-Type": "application/json",
    //             "Accept": "*/*",
    //             "token": getCookie("access_token")
    //         }
    //     }

    //     axios.post(`${process.env.REACT_APP_SERVER_URL}/update-status-tax-details`, values, headers).then((res) => {
    //         setMainSubmitButtonState(false)

    //         if (res.data.operation == "success") {
    //             getTaxList()
    //             setstate(1);
    //             swal("Great!", "Status changed successfully!", "success")
    //         }
    //         else {
    //             swal("Oops!", "Something went wrong!", "error");
    //         }
    //     });
    // }


    // const submitTaxDetails = () => {

    //     var nexp = /^(?!\d+$)(?:[a-zA-Z0-9][a-zA-Z0-9 /\\&$|~!@#()*\-+%{}\[\]_.;,:"'<>=]*)?$/;
    //     if (TaxName == "") {
    //         swal("Warning!", "Tax Name cannot be empty", "warning");
    //         return false;
    //     }
    //     else if (!TaxName.match(nexp)) {
    //         swal("Warning!", "Tax Name cannot contain certain symbols ", "warning");
    //         return false;
    //     }
    //     else if (true) {
    //         var oname = taxList.filter((item) => { return item.tax_id !== tax_id })
    //         for (var i = 0; i < oname.length; ++i) {
    //             if (oname[i].tax_name == TaxName) {
    //                 swal("Oops!", "Tax Name already exists, try different one!", "error");
    //                 return false;
    //             }
    //             else if(oname[i].tax_percentage == Percentage && oname[i].tax_type == TaxType)
    //             {
    //                 swal("Oops!", "Tax Percentage with this tax type already exists, try different one!", "error");
    //                 return false;
    //             }
    //         }
    //     }
    //     if (Percentage == "") {
    //         swal("Warning!", "Percentage cannot be empty", "warning");
    //         return false;
    //     }
    //     setMainSubmitButtonState(true)
    //     var headers = {
    //         headers: {
    //             "Content-Type": "application/json",
    //             "Accept": "*/*",
    //             "token": getCookie("access_token")
    //         }
    //     }
    //     let Account_info = {};
    //     if (TaxCategory === "TDS") {
    //         Account_info = TDSAcountDetails;
    //     }
    //     else if (TaxCategory === "TcS") {
    //         Account_info = TCSAcountDetails;
    //     }
    //     if (tax_id != "") {
    //         var values = { "id": tax_id, "tax_name": TaxName, "Account_info": Account_info, "category": TaxCategory, "tax_type": TaxType, "Percentage": Percentage, "page_name": page_name, "organization_id": organization_id };
    //         axios.post(`${process.env.REACT_APP_SERVER_URL}/update-tax`, values, headers).then((res) => {
    //             setMainSubmitButtonState(false)
    //             if (res.data.operation == "success") {
    //                 getTaxList()
    //                 swal("Great!", "Tax details updated successfully!", "success")
    //                 setRegularTaxModalShow(false)
    //                 setTDSModalShow(false)
    //                 setTCSModalShow(false)
    //             }
    //             else {
    //                 swal("Oops!", "Something went wrong!", "error");
    //             }
    //         });
    //     }
    //     else {
    //         var values = { "tax_name": TaxName, "category": TaxCategory, "tax_type": TaxType, "Percentage": Percentage, "page_name": page_name, "organization_id": organization_id };
    //         axios.post(`${process.env.REACT_APP_SERVER_URL}/add-tax`, values, headers).then((res) => {
    //             setMainSubmitButtonState(false)
    //             if (res.data.operation == "success") {
    //                 getTaxList()
    //                 swal("Great!", "Tax details save successfully!", "success")
    //                 setRegularTaxModalShow(false)
    //                 setTDSModalShow(false)
    //                 setTCSModalShow(false)
    //             }
    //             else {
    //                 swal("Oops!", "Something went wrong!", "error");
    //             }
    //         });
    //     }
    // }

    return (
        <>
            {
                (redirect == 1 || (read == 0 && write == 0)) ?
                    <>
                        <Redirect to={{
                            pathname: "/unauthorized",
                        }}
                        />
                    </> :
                    null
            }
            {
                redirect_to_login == 0 ? null : (redirect_to_login == 1 ? <>
                    <Redirect to={{ pathname: "/login" }} />
                </> : null)
            }
            <div className="d-flex flex-column flex-column-fluid">
                <div className="app-toolbar py-3 py-lg-6" id="kt_app_toolbar">
                    <div id="kt_app_toolbar_container" className="app-container container-xxl d-flex flex-stack">
                        <h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">Tax List

                            <small className="text-muted fs-7 fw-bold my-1 ms-1"></small>
                        </h1>
                        {/* <div className="d-flex align-items-center gap-2 gap-lg-3">
                            <button className="btn btn-sm fw-bold btn-primary" onClick={() => { submitTax(); setRegularTaxModalShow(true); setTaxCategory("Regular"); }}>Add Regular Tax</button>
                            <button className="btn btn-sm fw-bold btn-primary" onClick={() => { submitTax(); setTDSModalShow(true); setTaxCategory("TDS"); }}>Add TDS</button>
                            <button className="btn btn-sm fw-bold btn-primary" onClick={() => { submitTax(); setTCSModalShow(true); setTaxCategory("TCS"); }}>Add TCS</button>
                        </div> */}
                    </div>
                </div>
                <div id="kt_app_content" className="app-content flex-column-fluid">
                    <div id="kt_app_content_container" className="app-container container-xxl">
                        <div className="card">
                            <div className="card-body pt-6">
                                {
                                    state == 1 ?
                                        <>
                                            <MuiThemeProvider theme={createTheme({ palette: { type: userTheme } })}>
                                                <MUIDataTable
                                                    title={"Tax List"}
                                                    data={data}
                                                    columns={columns}
                                                    options={options}
                                                />
                                            </MuiThemeProvider>
                                        </>
                                        : state == 2 ?
                                            <img src="/images/loader-06.svg" alt="" style={loaderstyle} />
                                            :
                                            <>
                                                <div className="card-body pt-0">
                                                    <div className="notice d-flex bg-light-primary rounded border-primary border border-dashed rounded-3 p-6">
                                                        <div className="d-flex flex-stack flex-grow-1">
                                                            <div className="fw-bold">
                                                                <h4 className="text-gray-800 fw-bolder">Something went wrong.</h4>
                                                                <div className="fs-6 text-gray-600"> </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* <Modal show={TCSModalShow} onHide={() => { setTCSModalShow() }} size="lg" centered>
                <Modal.Header>
                    <Modal.Title>{tax_id === "" ? <>Add</> : <>Edit</>} TCS</Modal.Title>
                    <div className="btn btn-sm btn-icon btn-active-color-primary" onClick={() => { setTCSModalShow(false) }}>
                        <span className="svg-icon svg-icon-1">
                            <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                    <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                    <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                </g>
                            </svg>
                        </span>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className="form-group">
                        <div className="row mb-5">
                            <div className="col-md-12 fv-row">
                                <label className="required fs-5 fw-bold mb-2">Tax Name</label>
                                <input id="tax_name" type="text" className="form-control form-control-solid" placeholder="" name="account-head" value={TaxName} onChange={(e) => { setTaxName(e.target.value) }} />
                            </div>
                        </div>
                        <div className="row mb-5">
                            <div className="col-md-12 fv-row">
                                <label className="required fs-5 fw-bold mb-2">Percentage</label>
                                <input id='Percentage' type="text" className="form-control form-control-solid" placeholder="" name="Department-code" value={Percentage.toString()} onChange={(e) => { setPercentage(e.target.value==""?0:parseInt(e.target.value)) }} readOnly={write ? false : true} />
                            </div>
                        </div>
                        <div className="row mb-5">
                            <div className="col-md-12 fv-row">
                                <label className="fs-5 fw-bold mb-2">Tax Type:</label>
                                <Select
                                    styles={userTheme == 'dark' ? dropdownStyleDark : {}}
                                    name="category"
                                    id="category"
                                    options={TCSTypelist}
                                    onChange={(event) => {
                                        setTaxType(event.value)
                                    }}
                                    value={TCSTypelist.filter(option => option.value == TaxType).length != 0 && TCSTypelist.filter(option => option.value == TaxType)[0]}
                                    placeholder="--Select Tax Type--"
                                />
                            </div>
                        </div>
                        <div className="row mb-5">
                            <div className="col-md-6 fv-row">
                                <label className="fs-5 fw-bold mb-2">Paybale Account</label>
                                <Select
                                    styles={userTheme == 'dark' ? dropdownStyleDark : {}}
                                    name="category"
                                    id="category"
                                    options={TCSPayableAccountSelectionList}
                                    onChange={(val) => {
                                        //console.log(val);
                                        TCSAcountDetails.TCS_payable_account = val;
                                        //setTDS_payable_account(val);
                                    }}
                                    value={TCSAcountDetails.TCS_payable_account}
                                    placeholder="--Select Account--"
                                />
                                <span className="fw-semibold ps-2 fs-6"></span>

                                <div className="fv-plugins-message-container invalid-feedback"></div>
                            </div>
                            <div className="col-md-6 fv-row">
                                <label className="fs-5 fw-bold mb-2">Receivabale Account</label>
                                <Select
                                    styles={userTheme == 'dark' ? dropdownStyleDark : {}}
                                    name="category"
                                    id="category"
                                    options={TCSReceivableAccountSelectionList}
                                    onChange={(val) => {
                                        //console.log(val); 
                                        TCSAcountDetails.TCS_receivable_account = val;
                                        //setTDS_receivable_account(val);
                                    }}
                                    value={TCSAcountDetails.TCS_receivable_account}
                                    placeholder="--Select Account--"
                                />
                                <span className="fw-semibold ps-2 fs-6"></span>

                                <div className="fv-plugins-message-container invalid-feedback"></div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button onClick={() => { setTCSModalShow(false) }} type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                    {write == 1 && <form >
                        <button type="button" onClick={(e) => { submitTaxDetails(e) }} className="btn btn-primary" data-kt-indicator={mainSubmitButtonState ? "on" : "off"} disabled={mainSubmitButtonState} ><span className="indicator-label">{tax_id === "" ? <>Save</> : <>Update</>}</span>
                            <span className="indicator-progress">Please wait...<span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                        </button>
                    </form>}
                </Modal.Footer>
            </Modal>

            <Modal show={TDSModalShow} onHide={() => { setTDSModalShow() }} size="lg" centered>
                <Modal.Header>
                    <Modal.Title>{tax_id === "" ? <>Add</> : <>Edit</>} TDS</Modal.Title>
                    <div className="btn btn-sm btn-icon btn-active-color-primary" onClick={() => { setTDSModalShow(false) }}>
                        <span className="svg-icon svg-icon-1">
                            <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                    <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                    <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                </g>
                            </svg>
                        </span>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className="form-group">
                        <div className="row mb-5">
                            <div className="col-md-12 fv-row">
                                <label className="required fs-5 fw-bold mb-2">Tax Name</label>
                                <input id="tax_name" type="text" className="form-control form-control-solid" placeholder="" name="account-head" value={TaxName} onChange={(e) => { setTaxName(e.target.value) }} />
                            </div>
                        </div>
                        <div className="row mb-5">
                            <div className="col-md-12 fv-row">
                                <label className="required fs-5 fw-bold mb-2">Percentage</label>
                                <input id='Percentage' type="text" className="form-control form-control-solid" placeholder="" name="Department-code" value={Percentage.toString()} onChange={(e) => { setPercentage(e.target.value==""?0:parseInt(e.target.value)) }} readOnly={write ? false : true} />
                            </div>
                        </div>
                        <div className="row mb-5">
                            <div className="col-md-12 fv-row">
                                <label className="fs-5 fw-bold mb-2">Tax Type:</label>
                                <Select
                                    styles={userTheme == 'dark' ? dropdownStyleDark : {}}
                                    name="category"
                                    id="category"
                                    options={TDSTypelist}
                                    onChange={(event) => {
                                        setTaxType(event.value)
                                    }}
                                    value={TDSTypelist.filter(option => option.value == TaxType).length != 0 && TDSTypelist.filter(option => option.value == TaxType)[0]}
                                    placeholder="--Select Tax Type--"
                                />
                            </div>
                        </div>
                        <div className="row mb-5">
                            <div className="col-md-6 fv-row">
                                <label className="fs-5 fw-bold mb-2">Paybale Account</label>
                                <Select
                                    styles={userTheme == 'dark' ? dropdownStyleDark : {}}
                                    name="category"
                                    id="category"
                                    options={TDSPayableAccountSelectionList}
                                    onChange={(val) => {
                                        //console.log(val);
                                        TDSAcountDetails.TDS_payable_account = val;
                                        //setTDS_payable_account(val);
                                    }}
                                    value={TDSAcountDetails.TDS_payable_account}
                                    placeholder="--Select Account--"
                                />
                                <span className="fw-semibold ps-2 fs-6"></span>

                                <div className="fv-plugins-message-container invalid-feedback"></div>
                            </div>
                            <div className="col-md-6 fv-row">
                                <label className="fs-5 fw-bold mb-2">Receivabale Account</label>
                                <Select
                                    styles={userTheme == 'dark' ? dropdownStyleDark : {}}
                                    name="category"
                                    id="category"
                                    options={TDSReceivableAccountSelectionList}
                                    onChange={(val) => {
                                        TDSAcountDetails.TDS_receivable_account = val 
                                    }}
                                    value={TDSAcountDetails.TDS_receivable_account}
                                    placeholder="--Select Account--"
                                />
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button onClick={() => { setTDSModalShow(false) }} type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                    {
                        write == 1 &&
                        <button type="button" onClick={(e) => { submitTaxDetails(e) }} className="btn btn-primary" data-kt-indicator={mainSubmitButtonState ? "on" : "off"} disabled={mainSubmitButtonState} ><span className="indicator-label">{tax_id === "" ? "Save" : "Update"}</span>
                            <span className="indicator-progress">Please wait...<span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                        </button>
                    }
                </Modal.Footer>
            </Modal>

            <Modal show={RegularTaxModalShow} onHide={() => { setRegularTaxModalShow(false) }} size="lg" centered>
                <Modal.Header>
                    <Modal.Title>{tax_id === "" ? "Add" : "Edit"} Regular Tax</Modal.Title>
                    <div className="btn btn-sm btn-icon btn-active-color-primary" onClick={() => { setRegularTaxModalShow(false) }}>
                        <span className="svg-icon svg-icon-1">
                            <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                    <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                    <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                </g>
                            </svg>
                        </span>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className="form-group">
                        <div className="row mb-5">
                            <div className="col-md-12 fv-row">
                                <label className="required fs-5 fw-bold mb-2">Tax Name</label>
                                <input type="text" className="form-control form-control-solid" value={TaxName} onChange={(e) => { setTaxName(e.target.value) }} />
                            </div>
                        </div>
                        <div className="row mb-5">
                            <div className="col-md-12 fv-row">
                                <label className="required fs-5 fw-bold mb-2">Percentage</label>
                                <input type="number" className="form-control form-control-solid" value={Percentage.toString()} onChange={(e) => { setPercentage(e.target.value==""?0:parseInt(e.target.value)) }} readOnly={write ? false : true} />
                            </div>
                        </div>
                        <div className="row mb-5">
                            <div className="col-md-12 fv-row">
                                <label className="fs-5 fw-bold mb-2">Tax Type:</label>
                                <Select
                                    styles={userTheme == 'dark' ? dropdownStyleDark : {}}
                                    options={["CGST","SGST","IGST","UTGST"].map(x=>{return {label:x,value:x}})}
                                    onChange={(event) => {setTaxType(event.value)}}
                                    value={["CGST","SGST","IGST","UTGST"].map(x=>{return {label:x,value:x}}).filter(option => option.value == TaxType).length != 0 && ["CGST","SGST","IGST","UTGST"].map(x=>{return {label:x,value:x}}).filter(option => option.value == TaxType)[0]}
                                    placeholder="--Select Tax Type--"
                                />
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button onClick={() => { setRegularTaxModalShow(false) }} type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                    {
                        write == 1 &&
                        <button type="button" onClick={(e) => { submitTaxDetails(e) }} className="btn btn-primary" data-kt-indicator={mainSubmitButtonState ? "on" : "off"} disabled={mainSubmitButtonState} ><span className="indicator-label">{tax_id === "" ? "Save":"Update"}</span>
                            <span className="indicator-progress">Please wait...<span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                        </button>
                    }
                </Modal.Footer>
            </Modal> */}

            <Organization_modal OrganizationModalShow={OrganizationModalShow} setOrganizationModalShow1={setOrganizationModalShow} organization_name={organization_name} organization_list={organization_list} organization_id={organization_id} state_org={state_org} mainSubmitButtonState={mainSubmitButtonState} setMainSubmitButtonState={setMainSubmitButtonState} />
            <Timer_modal clockTimerModalShow={clockTimerModalShow} setClockTimerModalShow1={setClockTimerModalShow} clock_timer={clock_timer} organization_id={organization_id} setTimer={setTimer} mainSubmitButtonState={mainSubmitButtonState} setMainSubmitButtonState={setMainSubmitButtonState} />
        </>);

};

export default TaxList;
